import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--f1-car-turbo';

const ICON_ID = 'ic_f1_car_turbo';
const IconF1CarTurbo = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="31"
    height="12"
    viewBox="0 0 31 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconF1CarTurbo.propTypes = {
  className: string,
};

IconF1CarTurbo.defaultProps = {
  className: null,
};

export default IconF1CarTurbo;
