import { loadable } from 'nordic/lazy';

import sources from './component-types';

const BottomSheetIframe = loadable(async () => import('../eshops-components-library'), {
  resolveComponent: (mod) => mod.withBottomSheetContext(mod.BottomSheetIframe),
});

const ProfileHeader = loadable(async () => import('../eshops-components-library'), {
  resolveComponent: (mod) => mod.withBottomSheetContext(mod.ProfileHeader),
});

const FreeShippingBar = loadable(async () => import('../../full-snackbar/eshops-freeshipping.desktop'));

const Resources = {};

Resources[sources.PROFILE_HEADER] = ProfileHeader;
Resources[sources.FREE_SHIPPING_BAR] = FreeShippingBar;
Resources[sources.BOTTOM_SHEET_IFRAME] = BottomSheetIframe;

export default Resources;
