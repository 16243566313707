import { bool, func, node, string, shape } from 'prop-types';

const linkPropTypes = {
  ariaLabel: string,
  children: node.isRequired,
  className: string,
  href: string,
  isInternal: bool,
  keepLayout: bool,
  role: string,
  tabIndex: string,
  title: string,
  linkTag: string,
  target: string,
  tracks: shape({
    analyticsTrack: shape(),
    melidataTrack: shape(),
  }),

  onClick: func,
  filterId: string,
};

export { linkPropTypes };
