import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-variations-pill';

const VariationsPill = ({ className = null, text }) => (
  <div className={`${namespace}__container`}>
    <div className={classnames(namespace, className)}>{text}</div>
  </div>
);

VariationsPill.propTypes = {
  className: string,
  text: string.isRequired,
};

export default VariationsPill;
