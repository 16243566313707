import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import classnames from 'classnames';

import IconGalleryDesktop from '../icons/gallery.desktop';
import IconListDesktop from '../icons/list.desktop';
import Link from '../../components-v2/link';
import { stack, gallery, grid, testId } from './constants';

const namespace = 'ui-search-view-change';

const getLayoutIconComponent = (id) => {
  switch (id) {
    case gallery:
    case grid:
      return <IconGalleryDesktop />;
    case stack:

    // falls through
    default:
      return <IconListDesktop />;
  }
};

const ViewChange = ({ views, currentLayout }) => (
  <div className={namespace} data-testid={testId} aria-hidden>
    {views?.map((view) => (
      <Link
        key={view.id}
        href={`${view.url_host}${view.url_path}`}
        keepLayout={false}
        className={classnames(`${namespace}__link`, { [`${namespace}__link--active`]: view.id === currentLayout })}
        title={view.label}
      >
        {getLayoutIconComponent(view.id)}
      </Link>
    ))}
  </div>
);

ViewChange.propTypes = {
  currentLayout: string.isRequired,
  views: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      url_host: string.isRequired,
      url_path: string.isRequired,
    }),
  ).isRequired,
};

export default ViewChange;
