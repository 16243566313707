import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--alert-edit';
const ICON_ID = 'ic_alert_edit';

const IconAlertEdit = ({ className }) => (
  <svg
    data-testid="edit_alert_icon"
    className={classnames(namespace, className)}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconAlertEdit.propTypes = {
  className: string,
};

IconAlertEdit.defaultProps = {
  className: null,
};

export default IconAlertEdit;
