import React from 'react';

const ICON_ID = 'ic_coupon';

const IconCoupon = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
    <use href={`#${ICON_ID}`} />
  </svg>
);

export default IconCoupon;
