import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';

const namespace = 'ui-search-icon ui-search-icon--mshops_logo';

const MShopsLogo = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="mshops-logo.svg" alt="" />
  </div>
);

MShopsLogo.propTypes = {
  className: string,
};

MShopsLogo.defaultProps = {
  className: null,
};

export default MShopsLogo;
