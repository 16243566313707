import IconFactory from '../factory-builder';
import {
  IconRecommendationsFull,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
} from './icon-generator';

const RecommendationsIconFactory = IconFactory([
  IconRecommendationsFull,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
]);

export default RecommendationsIconFactory;
