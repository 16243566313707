import React from 'react';
import { string } from 'prop-types';

import Link from '../../../../components-v2/link';

const ProcessedDescription = ({ id, name, url }) => (
  <Link title={name} href={url}>
    <b>{name}</b>
    <br />({id})
  </Link>
);

ProcessedDescription.propTypes = {
  id: string,
  name: string,
  url: string,
};

ProcessedDescription.defaultProps = {
  id: '',
  name: '',
  url: '',
};

export default ProcessedDescription;
