import { useState } from 'react';

import SearchUrlService from '../../services/search-url';

const useLazyCategoryId = (siteId) => {
  const [categoryId, setCategoryId] = useState(null);

  const getCategoryId = () => {
    if (categoryId) {
      return Promise.resolve(categoryId);
    }

    return SearchUrlService.readSearchUrl(window.location.href, siteId).then(({ data: { category } }) => {
      setCategoryId(category);

      return category;
    });
  };

  return { getCategoryId };
};

export default useLazyCategoryId;
