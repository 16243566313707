import React from 'react';
import { shape, string } from 'prop-types';

import get from 'lodash/get';

import ItemPrice from '../../../../card/price';

const Price = ({ className, price, from, discountSource }) => {
  if (!price.amount) {
    return <div className={className}>{get(price, 'label.text', '')}</div>;
  }

  return <ItemPrice size="small" className={className} discountSource={discountSource} from={from} {...price} />;
};

Price.propTypes = {
  className: string,
  discountSource: string,
  from: shape({
    text: string,
  }),
  price: shape({}).isRequired,
};

Price.defaultProps = {
  className: null,
  discountSource: null,
  from: null,
};

export { Price };
