import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--warning';

const ICON_ID = 'ic_pills_warning';

const IconWarning = ({ className, description }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconWarning.propTypes = {
  className: string,
  description: string,
};

IconWarning.defaultProps = {
  className: null,
};

export default React.memo(IconWarning);
