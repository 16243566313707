import React, { Children, cloneElement } from 'react';
import { bool, string, node } from 'prop-types';

import classnames from 'classnames';

import Separator from './separator';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-item__group';

const Group = ({ className = null, children = null, name = null, noSeparator = false }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  if (children && (!Array.isArray(children) || (children.length > 0 && children.some((ch) => !!ch)))) {
    return (
      <div
        className={classnames(namespace, name && `${namespace}--${name}`, className, { 'shops__items-group': isShops })}
      >
        {Children.map(children, (child) => {
          if (!child) {
            return null;
          }

          return (
            <>
              {!noSeparator && <Separator />}
              {cloneElement(child, {
                className: classnames(`${namespace}__element`, child.props.className, {
                  'shops__items-group-details': isShops,
                }),
              })}
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

Group.propTypes = {
  children: node,
  className: string,
  name: string,
  noSeparator: bool,
};

export default Group;
