import React from 'react';

import { loadable } from 'nordic/lazy';

import useValuePropositionsDetails from './use-value-propositions-details';

const ChunkPropositionsDetails = () => {
  const valuePropositionProps = useValuePropositionsDetails();
  const ValuePropositionsDetails = loadable(async () =>
    import(/* webpackChunkName: "chunk-propositions-details" */ './value-propositions-details'),
  );

  return valuePropositionProps ? <ValuePropositionsDetails {...valuePropositionProps} /> : null;
};

export default ChunkPropositionsDetails;
