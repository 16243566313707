/*  eslint-disable @typescript-eslint/default-param-last */
const splitText = (words = [], length, lines) => {
  if (!words.length) {
    return lines;
  }

  const word = words[0];

  const nLines = lines.length;

  const split = nLines ? lines[nLines - 1] : '';

  const currentWords = split ? `${split} ${word}` : word;
  const wordsArray = currentWords.split(' ');
  const charactersCount = wordsArray.reduce((total, val) => total + val.length, 0);
  const spaceCount = currentWords.length - charactersCount ? (currentWords.length - charactersCount) * 0.7 : 0;

  if (nLines && split && charactersCount + spaceCount <= length) {
    lines[nLines - 1] = `${split} ${word}`;
  } else {
    lines.push(word);
  }

  return splitText(words.slice(1, words.length), length, lines);
};

const getSplitText = (text, characterSize, maxWidth) => {
  if (!text) {
    return [];
  }

  const words = text.split(' ');

  const characters = Math.floor(maxWidth / characterSize);

  return splitText(words, characters, []);
};

module.exports = { getSplitText, splitText };
