const PACKAGE_NAMESPACE_LABEL = 'ui-label-builder';

const PACKAGE_NAMESPACE_STYLED = 'ui-search-styled';

const COMPONENT_TYPE = {
  JSX: 'JSX',
  FN_ICON: 'FN_ICON',
};

const REGEX_LIB = {
  INTERPOLATION: /{([\s\S]+?)}/g,
  GET_VARIABLES: /\{([^{}]*)\}/g,
  GET_SNAKE_CASE: /_([a-zA-Z0-p])/g,
};

export { PACKAGE_NAMESPACE_LABEL, PACKAGE_NAMESPACE_STYLED, COMPONENT_TYPE, REGEX_LIB };
