import get from 'lodash/get';

import { trackEvent as trackMelidataEvent } from './melidata';
import { trackEvent as trackAnalyticsEvent } from './analytics';

const trackEvent = (track) => {
  if (track?.melidata_track) {
    trackMelidataEvent(track.melidata_track);
  }

  if (track?.analytics_track) {
    trackAnalyticsEvent(track.analytics_track);
  }
};

const trackLayoutChangeEvent = (track, layoutId) => {
  const event = get(track, 'melidata_track');

  if (event) {
    if (!event.event_data) {
      event.event_data = {};
    }

    event.event_data.list_mode = layoutId;
    trackEvent(track);
  }
};

const dispatchSellerPixelsInfo = (trackingMediator, results) => () => {
  trackingMediator('dispatch', 'mshops:handshake', {});
  trackingMediator('dispatch', 'page:search', {
    itemIds: results.map((item) => item.id),
  });
};

const trackSellerPixelsInfo = (trackingMediator, results) => {
  trackingMediator('on', 'mshops:ready', dispatchSellerPixelsInfo(trackingMediator, results));
};

export { trackEvent, trackLayoutChangeEvent, trackSellerPixelsInfo, dispatchSellerPixelsInfo };
