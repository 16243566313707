import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'poly_calendar';

const IconOnlineVisitRequest = ({ className }) => (
  <svg
    width="12"
    className={classnames(className)}
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconOnlineVisitRequest.propTypes = {
  className: string,
};

IconOnlineVisitRequest.defaultProps = {
  className: null,
};

export default IconOnlineVisitRequest;
