import { PACKAGE_NAMESPACE } from '../../utils/constants';

const NAMESPACE_WRAPPER = `${PACKAGE_NAMESPACE}-highlight-wrapper`;
const NAMESPACE_CONTENT = `${PACKAGE_NAMESPACE}-highlight-content`;
const NAMESPACE = `${PACKAGE_NAMESPACE}-highlight`;

export {
  NAMESPACE_WRAPPER,
  NAMESPACE_CONTENT,
  NAMESPACE,
};
