const HEADER_TYPES = {
  OFFICIAL_STORE: 'OFFICIAL_STORE_HEADER',
  EXHIBITOR: 'EXHIBITOR',
  DEAL: 'BANNER',
  SELLER: 'SELLER',
  ESHOP: 'ESHOP',
  SHOWCASE: 'SHOWCASE',
};

export { HEADER_TYPES };
