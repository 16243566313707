const NAMESPACE = 'ui-map-control';

const CONTROL_POSITION = {
  rightBottom: 'RIGHT_BOTTOM',
};

const MAP_TYPE = {
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
};

const ZOOM_LEVELS = {
  MIN: 4,
  MAX: 18,
  DEFAULT: 14,
};

export { CONTROL_POSITION, MAP_TYPE, NAMESPACE, ZOOM_LEVELS };
