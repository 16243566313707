/* eslint-disable react/prop-types */
import React from 'react';

import { string } from 'prop-types';

import Icon from './Icon/index';
import IconCoupon from '../../icons/icon-coupons';
import IconFull from '../../icons/full';
import IconLike from './like';
import IconMeliCoin from '../icons/icon-melicoin-html';
import IconMeliPlus from './meli_plus';
import IconStar from './circled_star';
import IconTrade from './trade';
import IconWarning from './pills_warning';
import IconInstallation from './installation';

import IconEdit from '@andes/icons/Edit16';
import ThumbUpOutlined from '@andes/icons/ThumbUpOutlined16';
import ThumbDownOutlined from '@andes/icons/ThumbDownOutlined16';
import OverflowMenuVertical from '@andes/icons/OverflowMenuVertical16';
import ThumbUpFilled from '@andes/icons/ThumbUpFilled16';

const icons = {
  coupon: ({ className, description, key }) => <IconCoupon description={description} key={key} className={className} />,
  dislike_outlined: ({ className, color, description, key }) => <ThumbDownOutlined color={color} description={description} key={key} className={className} />,
  full_icon: ({ className, description, key }) => <IconFull description={description} key={key} className={className} />,
  like_outlined: ({ className, color, description, key }) => <ThumbUpOutlined color={color} description={description} key={key} className={className} />,
  like: ({ className, description, key }) => <IconLike description={description} key={key} className={className} />,
  melicoin: ({ className, description, key }) => <IconMeliCoin description={description} key={key} className={className} />,
  meliplus: ({ className, description, key }) => <IconMeliPlus description={description} key={key} className={className} />,
  pen: ({ className, description, key }) => <IconEdit description={description} key={key} className={className} />,
  star: ({ className, description, key }) => <IconStar description={description} key={key} className={className} />,
  trade: ({ className, description, key }) => <IconTrade description={description} key={key} className={className} />,
  thumb_up_filled: ({ className, color, description, key }) => <ThumbUpFilled color={color} description={description} key={key} className={className} />,
  vertical_menu: ({ className, color, description, key }) => <OverflowMenuVertical color={color} description={description} key={key} className={className} />,
  warning_icon: ({ className, description, key }) => <IconWarning description={description} key={key} className={className} />,
  installation: ({ className, description, key, color }) => <IconInstallation description={description} color={color} key={key} className={className} />,
};


const getIcon = (icon, description, size, color, key, className, sourceIcons) => {
  let iconToPrint;

  if (size) {
    iconToPrint = Icon({ icon, size, className, color, description, sourceIcons });
  } else {
    const { [icon]: iconComponent = null } = icons;
    iconToPrint = !iconComponent ? null : iconComponent({ description, color, key, className });
  }

  iconToPrint = iconToPrint || `${icon}--no-icon`;

  return iconToPrint;
};


getIcon.defaultProps = {
  color: null,
  description: null,
  icon: null,
  size: '16',
};

getIcon.propTypes = {
  className: string,
  color: string,
  description: string,
  icon: string.isRequired,
  key: string,
  size: string,
};

export default getIcon;
