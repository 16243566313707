import { filterSearch } from '../components/context/filters/helpers';
import useClientSideOptions from './use-client-side-options';

const useFilterSearch = (href, id) => {
  const { isClientSideSearch } = useClientSideOptions();

  return isClientSideSearch ? filterSearch(href, id) : null;
};

export default useFilterSearch;
