/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';

import SearchUrlService from '../../../../services/search-url';
import { getPathname } from '../../context/filters/helpers';
import { useStaticProps } from '../../context/static-props';
import { useSearch } from '../../../hooks/context';
import { sendTrack } from '../helpers/track';
import { DISPLAY_TYPES, FILTERS, LAYOUTS } from '../../../constants';

const deriveInitialStateFromFacetedConfig = (newFacetedSearch) => {
  const { applied_filters, selected_filter, faceted_config } = newFacetedSearch;

  const mapFilterValues = (filterName) => {
    const filter = applied_filters.find(({ id }) => id === filterName);

    return filter?.values.map((value) => value.id).join(',') ?? undefined;
  };

  // buscar el string de la Category dentro de las opciones disponibles en el dropdown de category
  const propertyType = mapFilterValues(FILTERS.IDS.PROPERTY_TYPE);
  const categoriesConfig = faceted_config?.data.find(({ id }) => id === FILTERS.IDS.CATEGORY);
  const selectedCategory = categoriesConfig?.options.find(({ id }) => id.includes(`_${propertyType}`));

  return {
    OPERATION: mapFilterValues(FILTERS.IDS.OPERATION),
    OPERATION_SUBTYPE: mapFilterValues(FILTERS.IDS.OPERATION_SUBTYPE) === FILTERS.IDS.PROJECTS,
    category: selectedCategory?.id,
    LOCATION: {
      label: selected_filter.location?.name,
      city: mapFilterValues(FILTERS.IDS.CITY),
      state: mapFilterValues(FILTERS.IDS.STATE),
      neighborhood: mapFilterValues(FILTERS.IDS.NEIGHBORHOOD),
    },
  };
};

const mapActionPayloadToFilters = (actionPayload, renderConfig, tracks) => {
  sendTrack(actionPayload, tracks, true);

  const { OPERATION, city, state, neighborhood } = actionPayload;

  const subtypeConfig = renderConfig?.find(({ id }) => id === FILTERS.IDS.OPERATION_SUBTYPE);
  const OPERATION_SUBTYPE = subtypeConfig?.values[actionPayload.OPERATION_SUBTYPE];
  const [, PROPERTY_TYPE] = actionPayload.category.split('_');

  return { OPERATION, OPERATION_SUBTYPE, PROPERTY_TYPE, city, state, neighborhood };
};

const useVisFacetedSearch = () => {
  const { siteId, visualId } = useStaticProps();
  const { new_faceted_search: newFacetedSearch, view_options, layout_options } = useSearch();
  const initialState = useMemo(
    () => (newFacetedSearch ? deriveInitialStateFromFacetedConfig(newFacetedSearch) : {}),
    [newFacetedSearch],
  );
  const callToAction = newFacetedSearch?.labels.action;
  const renderConfig = newFacetedSearch?.faceted_config?.data ?? [];
  const tracks = newFacetedSearch?.tracks.search_tracks;

  const onAction = useCallback(
    async (_, actionPayload) => {
      const { category } = await SearchUrlService.readSearchUrl(window.location.href, siteId).then(
        (response) => response.data,
      );

      const oldFilters = newFacetedSearch?.selected_filter.keep_filters ? newFacetedSearch.applied_filters : [];
      const newFilters = mapActionPayloadToFilters(actionPayload, renderConfig, tracks);

      return getPathname({
        appliedFilters: oldFilters,
        removeOffset: true,
        useExistingFilters: true,
        sortingOptions: view_options?.sort?.sorts ?? [],
        DisplayType: layout_options?.current === LAYOUTS.TYPE_MAP ? DISPLAY_TYPES.MAP : null,
        siteId,
        visualId,
        ...newFilters,
        category,
      }).then((url) => {
        window.location = url;

        return null;
      });
    },
    [siteId],
  );

  return {
    renderConfig,
    initialState,
    callToAction,
    onAction,
  };
};

export default useVisFacetedSearch;
