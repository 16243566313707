import { useEffect } from 'react';

import get from 'lodash/get';
import { createSetter, getSnapshot, useSubscribedState } from 'nordic/page/store';

import getHistory from '../../../lib/history';
import { trackLayoutChangeEvent } from '../../../lib/tracking';

export const useLayout = () => useSubscribedState().layout || {};

export const useCurrentLayout = () => useLayout()?.currentLayout;

export const useNextLayout = () => useLayout().nextLayout;

export const getLayout = () => getSnapshot().layout || {};

export function getLayoutById(layoutId, layout) {
  return typeof layoutId === 'string' ? layout.views.find((it) => it.id === layoutId) : layoutId;
}

const setCurrentLayout = createSetter(({ layout }, payload) => {
  layout.currentLayout = getLayoutById(payload, layout.init);
});

const setNextLayout = createSetter(({ layout }, payload) => {
  layout.nextLayout = getLayoutById(payload, layout.init);
});

export const getNextLayout = (currentLayoutId, viewsList) => {
  const index = viewsList.findIndex((l) => l.id === currentLayoutId);

  return index === viewsList.length - 1 ? viewsList[0] : viewsList[index + 1];
};

export function onChangeLayout(id) {
  const { currentLayout, nextLayout, init } = getLayout();
  // Si recibe id lo llama desktop, caso contrario mobile
  const newLayout = id ? getLayoutById(id) : getNextLayout(currentLayout, init.views);
  const newNextLayout = getNextLayout(nextLayout, init.views);

  const pathname = newLayout.url_path;

  setCurrentLayout(newLayout);
  setNextLayout(newNextLayout);
  trackLayoutChangeEvent(init.track, newLayout.id);

  const history = getHistory();

  history.push({
    pathname: encodeURI(pathname),
    search: history.location.search,
    state: { layoutId: newLayout.id, nextLayoutId: newNextLayout.id },
  });
}

const LayoutListener = () => {
  const history = getHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        const { initialLayout, initialNextLayout } = getLayout();

        setCurrentLayout(get(location, 'state.layoutId', initialLayout));
        setNextLayout(get(location, 'state.nextLayoutId', initialNextLayout));
      }
    });

    return () => unlisten();
  }, [history]);

  return null;
};

export { LayoutListener };
