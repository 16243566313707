import { createSetter, useSubscribedState } from 'nordic/page/store';

export const useFullFreeshipping = () => useSubscribedState().fullFreeshipping;
export const useFullOnlyFirstFreeshipping = () => useFullFreeshipping().fullOnlyFirstFreeshipping;
export const useShowShimmerAction = () => useFullFreeshipping().showShimmerAction;
export const useIsVisible = () => useFullFreeshipping().isVisible;
export const useShowShimmer = () => useFullFreeshipping().showShimmer;

export const setCurrentFullFreeshipping = createSetter(({ fullFreeshipping }, payload) => {
  fullFreeshipping.currentFullFreeshipping = payload;
});
export const setFullOnlyFirstFreeshipping = createSetter(({ fullFreeshipping }, payload) => {
  fullFreeshipping.fullOnlyFirstFreeshipping = payload;
});
export const setFullFreeShippingLoading = createSetter(({ fullFreeshipping }, payload) => {
  fullFreeshipping.isLoading = payload;
});
export const setFullFreeShippingVisibility = createSetter(({ fullFreeshipping }, payload) => {
  fullFreeshipping.isVisible = payload;
});
