import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--international-filter-cbt';

const IconInternationalFilterCBT = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="international-filter-cbt.svg" alt="Compra Internacional" />
  </div>
);

IconInternationalFilterCBT.propTypes = {
  className: string,
};

IconInternationalFilterCBT.defaultProps = {
  className: '',
};

export default React.memo(IconInternationalFilterCBT);
