const MIN_INPUT_NAME = 'Minimum';
const SINCE_ITEM_NAME = 'From';
const UNTIL_ITEM_NAME = 'To';
const MAX_INPUT_NAME = 'Maximum';

const FILTER_GROUPS_IDS = {
  OFFICIAL_STORE: 'official_store',
  SIZE: 'SIZE',
  MAIN_COLOR: 'MAIN_COLOR',
};

const STYLE_MODIFIERS = {
  FULL_ICON: 'full',
  YEAR_RANGE: 'year',
  PRICE_RANGE: 'price',
  VEHICLE_BODY_MODAL: 'vehicle-body',
  DEFAULT_MODAL: 'default',
};

export { MIN_INPUT_NAME, SINCE_ITEM_NAME, UNTIL_ITEM_NAME, MAX_INPUT_NAME, FILTER_GROUPS_IDS, STYLE_MODIFIERS };
