/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useMemo } from 'react';

import supercluster from '../../../lib/supercluster';
import { DEFAULT_CLUSTER_OPTIONS, GEOJSON_TYPES, INITIAL_INDEXED_CLUSTERS, LEAVES_PAGINATION } from './constants';

const Supercluster = supercluster.default || supercluster;

const { POINT, FEATURE } = GEOJSON_TYPES;

const toGeoJSON = ({ latitude, longitude }, markerIndex) => ({
  type: FEATURE,
  properties: { markerIndex },
  geometry: {
    type: POINT,
    coordinates: [longitude, latitude],
  },
});

const buildClusterPoint = ({ lat, lng, markerIndex, clusterResults }) => {
  const [{ id }] = clusterResults;

  return {
    clusterResults,
    id,
    isCluster: clusterResults.length > 1,
    lat,
    lng,
    markerIndex,
  };
};

const sortByMarkerIndex = (markerA, markerB) => markerA.properties.markerIndex - markerB.properties.markerIndex;

const flattenCluster = (sc, cluster) => {
  const {
    properties: { cluster: isCluster, cluster_id: clusterId },
    geometry: {
      coordinates: [lng, lat],
    },
  } = cluster;
  const points = isCluster ? sc.getLeaves(clusterId, LEAVES_PAGINATION) : [cluster];

  // Cluster leaves do not keep the original marker order, so we need to sort
  // it to stay consistent with the markers array.
  const [
    {
      properties: { markerIndex },
    },
  ] = points.sort(sortByMarkerIndex);

  return { lat, lng, markerIndex, points };
};

// Match every marker index to its cluster index so we can access it in
// linear time instead of looking for it on each interaction
const indexCluster = ({ sc, markers, clusters, indices }, cluster, clusterIndex) => {
  const nextIndices = {};
  const clusterResults = [];
  const { lat, lng, markerIndex, points } = flattenCluster(sc, cluster);

  points.forEach(({ properties: { markerIndex: currentIndex } }) => {
    clusterResults.push(markers[currentIndex]);
    nextIndices[currentIndex] = clusterIndex;
  });

  return {
    sc,
    markers,
    clusters: [...clusters, buildClusterPoint({ lat, lng, clusterResults, markerIndex })],
    indices: { ...indices, ...nextIndices },
  };
};

const getIndexedClusters = ({ sc, markers, bounds, zoom }) => {
  if (bounds && zoom) {
    return sc.getClusters(bounds, zoom).reduce(indexCluster, { sc, markers, ...INITIAL_INDEXED_CLUSTERS });
  }

  return INITIAL_INDEXED_CLUSTERS;
};

const useClusters = ({ markers, bounds, zoom, options = {} }) => {
  const api = useMemo(() => {
    const sc = new Supercluster({ ...DEFAULT_CLUSTER_OPTIONS, ...options });

    sc.load(markers.map(toGeoJSON));

    return { sc, markers };
  }, [markers]);

  const { clusters, indices } = useMemo(
    () => getIndexedClusters({ sc: api.sc, markers: api.markers, bounds, zoom }),
    [api, bounds, zoom],
  );

  const checkSelectionStatus = useCallback(
    (selectedMarker, clusterIndex) => indices[selectedMarker] === clusterIndex,
    [indices],
  );

  return { clusters, checkSelectionStatus };
};

export default useClusters;
