import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Chevron from '../icons/chevron';

const { string, func, bool } = PropTypes;

const namespace = 'ui-search-info-text-box';

const InfoTextBoxDescription = ({ see_less, see_more, handleClick, collapsed, description }) => (
  <>
    <div
      className={classnames(`${namespace}__details`, { [`${namespace}__details--is-collapsed`]: collapsed })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: description }}
    />
    <button
      className={classnames(`${namespace}__button`, { [`${namespace}__button--is-collapsed`]: collapsed })}
      onClick={handleClick}
      type="button"
    >
      {collapsed ? see_more : see_less}
      <Chevron className={`${namespace}__button-icon`} />
    </button>
  </>
);

const InfoTextBox = ({ title, description, see_less, see_more, layout }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={classnames(namespace, `${namespace}--${layout}`)}>
      <div className={classnames(`${namespace}__container`, { [`${namespace}__container--is-collapsed`]: collapsed })}>
        <h2 className={`${namespace}__title`}>{title}</h2>
        {description && (
          <InfoTextBoxDescription
            see_less={see_less}
            see_more={see_more}
            handleClick={handleClick}
            collapsed={collapsed}
            description={description}
          />
        )}
      </div>
    </div>
  );
};

InfoTextBox.propTypes = {
  description: string.isRequired,
  layout: string.isRequired,
  see_less: string.isRequired,
  see_more: string.isRequired,
  title: string.isRequired,
};

InfoTextBoxDescription.propTypes = {
  collapsed: bool.isRequired,
  description: string.isRequired,
  handleClick: func.isRequired,
  see_less: string.isRequired,
  see_more: string.isRequired,
};

export default InfoTextBox;
