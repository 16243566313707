const TEXT_GROUP_REGEX = /(\{\w+\}|[^{}]+)/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;
const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;

/**
 * Interpolates a label by replacing placeholders with corresponding values or icons.
 *
 * @param {Object} label - The label object containing text and values.
 * @param {Function} [iconFactory] - A function to build icons based on their ID.
 * @returns {Array} An array of strings and/or React elements with placeholders replaced.
 */
const runLabelInterpolator = (label, iconFactory) => {
  if (!label) {
    return [];
  }

  const { text, values } = label;

  if (!values) {
    return text ? [text] : [];
  }

  return text.match(TEXT_GROUP_REGEX).map((textGroup) => {
    const valueKey = textGroup.split(PLACEHOLDER_DELIMITERS_REGEX)[1];

    if (!valueKey) {
      return textGroup;
    }

    const valueProp = values.find((value) => value.key === valueKey);

    if (!valueProp) {
      return textGroup;
    }

    if (valueProp.type === 'icon' && iconFactory) {
      return iconFactory(valueProp.id);
    }

    return valueProp.text || textGroup;
  });
};

/**
 * Removes placeholders from a label string.
 *
 * @param {string} label - The label string containing placeholders in the format `{key}`.
 * @returns {string} The label string with placeholders removed and trimmed.
 */
const removePlaceholder = (label = '') => {
  if (label === null) {
    return '';
  }

  return label?.replace(PLACEHOLDER_FORMAT_REGEX, '').trim();
};

module.exports = { removePlaceholder, runLabelInterpolator };
