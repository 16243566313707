import React from 'react';
import { bool, number, func, string, shape } from 'prop-types';

import classnames from 'classnames';
import { Snackbar as AndesSnackbar } from '@andes/snackbar';

const namespace = 'ui-search-snackbar';

const SnackBar = ({ className, color, message, action, delay, show }) => {
  const { text, onClick, position } = action ?? {};

  return (
    <AndesSnackbar
      className={classnames(namespace, className)}
      message={message}
      delay={delay}
      color={color}
      action={{
        text,
        onClick,
        position,
      }}
      show={show}
    />
  );
};

SnackBar.propTypes = {
  action: shape({
    text: string,
    onClick: func,
    position: string,
  }),
  className: string,
  color: string.isRequired,
  delay: number,
  message: string.isRequired,
  show: bool,
};

SnackBar.defaultProps = {
  className: null,
  actionMessage: null,
  delay: 4000,
  onActionClick: null,
  show: true,
};

export default SnackBar;
