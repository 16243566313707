import React from 'react';
import { shape, string, arrayOf } from 'prop-types';

import classNames from 'classnames';
import { Text } from '@andes/typography';

import { Price } from '../../layout/renderer/map/common/price';
import { Attributes } from '../../layout/renderer/map/common/attributes';
import { namespace } from '../../layout/renderer/constants';

import IconOnlineVisitRequest from '../../icons/online-visit-request';

const ITEM_SEPARATOR = '|';

const ResContent = ({
  item: {
    price,
    discountSource,
    from,
    descriptions,
    title,
    available_units,
    seller_info,
    subtitles,
    online_visit_request,
  },
}) => {
  const [bedrooms, bathrooms, area] = descriptions;

  return (
    <div className={`${namespace}__content`}>
      <Text component="div" size="xs" color="secondary">
        {subtitles.operation}
      </Text>
      <Text component="div" size="s" className={`${namespace}__content-title`}>
        {title}
      </Text>
      <Text
        component="div"
        size="xs"
        color="secondary"
        className={classNames(`${namespace}__content-separator--8`, `${namespace}__content-seller`)}
      >
        {seller_info.official_store_text}
      </Text>
      <Price
        className={`${namespace}__content-separator--8`}
        price={price}
        from={from}
        discountSource={discountSource}
      />
      {descriptions && (
        <Attributes
          className={classNames(`${namespace}__content-separator--4`, `${namespace}__content-attributes`)}
          attributes={[bedrooms, bathrooms].filter(Boolean)}
          separator={ITEM_SEPARATOR}
        />
      )}
      {area?.label && (
        <Text component="div" size="xs" className={`${namespace}__content-separator--4`}>
          {area.label}
        </Text>
      )}
      {available_units && (
        <Text
          component="div"
          size="xs"
          color="secondary"
          weight="semibold"
          className={`${namespace}__content-separator--4`}
        >
          {available_units}
        </Text>
      )}
      {online_visit_request && (
        <Text
          component="div"
          size="xs"
          color="primary"
          weight="semibold"
          className={classNames(`${namespace}__content-separator--4`, `${namespace}__content-online-visit-request`)}
        >
          <IconOnlineVisitRequest />
          {online_visit_request}
        </Text>
      )}
    </div>
  );
};

ResContent.propTypes = {
  item: shape({
    title: string,
    subtitles: shape({
      operation: string,
    }),
    seller_info: shape({
      official_store_text: string,
    }),
    descriptions: arrayOf(
      shape({
        label: string,
      }),
    ),
    available_units: string,
    price: shape({}),
    from: shape({}),
    discountSource: string,
  }).isRequired,
};

export { ResContent };
