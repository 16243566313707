import React from 'react';
import { number, string, object } from 'prop-types';

import classnames from 'classnames';

import StarHalfIcon from '../icons/star-half';
import StarFullIcon from '../icons/star-full';
import StarEmptyIcon from '../icons/star-empty';

const namespace = 'ui-search-reviews';
const REVIEW_MAX_STARS = 5;
const MAX_DECIMAL_TO_FLOOR_ROUND = 0.7;
const MIN_DECIMAL_TO_CEIL_ROUND = 0.3;

const getStars = ({ value }) => {
  const full = Math.floor(value);
  const half = value - full > 0 ? 1 : 0;
  const empty = REVIEW_MAX_STARS - full - half;

  return { full, half, empty };
};

const getStarsLegacy = (rating) => {
  const decimal = (rating - Math.floor(rating)).toFixed(2);
  const full = decimal > MAX_DECIMAL_TO_FLOOR_ROUND ? Math.ceil(rating) : Math.floor(rating);
  const half = decimal < MIN_DECIMAL_TO_CEIL_ROUND ? Math.floor(rating - full) : Math.ceil(rating - full);
  const empty = REVIEW_MAX_STARS - half - full;

  return { full, half, empty };
};

const renderStars = (stars, rating) => {
  const { full, half, empty } = stars ? getStars(stars) : getStarsLegacy(rating);

  const ratings = [];

  let key = 0;

  for (let f = 0; f < full; f += 1) {
    ratings.push(<StarFullIcon key={(key += 1)} />);
  }

  for (let h = 0; h < half; h += 1) {
    ratings.push(<StarHalfIcon key={(key += 1)} />);
  }

  for (let e = 0; e < empty; e += 1) {
    ratings.push(<StarEmptyIcon key={(key += 1)} />);
  }

  return ratings;
};

const ReviewSummary = ({ className = '', rating = 4.5, stars, formattedTotal, formattedAverage, accesibilityText }) => (
  <div className={classnames(namespace, className)}>
    <span className="andes-visually-hidden">{accesibilityText}</span>
    <span className={`${namespace}__rating-number`} aria-hidden>
      {formattedAverage?.text ? formattedAverage.text : formattedAverage}
    </span>
    <span className={`${namespace}__ratings`} aria-hidden>
      {renderStars(stars, rating)}
    </span>
    <span className={`${namespace}__amount`} aria-hidden>
      {formattedTotal?.text ? formattedTotal.text : formattedTotal}
    </span>
  </div>
);

ReviewSummary.propTypes = {
  accesibilityText: string.isRequired,
  className: string,
  formattedAverage: object.isRequired,
  formattedTotal: object.isRequired,
  rating: number,
  stars: object.isRequired,
};

export default ReviewSummary;
