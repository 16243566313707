const MAIN_VARIANTS = {
  OFFICIAL_STORE: 'official-store',
  EXHIBITOR: 'exhibitor',
  DEALS: 'deals',
};

const LANDING_TYPES = {
  OFFICIAL_STORE: 'OFFICIAL_STORE',
  DEALS: 'DEALS',
  BASE: 'BASE',
};

const SVG_SPRITES = 'svg-sprites-icons';

export { MAIN_VARIANTS, LANDING_TYPES, SVG_SPRITES };
