/* eslint-disable max-len */
import React from 'react';

import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const namespace = 'ui-row-icon ui-row-icon--icon-melicoin';

const IconMelicoin = ({ className }) => (
    <div className={classnames(namespace, className)}>
      <Image src="icon-melicoin.svg" alt="" />
    </div>
);

IconMelicoin.propTypes = {
  className: string,
};

IconMelicoin.defaultProps = {
  className: null,
};

export default IconMelicoin;
