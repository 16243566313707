const SCROLL_BEHAVIOR = 'scrollBehavior';
const SMOOTH = 'smooth';
// Maximum number of miliseconds the animation will take.
const MAX_SCROLL_DURATION = 200;

const isSmoothScrollSupported = ({ document }) => SCROLL_BEHAVIOR in document.documentElement.style;

const getRequestAnimationFrame = (windowObj) =>
  windowObj.requestAnimationFrame ||
  windowObj.mozRequestAnimationFrame ||
  windowObj.webkitRequestAnimationFrame ||
  windowObj.msRequestAnimationFrame;

// The following methods and calculations are based on the current
// smooth scroll polyfill. See: https://github.com/iamdustan/smoothscroll
const now = (windowObj) => {
  if (windowObj?.performance?.now) {
    return windowObj.performance.now();
  }

  return Date.now();
};

// Adjust the `k` value to a cosine curve with range between 0 and 1.
// This curve describes the easing animation as long as the `k` value
// is also between 0 and 1. For visual reference you can put this
// function in any math graph tool.
const ease = (k) => 0.5 * (1 - Math.cos(Math.PI * k));

const getTimeFactor = (startTime, currentTime) => {
  const elapsedTimeSinceStart = currentTime - startTime;
  const elapsedRatio = elapsedTimeSinceStart / MAX_SCROLL_DURATION;
  const validElapsedRatioForAnimation = Math.min(elapsedRatio, 1);

  return ease(validElapsedRatioForAnimation);
};

const getCurrentY = (startY, targetY, elapsedTimeFactor) => {
  const distanceInPx = targetY - startY;
  const scrolledSinceStart = distanceInPx * elapsedTimeFactor;
  const nextScrollPosition = startY + scrolledSinceStart;

  return nextScrollPosition;
};

// Recursively scroll to a newly calculated position until reaching
// the target.
const step = (context) => {
  const { startTime, startY, targetY, windowObj } = context;
  const elapsedTimeFactor = getTimeFactor(startTime, now(windowObj));
  const currentY = getCurrentY(startY, targetY, elapsedTimeFactor);

  windowObj.scrollTo(0, currentY);

  if (currentY !== targetY) {
    getRequestAnimationFrame(windowObj)(() => step(context));
  }
};

const scrollElementIntoView = (element, windowObj) => {
  const bounds = element.getBoundingClientRect();

  getRequestAnimationFrame(windowObj)(() => {
    step({
      startTime: now(windowObj),
      startY: windowObj.scrollY,
      targetY: bounds.top,
      windowObj,
    });
  });
};

const smoothScroll = (element, windowObj) => {
  // If the browser does not support `requestAnimationFrame` we fallback
  // to the native implementation even if we lose the `smooth` behavior.
  const preferNativeImplementation = isSmoothScrollSupported(windowObj) || !getRequestAnimationFrame(windowObj);

  return preferNativeImplementation
    ? element.scrollIntoView({ behavior: SMOOTH })
    : scrollElementIntoView(element, windowObj);
};

module.exports = smoothScroll;
