/* eslint-disable react/prop-types */

import React, { useState, useRef } from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Card } from '@andes/card';
import { CarouselSnapped, CarouselSnappedSlide } from '@andes/carousel-snapped';

import CardContent from './card-content.pi';
import ItemBookmark from '../../../bookmark/bookmark';
import Link from '../../../../components-v2/link';
import { renderMediaTagGroup, isLayoutGallery } from '../desktop-card-content/common';
import ItemOfficialStore from '../../../card/official-store/official-store-label';
import useItem from '../../../../hooks/use-item';
import { CAROUSEL_SETTINGS, DEFAULT_DPI, LAZY_ON, LAZY_OFF, namespace, STACK, GRID, GALLERY } from '../constants';
import { useSearch } from '../../../../hooks/context';

function renderOfficialStoreItem(official_store, options, layout) {
  return (
    <ItemOfficialStore
      label={official_store.verbose_text}
      permalink={official_store.permalink}
      shouldRenderLinks
      title={official_store.verbose_text.text}
      tracks={official_store.tracks}
      className="official-store--pi"
      layout={layout}
    />
  );
}

function renderRealEstateAgencyLogo(item, options, layout) {
  const superPremium = includes(item.tags, 'super_premium');
  const isMultifamily = item.is_multifamily ?? false;

  if (superPremium || isMultifamily) {
    const realEstateAgencyLogo = get(item, 'seller_info.real_estate_agency_logo');
    const realEstateFantasyName = get(item, 'seller_info.real_estate_agency_fantasy_name');
    const isOfficialStoreAndStackLayout = layout === STACK && item.official_store;

    if (realEstateAgencyLogo) {
      return (
        <div className={`${namespace}-item-super_premium`}>
          <Image
            className={`real-estate-agency-logo__image--${layout}`}
            src={realEstateAgencyLogo}
            lazyload="on"
            lowEnd={options.lowEnd === true}
            alt={realEstateFantasyName}
          />
          {isOfficialStoreAndStackLayout ? (
            renderOfficialStoreItem(item.official_store, options)
          ) : (
            <span className="real-estate-agency-name">{realEstateFantasyName}</span>
          )}
        </div>
      );
    }

    return isOfficialStoreAndStackLayout ? (
      renderOfficialStoreItem(item.official_store, options)
    ) : (
      <span className="real-estate-agency-onlyname">{realEstateFantasyName}</span>
    );
  }

  return null;
}

function renderOfficialStore(item, options, layout) {
  const isSuperPremium = includes(item.tags, 'super_premium');
  const isMultifamily = item.is_multifamily ?? false;
  const officialStore = item.official_store;

  if (isSuperPremium || isMultifamily) {
    return renderRealEstateAgencyLogo(item, options, layout);
  }

  if (officialStore) {
    return renderOfficialStoreItem(officialStore, options);
  }

  return null;
}

function renderResContent(item, options, layout, title, permalink) {
  return (
    <>
      <Link
        className={`${namespace}__content-wrapper`}
        href={permalink}
        title={title}
        isInternal={false}
        target={item.target}
      >
        <CardContent item={item} layout={layout} />
      </Link>
      {layout === STACK && renderOfficialStore(item, options, layout)}
      {(layout === GRID || layout === GALLERY) && item.official_store ? (
        <div className="ui-search-item__official-store-container">
          {renderOfficialStoreItem(item.official_store, options, layout)}
        </div>
      ) : null}
    </>
  );
}

const PictureSlide = ({ picture, item, picturesStripStyles }) => (
  <CarouselSnappedSlide key={picture.src}>
    <Link
      className={picturesStripStyles}
      href={item.permalink}
      title={item.title}
      isInternal={false}
      target={item.target}
    >
      <Image
        key={picture.src}
        alt={picture.title}
        className={`${namespace}-image__element`}
        src={picture.src}
        preload
        lazyload={picture.lazyload}
        lowEnd={picture.lowEnd}
      />
    </Link>
  </CarouselSnappedSlide>
);

const normalizePictureData = (picture, title, options) => {
  const imgSrc = get(picture, options.imageResolution || DEFAULT_DPI) || get(picture, 'url');

  return {
    ...picture,
    src: imgSrc,
    lazyload: options.preload ? LAZY_OFF : LAZY_ON,
    lowEnd: options.lowEnd,
    preload: options.preload || false,
    title,
  };
};

// TODO: render creado para la VI
const DesktopLayoutItemPI = ({ index, item: middlewareItem, layout, onMount, options }) => {
  const cardRef = useRef(null);
  const itemContext = useItem({
    index,
    middlewareItem,
    layout,
    onMount,
    cardRef,
  });

  const { bookmark } = useSearch();

  const { item, selectedVariation, itemPictures, isLoadingPictures, handleCardHover } = itemContext;
  const { title, permalink, vertical, target } = item;
  const itemVertical = vertical ? vertical.toLowerCase() : null;
  // @TODO Quitar esto cuando se simplifique si es gallery o grid para desktop.
  const isGridLayout = layout === GRID;
  const [carouselSettings] = useState(CAROUSEL_SETTINGS);

  const shouldRenderBookmark = bookmark?.show_user_bookmarks && !item.pds_info;

  const resultWrapperStyles = classnames(`${namespace}__wrapper`);
  const cardStyles = classnames(namespace, {
    [`${namespace}--${itemVertical}`]: itemVertical,
    [`${namespace}--pill-p`]: item.is_ad,
  });
  const picturesStripStyles = classnames(`${namespace}__image`, {
    [`${namespace}__image--loading`]: isLoadingPictures(item.id),
  });

  return (
    <div className={resultWrapperStyles} ref={cardRef} onMouseEnter={handleCardHover} onFocus={handleCardHover}>
      <Card animated={isGridLayout} className={cardStyles}>
        <CarouselSnapped
          className={`${namespace}__card`}
          key={get(selectedVariation, 'id', get(item, 'id'))}
          strictBoundaries={false}
          pagination={carouselSettings.dots}
          type="full"
          slidesPerView={carouselSettings.slidesToShow}
          arrowsVisible={carouselSettings.arrowsVisible}
          infinite={carouselSettings.infinite}
        >
          {itemPictures
            .map((picture) => ({
              picture: normalizePictureData(picture, title, options),
              item,
              picturesStripStyles,
            }))
            .map(PictureSlide)}
        </CarouselSnapped>
        <Link className={picturesStripStyles} href={permalink} title={title} isInternal={false} target={target}>
          {layout === GRID && renderRealEstateAgencyLogo(item, options, layout)}
        </Link>
        <div
          className={classnames(`${namespace}__content`, {
            'no-borders': layout === GRID || layout === GALLERY,
          })}
        >
          {renderResContent(item, options, layout, title, permalink)}
          {item.is_ad && <span className="ui-search-item__ad_label">{item.ad_label}</span>}
        </div>

        {shouldRenderBookmark && (
          <div
            className={classnames(`${namespace}__bookmark`, {
              [`${namespace}__bookmark-active`]: item.bookmarked,
            })}
          >
            <ItemBookmark item={item} />
          </div>
        )}

        {isLayoutGallery(layout) ? (
          <div
            className={classnames(`${namespace}__media-highlighted-bottom`, {
              [`flex-end`]: !item.media,
            })}
          >
            {renderMediaTagGroup(item)}
            {item.highlighted && (
              <div className="ui-search-item__highlighted-wrapper">
                <span className={`ui-search-item__highlighted-${layout}`}>{item.highlighted}</span>
              </div>
            )}
          </div>
        ) : (
          <>
            {layout === GRID && (
              <div className="ui-search-item__possession-wrapper">
                <span className="ui-search-item__possession">{item.possession}</span>
              </div>
            )}
            {item.highlighted && (
              <div className="ui-search-item__highlighted-wrapper">
                <span className={`ui-search-item__highlighted-${layout}`}>{item.highlighted}</span>
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

DesktopLayoutItemPI.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onMount: func,
};

DesktopLayoutItemPI.defaultProps = {
  onMount: undefined,
  options: {},
};

export default DesktopLayoutItemPI;
