import React from 'react';
import { shape, string, number } from 'prop-types';

import ProgressIndicatorLinear from '@andes/progress-indicator-linear';

const ProgressBarDefault = ({ namespace, progress_bar }) => (
  <div className={`${namespace}__progressbar-container`}>
    <ProgressIndicatorLinear color={progress_bar.color_hex} highlight value={progress_bar.percent} />
  </div>
);

ProgressBarDefault.propTypes = {
  namespace: string.isRequired,
  progress_bar: shape({
    color_hex: string,
    percent: number,
  }),
};

export default ProgressBarDefault;
