import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--trade-in';

const ICON_ID = 'poly_trade_in';

const IconTrade = ({ className, description }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconTrade.propTypes = {
  className: string,
  description: string,
};

IconTrade.defaultProps = {
  className: null,
};

export default React.memo(IconTrade);
