import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--virtual-tour';

const IconVirtualTour = ({ className }) => (
  <svg className={classnames(namespace, className)} width="23" height="12" viewBox="0 0 23 12" aria-hidden="true">
    <use href="#poly_tour" />
  </svg>
);

IconVirtualTour.propTypes = {
  className: string,
};

IconVirtualTour.defaultProps = {
  className: null,
};

export default React.memo(IconVirtualTour);
