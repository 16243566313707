import React from 'react';
import { arrayOf, objectOf, shape, string, any, bool, number } from 'prop-types';

import { useCurrentLayout } from '../../../context/layout';
import PresentationAddToCart from '../presentation/add-to-cart';
import { sendTracks } from '../utils';
import useCartHandlers from '../hooks/useCartHandlers';

const AddToCart = ({
  action,
  availableQuantity,
  cart_request_delay,
  cart_request_initial_delay,
  category,
  isCpg,
  itemId,
  label,
  label_count,
  labelMinQuantity,
  minimumQuantity,
  productId,
  quantity,
  tracks,
  type,
  variation_id,
  weight_data,
  inventoryId,
  groupBy,
  threshold,
  should_call_suggestions,
  isAd,
  permalink,
  viewMode,
}) => {
  const currentLayout = useCurrentLayout();
  const { handleAddToCartDelete, handleAddToCartPut, labelQuantity, itemQuantity, itemMaxStock } = useCartHandlers({
    availableQuantity,
    minimumQuantity,
    itemId,
    category,
    cart_request_initial_delay,
    cart_request_delay,
    variation_id,
    isCpg,
    productId,
    inventoryId,
    weight_data,
    tracks,
    groupBy,
    threshold,
    viewMode,
    isAd,
    permalink,
    should_call_suggestions,
    label_count,
    quantity,
  });

  return (
    <PresentationAddToCart
      action={action}
      handleAddToCartDelete={handleAddToCartDelete}
      handleAddToCartPut={handleAddToCartPut}
      itemCount={itemQuantity}
      itemId={itemId}
      label={label}
      labelCount={labelQuantity}
      labelMinQuantity={labelMinQuantity}
      maxValue={itemMaxStock}
      sendButtonTracks={sendTracks}
      type={type}
      permalink={permalink}
      viewMode={viewMode === 'intervention' ? viewMode : currentLayout?.id}
    />
  );
};

AddToCart.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
  }).isRequired,
  availableQuantity: number,
  cart_request_delay: number,
  cart_request_initial_delay: number,
  category: string.isRequired,
  groupBy: string,
  inventoryId: string,
  isAd: bool,
  isCpg: bool,
  itemId: string.isRequired,
  label: string.isRequired,
  label_count: objectOf(any),
  labelMinQuantity: shape({
    color: string,
    text: string,
  }),
  minimumQuantity: number,
  permalink: string,
  productId: string,
  quantity: number.isRequired,
  should_call_suggestions: bool,
  threshold: number,
  tracks: shape({
    melidata_track: objectOf(any),
  }).isRequired,
  type: string.isRequired,
  variation_id: string,
  viewMode: string,
  weight_data: arrayOf(shape()),
};

AddToCart.defaultProps = {
  availableQuantity: 0,
  cart_request_delay: null,
  cart_request_initial_delay: null,
  isCpg: false,
  label_count: {},
  labelMinQuantity: undefined,
  minimumQuantity: 0,
  productId: null,
  weight_data: undefined,
  viewMode: null,
};

export default AddToCart;
