import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';
import Tooltip from '@andes/tooltip';

import { FilterLink } from '../../../../components-v2/link';
import ProcessedDescription from './processed-description.desktop';

const namespace = 'ui-search-filter-official-store';

const FilterOfficialStore = ({ id, name, expanded_values, className }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)}>
    {expanded_values.map((value) => (
      <div className={`${namespace}__container`}>
        <FilterLink title={value.name} href={value.url}>
          <Tooltip content={<ProcessedDescription {...value} />} side="bottom" className={`${namespace}__tooltip`}>
            <Image alt={name} className={`${namespace}__image`} src={value.logo_url} lowEnd />
          </Tooltip>
        </FilterLink>
      </div>
    ))}
  </div>
);

FilterOfficialStore.propTypes = {
  className: string,
  expanded_values: shape({}),
  id: string,
  name: string,
};

FilterOfficialStore.defaultProps = {
  className: '',
  expanded_values: null,
  id: '',
  name: '',
};

export default FilterOfficialStore;
