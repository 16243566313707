import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import ChevronDown from '@andes/icons/ChevronDown20';
import Card, { CardHeader, CardContent } from '@andes/card';

const namespace = 'ui-search-collapsible-card';

const CollapsibleCardHeader = ({ title, isExpanded, onClick }) => {
  const andesBlueHexCode = '#3483fa';

  return (
    <button className={classnames(`${namespace}__container`)} onClick={onClick} type="button">
      <h3 className={classnames(`${namespace}__title`)}>
        <strong>{title}</strong>
      </h3>
      <div className={classnames(`${namespace}__icon`, { [`${namespace}__icon--is-expanded`]: isExpanded })}>
        <ChevronDown color={andesBlueHexCode} />
      </div>
    </button>
  );
};

const CollapsibleCardContent = ({ content, isExpanded }) => (
  <CardContent className={classnames(`${namespace}__content`, { [`${namespace}__content--is-expanded`]: isExpanded })}>
    <div
      className={classnames(`${namespace}__content-text`)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </CardContent>
);

const CollapsibleCard = ({ title, content, isExpanded, onClick }) => (
  <Card className={classnames(namespace)} shadow="outline" paddingSize="0">
    <CardHeader className={classnames(`${namespace}__header`)}>
      <CollapsibleCardHeader title={title} onClick={onClick} isExpanded={isExpanded} />
    </CardHeader>
    <CollapsibleCardContent content={content} isExpanded={isExpanded} />
  </Card>
);

CollapsibleCard.propTypes = {
  content: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
CollapsibleCardHeader.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
CollapsibleCardContent.propTypes = {
  content: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

CollapsibleCard.defaultProps = {
  isExpanded: false,
};

export default CollapsibleCard;
