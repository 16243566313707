import React from 'react';

import BaseModal from './base.mobile';
import useModal from './hooks/use-modal';

// Default Modal implementation
const Modal = (props) => {
  const [isOpen, openModal, hideModal] = useModal();

  return <BaseModal {...props} isOpen={isOpen} openModal={openModal} hideModal={hideModal} />;
};

export default Modal;
