const NO_INDEX_TRUE = '_NoIndex_True';

const appendNoIndex = (url) => {
  const canApplyNoIndex = url.indexOf(NO_INDEX_TRUE) === -1;

  if (canApplyNoIndex) {
    return `${url}${NO_INDEX_TRUE}`;
  }

  return url;
};

module.exports = appendNoIndex;
