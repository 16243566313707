import React from 'react';

import { loadable } from 'nordic/lazy';

import usePricingBanner from './use-pricing-banner';

const ChunkPricingBannerContainer = () => {
  const pricingBannerProps = usePricingBanner();
  const PricingBanner = loadable(async () => import(/* webpackChunkName: "chunk-pricing-banner" */ './pricing-banner'));

  return pricingBannerProps ? <PricingBanner {...pricingBannerProps} /> : null;
};

export default ChunkPricingBannerContainer;
