import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import classnames from 'classnames';
import { arrayOf, func, shape, string } from 'prop-types';
import { PACKAGE_NAMESPACE } from '../utils/constants';
import { getVariablesFromText, getVariablesObject, splitVariablesAndText } from '../utils/utilities';
import renderComponentsFromBuilder from '../utils/renderComponentsFromBuilder';
import StyledLabel from './styled-label';

/**
 * @param {string} text
 * @param {array} values
 */



const LabelBuilder = forwardRef(({
  accessibility_text,
  text,
  values= null,
  styles= null,
  class_name= null,
  html_tag= null,
  onClick= null,
  url= null,
  id,
  target,
  sourceIcons,
}, ref) => {
  const [content, setContent] = useState(values);

  useEffect(() => {
    setContent(values);
  }, [values]);

  useImperativeHandle(ref, () => ({
    changeValue(key, value) {
      const newContent = content.map((v) => {
        if (v.key === key) {
          return { ...v, text: value };
        }
        return v;
      });

      setContent(newContent);
    },
    getValue(key) {
      const value = content.find((v) => v.key === key);
      return value;
    },
  }));

  if (!content) {
    return (
      <StyledLabel
        text={accessibility_text ?? text}
        styles={styles}
        className={class_name?.toLowerCase()}
        html_tag={html_tag}
        onClick={onClick}
        href={url}
        id={id}
        target={target}
      />
    );
  }

  const NAMESPACE = class_name
    ? `${PACKAGE_NAMESPACE}-label-builder ${class_name}`
    : `${PACKAGE_NAMESPACE}-label-builder`;

  const result = splitVariablesAndText(text);

  const vars = getVariablesFromText(text);

  const replacements = getVariablesObject(vars, content);

  const componentsToRender = useMemo(() => renderComponentsFromBuilder(result, replacements, sourceIcons), [result, replacements, sourceIcons]);

  return (
    <p
      className={classnames(class_name, NAMESPACE, id)}
      style={{ ...styles }}
    >
      { componentsToRender }
    </p>
  );
});


LabelBuilder.propTypes = {
  accessibility_text: string,
  text: string,
  values: arrayOf(shape({})),
  styles: shape({}),
  class_name: string,
  html_tag: string,
  onClick: func,
  url: string,
  id: string,
  sourceIcons: shape({}),
  target: string,
};


export default LabelBuilder;
