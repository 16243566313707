/* eslint-disable react/prop-types */
import React from 'react';

import MeliPlayInterventionMobile from '../../meliplay/grid/meliplay-intervention.mobile';
import MeliPlayInterventionDesktop from '../../meliplay/grid/meliplay-intervention.desktop';

const renderItemsIterventionsMobile = {
  MELIPLAY_INTERVENTION: ({ item, idx, imageGridHeight }) => (
    <MeliPlayInterventionMobile intervention={item} position={idx} imageGridHeight={imageGridHeight} />
  ),
};

const renderItemsIterventionsDesktop = {
  MELIPLAY_INTERVENTION: (item, idx) => <MeliPlayInterventionDesktop intervention={item} position={idx} />,
};

export { renderItemsIterventionsMobile, renderItemsIterventionsDesktop };
