import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';

import { useStaticProps } from '../../context/static-props';
import ComposedLabel from '../../composed-label/composed-label';
import { useSearch } from '../../../hooks/context';
import Link from '../../../components-v2/link';

const namespace = 'ui-search-item__title';
const HEADING_EXPERIMENT_SITE = 'MPE';

const Title = ({ className = null, title, compats = null, permalink, target, isAd, classNameLink, titleA11Y }) => {
  const { shops } = useSearch();
  const { siteId } = useStaticProps();
  const isShops = Boolean(shops);
  const experimentHeading = siteId && siteId.toUpperCase() === HEADING_EXPERIMENT_SITE;
  const Heading = experimentHeading ? 'h3' : 'h2';
  const linkClassName = classNameLink ?? 'ui-search-link__title-card';
  const titleClassName = titleA11Y ?? title;

  return (
    <Heading
      aria-level="3"
      className={classnames(namespace, {
        [`${className}`]: className,
        [`${namespace}-compats`]: compats,
        'shops__item-title': isShops,
      })}
    >
      <Link
        href={permalink}
        title={titleClassName}
        isInternal={false}
        target={target}
        isAd={isAd}
        className={linkClassName}
      >
        {title}
      </Link>
      {compats && <ComposedLabel {...compats} className={`${namespace}__compats-label`} />}
    </Heading>
  );
};

Title.propTypes = {
  className: string,
  compats: shape({
    bg_color: string,
    color: string,
    font_size: string,
    text: string,
    font_family: string,
    values: shape({
      compatibility: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      user_selection: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
    }),
  }),
  title: string.isRequired,
  permalink: string.isRequired,
  target: string.isRequired,
  classNameLink: string,
  titleA11Y: string,
};

export default Title;
