import React, { Component } from 'react';
import { element, string, func } from 'prop-types';

import ErrorComponent from './error-component';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { newrelic } = window || {};
    const { customError } = this.props;

    if (newrelic) {
      newrelic.noticeError(error, {
        stack: error.stack,
        message: error.message,
      });
    }

    if (customError) {
      customError(error?.message);
    }
  }

  render() {
    const { children, siteId } = this.props;

    return this.state.hasError ? <ErrorComponent siteId={siteId} /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  customError: func,
  siteId: string.isRequired,
};

ErrorBoundary.defaultProps = {
  customError: null,
};

export default ErrorBoundary;
