import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--meliplus';

const MeliPlus = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="meli-plus.svg" alt="Icono de Meli +" />
  </div>
);

MeliPlus.propTypes = {
  className: string,
};

MeliPlus.defaultProps = {
  className: null,
};

export default React.memo(MeliPlus);
