/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useCallback } from 'react';

import debounce from 'lodash/debounce';

import { BOUNDING_BOX_PADDINGS, INITIAL_MAP_STATUS, SAFE_AREA_PADDINGS } from '../constants';
import { useStaticProps } from '../../context/static-props';
import { EVENTS, getBounds, padBounds } from '../lib';

const { BOUNDS_CHANGED, DRAG_END, IDLE, ZOOM_CHANGED } = EVENTS;
const GET_RESULTS_DEBOUNCE_MS = 500;

const useMapState = ({
  fitMarkersInMap,
  isLoadingResults,
  markers,
  updateResults,
  fixedCoords,
  selectedMarker = null,
  setSelectedMarker = () => undefined,
  panToSelectedMarker = false,
}) => {
  const { deviceType } = useStaticProps();
  const [map, setMap] = useState(null);
  const [mapEvent, setMapEvent] = useState(null);
  const [mapStatus, setMapStatus] = useState(INITIAL_MAP_STATUS);

  const isUIReady = !isLoadingResults && !!mapEvent;

  const getNewResults = useCallback(
    debounce(() => {
      updateResults(padBounds(map, BOUNDING_BOX_PADDINGS[deviceType]).toJSON());
      setMapEvent(IDLE);
    }, GET_RESULTS_DEBOUNCE_MS),
    [updateResults, map, setMapEvent],
  );

  const handleMapLoad = (mapInstance) => setMap(mapInstance);

  const handleDragEnd = () => {
    setMapEvent(DRAG_END);
    getNewResults();
  };

  const handleIdle = () => {
    if (!mapEvent) {
      setMapEvent(IDLE);
    }
  };

  // We need to differentiate between an user and a programmatically triggered zoom_changed event
  // (e.g. when fitMarkersInMap is true).
  // Looks like isUIReady works to differentiate initial zoom event from the rest
  const handleZoomChanged = () => {
    if (isUIReady) {
      setMapEvent(ZOOM_CHANGED);
      getNewResults();
    }
  };

  // The bounds_changed event is always triggered after the zoom_changed event, so we need to
  // check whether the zoom_changed event was triggered by the user or not.
  const handleBoundsChanged = () => {
    if (mapEvent === ZOOM_CHANGED || mapEvent === DRAG_END) {
      setMapEvent(BOUNDS_CHANGED);
      getNewResults();
    }
  };

  useEffect(() => {
    if (isLoadingResults) {
      setSelectedMarker(null, null);
    }
  }, [isLoadingResults]);

  useEffect(() => {
    if (map && fitMarkersInMap) {
      setMapEvent(null);
      map.fitBounds(getBounds(markers, fixedCoords), BOUNDING_BOX_PADDINGS[deviceType]);
    }
  }, [map, markers, fitMarkersInMap, fixedCoords]);

  useEffect(() => {
    if (map && isUIReady) {
      setMapStatus({ bounds: map.getBounds(), zoom: map.getZoom() });
    }
  }, [map, isUIReady]);

  useEffect(() => {
    if (map && panToSelectedMarker && selectedMarker !== null && markers[selectedMarker]) {
      const { latitude, longitude } = markers[selectedMarker];
      const markerBounds = getBounds([{ latitude, longitude }]);

      map.panToBounds(markerBounds, SAFE_AREA_PADDINGS[deviceType]);
    }
  }, [panToSelectedMarker, map, markers, selectedMarker]);

  return {
    handleDragEnd,
    handleMapLoad,
    handleZoomChanged,
    handleBoundsChanged,
    handleIdle,
    mapStatus,
  };
};

export { useMapState };
