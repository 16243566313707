import componentFactoryFrom from './factory';
import types from './component-types';
import grouped from '../grouped/grouped';
import breadcrumb from '../sidebar/components/breadcrumb/breadcrumb';
import mapLink from '../sidebar/components/map/map-link.desktop';
import mapButton from '../sidebar/components/map/map-button.desktop';
import appliedFilters from '../filters/applied/applied-filters.desktop';
import filters from '../sidebar/components/filters/filters.desktop';
import alert from '../alert/alert.desktop';
import searchSave from '../alert-v2/search-save.desktop';
import searchResult from '../sidebar/components/search-result/search-result';
import moneyPicker from '../money-picker/money-picker.desktop';
import skyBanner from '../sidebar/components/sky-banner/index';
import searchShops from '../sidebar/components/search-shops-ads/index';
import advertisingAdn from '../advertising-adn/index';
import styledLabel from '../styled-label/styled-label';

const sources = {};

sources[types.GROUPED] = grouped;
sources[types.BREADCRUMB] = breadcrumb;
sources[types.MAP_LINK] = mapLink;
sources[types.MAP_BUTTON] = mapButton;
sources[types.APPLIED_FILTERS] = appliedFilters;
sources[types.AVAILABLE_FILTERS] = filters;
sources[types.ALERT] = alert;
sources[types.ALERT_V2] = searchSave;
sources[types.TOTAL_RESULTS] = searchResult;
sources[types.MONEY_PICKER] = moneyPicker;
sources[types.SIDEBAR_ADVERTISING] = skyBanner;
sources[types.SEARCH_SHOPS_ADS] = searchShops;
sources[types.ADN] = advertisingAdn;
sources[types.ACCESIBLE_LABEL] = styledLabel;

const ComponentFactory = componentFactoryFrom(sources);

export default ComponentFactory;
