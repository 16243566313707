const get = require('lodash/get');

const isEmptyString = (value) => !value || value.toString().trim().lenght === 0;

const ifEmptyThenNull = (value, path) => (isEmptyString(get(value, path)) ? undefined : get(value, path));

const getEmbeddedData = (initialState, currentUser) => ({
  user_id: ifEmptyThenNull(currentUser, 'id'),
  is_identifier: get(currentUser, 'loggedIn', false),
  category_id: ifEmptyThenNull(initialState, 'melidata_track.event_data.category_id'),
  flow_type: ifEmptyThenNull(initialState, 'results[0].cpg.tracks.melidata_track.event_data.flow_type'),
});

module.exports = {
  getEmbeddedData,
};
