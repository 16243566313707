import { COMPONENT_TYPE } from '../../utils/constants';
import Highlight from '../../highlight';
import Price from '../price-part';
import getIcon from '../../icons/get-icon';
import StyledLabel from '../styled-label';

const { FN_ICON, JSX, SINGLE_TAG } = COMPONENT_TYPE;

const SOURCES = {
  BREAK: {
    Component: 'br',
    kind: SINGLE_TAG,
  },
  DEFAULT: {
    Component: StyledLabel,
    kind: JSX,
  },
  LABEL: {
    Component: StyledLabel,
    kind: JSX,
  },
  HIGHLIGHT: {
    Component: Highlight,
    kind: JSX,
  },
  ICON: {
    Component: getIcon,
    kind: FN_ICON,
  },
  PRICE: {
    Component: Price,
    kind: JSX,
  },
};

export default SOURCES;
