import React from 'react';
import { string, shape, number } from 'prop-types';

import classnames from 'classnames';

import Price from '../price';
import { MOSAIC } from '../../layout/renderer/constants';

const namespace = 'ui-search-installments';

const PRICE_FORMAT_SPECIFIER = '{price}';

const Installments = ({ className = null, installments, price: initialPrice, layout = null }) => {
  const price = initialPrice || {};
  const { accessibility_text, text, amount, currency_id, color, prefix_pill } = installments;
  const priceIndex = text.indexOf(PRICE_FORMAT_SPECIFIER);
  const installmentsClassName = classnames(className, namespace, `ui-search-color--${color}`, {
    [`${namespace}-pill-space`]: prefix_pill,
  });
  const showDecimals = amount && parseInt(amount.toFixed(2).split('.')[0], 10) < 1000;

  if (priceIndex > 0) {
    return (
      <span className={installmentsClassName}>
        {prefix_pill && <b className={`${namespace}-pill`}>{`${prefix_pill}`}</b>}

        {price.rebate_price && layout !== MOSAIC && (
          <div className={`${namespace}-prefix`}>
            {price.rebate_price.amount && (
              <Price
                amount={price.rebate_price.amount}
                currency_id={currency_id}
                size="x-tiny"
                showDecimals={showDecimals}
              />
            )}
            <span className={classnames({ [`ui-search-color--${color}`]: prefix_pill })}>
              {price.rebate_price.text}
            </span>
          </div>
        )}
        {text.substring(0, priceIndex)}
        <Price
          amount={amount}
          currency_id={currency_id}
          size="x-tiny"
          className={`ui-search-color--${color}`}
          showDecimals={showDecimals}
        />
        {text.substring(priceIndex + PRICE_FORMAT_SPECIFIER.length).trim()}
      </span>
    );
  }

  return (
    <div className={installmentsClassName} aria-label={accessibility_text || undefined}>
      {prefix_pill && <b className={`${namespace}-pill`}>{`${prefix_pill}`}</b>}
      <span aria-hidden={!!accessibility_text}>{text}</span>
    </div>
  );
};

Installments.propTypes = {
  className: string,
  installments: shape({
    accessibility_text: string,
    text: string.isRequired,
    amount: number,
    currency_id: string,
    color: string.isRequired,
  }).isRequired,
  layout: string,
  price: shape({}),
};

export default Installments;
