import { useSearch } from '../../hooks/context';
import { useStaticProps } from '../context/static-props';

const useCarousel = () => {
  const { carousel } = useSearch();
  const { lowEnd } = useStaticProps();

  return lowEnd ? null : carousel;
};

export default useCarousel;
