import { useState, useEffect } from 'react';

import { useStaticProps } from '../components/context/static-props';
import useFilterSearch from './use-filter-search';
import appendNoIndex from '../lib/seo-append-no-index';
import {
  MIN_INPUT_NAME,
  SINCE_ITEM_NAME,
  UNTIL_ITEM_NAME,
  MAX_INPUT_NAME,
  TYPE_RANGE,
  FILTERS,
  DEVICE_TYPES,
  LAYOUTS,
} from '../constants';
import { linkBuildNewHref } from '../components-v2/link';
import useMapConfig from '../components/map/hooks/use-map-config';
import { trackClickEvent } from '../components/map/track';
import { useSearch } from './context';
import { getLayout } from '../components/context/layout';

const { IDS } = FILTERS;
const { MOBILE } = DEVICE_TYPES;
const VALUE_ID = 'UNKNOWN_VALUE_ID';
const APPLIED_VALUE_ID = 'UNKNOWN_APPLIED_VALUE_ID';

const getNewRangeUrl = (min, max, urlTemplate, activeCurrencyId) => {
  let newUrl = null;

  if (min && !max) {
    newUrl = urlTemplate.only_from.replace('$from', min);
  } else if (max && !min) {
    newUrl = urlTemplate.only_to.replace('$to', max);
  } else {
    newUrl = urlTemplate.from_and_to.replace('$from', min).replace('$to', max);
  }

  if (!activeCurrencyId) {
    return newUrl;
  }

  return newUrl.replace(/\$currency/gi, activeCurrencyId);
};

const useFilterRange = ({ filterId, labels, url, activeCurrencyId, type, fragment }) => {
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [rangeUrl, setRangeUrl] = useState();
  const [dropdownItemSelected, setDropdownItemSelected] = useState(false);
  const defaultValue = { from: '', to: '' };
  const filterSearch = useFilterSearch(rangeUrl, filterId);
  const disabled = min?.length === 0 && max?.length === 0;
  const isDropdown = type === TYPE_RANGE.DROPDOWN;
  const { deviceType } = useStaticProps();
  const { queryParams } = useStaticProps();
  const { searchTrack } = useMapConfig();
  const { layout_options } = useSearch();

  const labelsData = [
    {
      label: isDropdown ? SINCE_ITEM_NAME : MIN_INPUT_NAME,
      type,
      text: isDropdown ? labels.from : labels.minimum,
    },
    {
      label: isDropdown ? UNTIL_ITEM_NAME : MAX_INPUT_NAME,
      type,
      text: isDropdown ? labels.to : labels.maximum,
    },
  ];

  const changeRangeUrl = (rangeMin, rangeMax) => {
    const changeUrl = getNewRangeUrl(rangeMin, rangeMax, url, activeCurrencyId);

    setRangeUrl(changeUrl);

    return changeUrl;
  };

  const handleOnSubmit = (event, { newUrl = rangeUrl } = {}) => {
    event.preventDefault();

    const track = {
      melidata: {
        melidata_track: {
          ...searchTrack,
          type: 'view',
        },
      },
    };

    if (layout_options?.current === LAYOUTS.TYPE_MAP) {
      trackClickEvent(null, track.melidata);
    }

    const idTemplate = {
      min: min > 0 ? min : '*',
      max: max > 0 ? max : '*',
    };

    const valueID = `${idTemplate.min}-${idTemplate.max}`;

    const valueName =
      filterId === IDS.PRICE ? `${activeCurrencyId}${idTemplate.min}-${activeCurrencyId}${idTemplate.max}` : valueID;

    const editedFragment = fragment?.replace(VALUE_ID, valueID).replace(APPLIED_VALUE_ID, valueName);

    const isClientSideSearch = !!filterSearch;

    if (isClientSideSearch) {
      filterSearch();

      return;
    }

    const urlWithNoIndex = appendNoIndex(newUrl);

    const formatUrl = `${urlWithNoIndex}#${editedFragment}`;

    const { currentLayout } = getLayout();

    window.location.href = linkBuildNewHref({ href: formatUrl, queryParams, isInternal: true, currentLayout });
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const finalValue = value.replace(',', '.');

    if (event.target.name === MIN_INPUT_NAME) {
      setMin(finalValue);
      changeRangeUrl(finalValue, max);
    } else {
      setMax(finalValue);
      changeRangeUrl(min, finalValue);
    }
  };

  useEffect(() => {
    changeRangeUrl(min, max);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrencyId]);

  const handleChangeDropdown = {
    From: (e, value) => {
      const newValue = value === defaultValue.from ? defaultValue.from : value;

      setMin(newValue);
      changeRangeUrl(newValue, max);
      setDropdownItemSelected(false);
    },
    To: (e, value) => {
      const newValue = value === defaultValue.to ? defaultValue.to : value;

      setMax(newValue);

      const newUrl = changeRangeUrl(min, newValue);

      setDropdownItemSelected(false);

      if (deviceType === MOBILE) {
        handleOnSubmit(e, { newUrl });
      }
    },
  };

  return {
    handleChangeDropdown,
    handleInputChange,
    handleOnSubmit,
    labelsData,
    disabled,
    dropdownItemSelected,
    setDropdownItemSelected,
  };
};

export default useFilterRange;
