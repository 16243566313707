import React from 'react';
import { string, objectOf, any, oneOf } from 'prop-types';

import Button, { ButtonText } from '@andes/button';

import FilterTooltip from '../filters-tooltip/filters-tooltip.desktop';
import IconMap from '../../../icons/map.desktop';
import IconListDesktop from '../../../icons/list.desktop';
import { trackClickEvent } from '../../../map/track';

const namespace = 'ui-search-map';

const MAP_LINK = 'MAP_LINK';
const LIST_LINK = 'LIST_LINK';
const DEFAULT = 'DEFAULT';

const TYPE = {
  [LIST_LINK]: { hierarchy: 'quiet', IconComponent: IconListDesktop },
  [MAP_LINK]: { hierarchy: 'quiet', IconComponent: IconMap },
  [DEFAULT]: { hierarchy: 'quiet' },
};
const MapButtonDesktop = ({ url, label, melidata_track_value, analytics_track_event, tooltip, type }) => {
  const handleClick = (evt) => {
    evt.preventDefault();
    trackClickEvent(analytics_track_event, melidata_track_value);
    window.location.href = url;
  };

  const { hierarchy, IconComponent } = TYPE[type] || TYPE.DEFAULT;

  return (
    <div className={`${namespace}__button-container`}>
      <FilterTooltip className={`${namespace}__tooltip`} tooltip={{ ...tooltip, type: tooltip?.type || 'dark' }}>
        <Button size="medium" className={`${namespace}__button`} href={url} onClick={handleClick} hierarchy={hierarchy}>
          {IconComponent && <IconComponent className={`${namespace}__icon`} />}

          <ButtonText>{label}</ButtonText>
        </Button>
      </FilterTooltip>
    </div>
  );
};

MapButtonDesktop.propTypes = {
  analytics_track_event: objectOf(any),
  label: string.isRequired,
  melidata_track_value: objectOf(any),
  tooltip: objectOf(any),
  type: oneOf([LIST_LINK, MAP_LINK, DEFAULT]),
  url: string.isRequired,
};

MapButtonDesktop.defaultProps = {
  analytics_track_event: null,
  melidata_track_value: null,
  tooltip: null,
};

export default MapButtonDesktop;
