/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { node, number, string } from 'prop-types';

import debounce from 'lodash/debounce';

const AutoHeight = ({ className, children, initialHeight }) => {
  const [height, setHeight] = useState(initialHeight);
  const container = useRef();

  const resizeContainer = useCallback(() => setHeight(window.innerHeight - container.current.offsetTop), []);

  useEffect(() => {
    if (container.current && window) {
      resizeContainer();
    }
  }, []);

  // Some filters may or may not be present, so we need to make sure the
  // container always takes the available height.
  useEffect(() => {
    if (!MutationObserver) {
      return () => {};
    }

    const observer = new MutationObserver(resizeContainer);

    observer.observe(container.current.parentNode, { childList: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = debounce(resizeContainer, 1000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={className} style={{ height }} ref={container}>
      {children}
    </div>
  );
};

AutoHeight.propTypes = {
  children: node.isRequired,
  className: string,
  initialHeight: number,
};

AutoHeight.defaultProps = {
  initialHeight: 500,
};

export default AutoHeight;
