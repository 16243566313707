/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';

import classnames from 'classnames';

import Chevron from '../../../icons/chevron';
import IconFull from '../../../icons/full';
import { trackEvent } from '../../../../lib/melidata';
import { useSearch } from '../../../../hooks/context';

const namespace = 'ui-search-breadcrumb';

const labelBuilder = (text, values) => {
  if (values) {
    const { key } = values[0];

    return text.split(' ').map((word) =>
      word === `{${key}}` ? (
        <span key={word} className="content-icon">
          <IconFull className="mr-5" />
        </span>
      ) : (
        `${word} `
      ),
    );
  }

  return text;
};

const BreadcrumbItem = ({ isLast, position, text, url }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <li
      className={classnames('andes-breadcrumb__item', { 'shops__breadcrumb-item': isShops })}
      itemProp="itemListElement"
      itemType="http://schema.org/ListItem"
      itemScope
      onClick={() =>
        trackEvent({
          path: '/search/breadcrumb/select',
          event_data: { url },
          clean: true,
        })
      }
    >
      <a href={url} title={text} itemProp="item" className="andes-breadcrumb__link">
        <span itemProp="name">{text}</span>
      </a>
      <meta itemProp="position" content={position} />
      {!isLast && <Chevron className="andes-breadcrumb__chevron ui-search-breadcrumb__chevron" />}
    </li>
  );
};

BreadcrumbItem.propTypes = {
  isLast: bool.isRequired,
  position: number.isRequired,
  text: string.isRequired,
  url: string.isRequired,
};

const Breadcrumb = ({
  label: initialLabel,
  nodes: initialNodes,
  h1 = '',
  html_tag = '',
  isMobile = false,
  link_h1 = '',
  show_h2 = false,
  text_link = '',
  values = null,
}) => {
  const label = initialLabel || {
    text: '',
  };
  const nodes = initialNodes || [];
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const text = label?.prefix ? `${label.prefix} ${label.text}` : label?.text || h1;
  const textLink = text_link || link_h1;
  const tag = show_h2 ? 'h2' : 'h1';
  const HtmlTag = html_tag || tag;

  return (
    <div className={classnames(namespace, { shops__breadcrumb: isShops })}>
      {!!nodes.length && (
        <ol className="andes-breadcrumb" itemType="http://schema.org/BreadcrumbList" itemScope>
          {nodes.map((node, index) => (
            <BreadcrumbItem {...node} isLast={index === nodes.length - 1} position={index + 1} />
          ))}
        </ol>
      )}
      {!isMobile && (
        <HtmlTag
          className={classnames(`${namespace}__title`, `${values ? 'max-width' : ''}`, {
            'shops-custom-primary-font': isShops,
          })}
        >
          {text_link ? (
            <a className={`${namespace}__link`} href={textLink}>
              {text}
            </a>
          ) : (
            labelBuilder(text, values)
          )}
        </HtmlTag>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  h1: string,
  html_tag: string,
  isMobile: bool,
  label: shape({
    text: string,
  }),
  link_h1: string,
  nodes: arrayOf(
    shape({
      text: string.isRequired,
      url: string.isRequired,
    }),
  ),
  show_h2: bool,
  text_link: string,
  values: arrayOf(
    shape({
      key: string,
    }),
  ),
};

export default Breadcrumb;
