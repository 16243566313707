/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import CardBlogCarousel from '@seo-frontend-components/card-blog-carousel';
import CardBlogCarouselMobile from '@seo-frontend-components/card-blog-carousel-mobile';

import { useStaticProps } from '../../context/static-props';
import { useSearch } from '../../../hooks/context';

const namespace = 'search-ui-seo-contents';

const BlogsIntervention = ({ isSeoIntervention, seo, title, seo_blogs, isMobile }) => {
  const blogs = seo_blogs.map((blog) => ({
    title: blog.title,
    url: blog.blog_id,
    image: {
      url: blog.image,
      alt: blog.alt_image,
    },
    description: blog.description,
    tags_metadata: blog.tags_metadata,
  }));

  const searchName = seo.h1;
  const blogsTitle = title && searchName ? `${title} ${searchName}` : '';

  return (
    <div className={classnames(`${namespace}__blogs-container ${isSeoIntervention ? 'seo-intervention-on' : ''}`)}>
      {isMobile ? (
        <CardBlogCarouselMobile title={blogsTitle} jsDisabled={false} supportWebP blogEntries={blogs} />
      ) : (
        <CardBlogCarousel title={blogsTitle} jsDisabled={false} supportWebP blogEntries={blogs} />
      )}
    </div>
  );
};

const BlogsComponent = ({ isSeoIntervention, seoBlogsInterventionObject }) => {
  const { deviceType } = useStaticProps();
  const { seo } = useSearch();
  const { title, seo_blogs } = seoBlogsInterventionObject;

  const isMobile = deviceType === 'mobile';

  return (
    <>
      {seoBlogsInterventionObject && (
        <BlogsIntervention
          isSeoIntervention={isSeoIntervention}
          seo={seo}
          title={title}
          seo_blogs={seo_blogs}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

BlogsComponent.propTypes = {
  isSeoIntervention: PropTypes.bool.isRequired,
  seoBlogsInterventionObject: PropTypes.object.isRequired,
};
BlogsIntervention.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSeoIntervention: PropTypes.bool.isRequired,
  seo: PropTypes.shape().isRequired,
  seo_blogs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogsComponent;
