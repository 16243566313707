import React from 'react';
import { shape, string } from 'prop-types';
import HighLightUI from './highlight-ui';

const Highlight = (props={}) => {
  const { text } = props;

  return <HighLightUI {...props} content={text} type="SINGLE" />;
};


Highlight.propTypes = {
  class_name: string,
  id: string,
  text: string.isRequired,
  styles: shape({}),
};


export default Highlight;
