import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';

const NAMESPACE = `${PACKAGE_NAMESPACE}-icon ${PACKAGE_NAMESPACE}-icon--warning`;

const ICON_ID = 'ic_pills_warning';

const IconWarning = ({ className, description }) => (
  <svg
    className={classnames(NAMESPACE, className)}
    xmlns="http://www.w3.org/2000/svg"
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);


IconWarning.propTypes = {
  className: string,
  description: string,
};

IconWarning.defaultProps = {
  className: null,
};

export default React.memo(IconWarning);
