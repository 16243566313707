import React from 'react';

import classnames from 'classnames';

import IconRecommendationsFull from '../recos-full';
import IconRecommendationsMeliCoins from '../icon-melicoin';
import IconRecommendationsMeliLogo from '../meli';
import IconRecommendationsChevron from '../chevron';
import IconRecommendationsBlackFriday from '../black-friday';
import IconRecommendationsMShopsLogo from '../mshops-logo';

const genIcon = (id, useId, width, height, viewBox, classNameClient = null) => {
  const obj = ({ className, ariaText }) => {
    const svgClassName = classnames(className, classNameClient);

    return (
      <svg
        className={svgClassName}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        aria-label={ariaText}
        fill="none"
      >
        <use href={`#${useId}`} />
      </svg>
    );
  };

  obj.ICON_ID = id;

  return obj;
};

const IconRecommendationsFullSuperMarket = genIcon('recommendations-full_icon', 'full', 41, 13, '0 0 41 13');
const IconRecommendationsCpg = genIcon('recommendations-cpg_icon', 'supermarket-logo', 100, 9, '0 0 100 9');
const IconRecommendationsHeartEmpty = genIcon('recommendations-heart-empty', 'poly_bookmark', 20, 20, '0 0 20 20');
const IconRecommendationsHeartFull = genIcon('recommendations-heart-full', 'poly_bookmark', 20, 20, '0 0 20 20');

IconRecommendationsFull.ICON_ID = 'recommendations-full_icon';
IconRecommendationsMeliCoins.ICON_ID = 'recommendations-meli_coins';
IconRecommendationsMeliLogo.ICON_ID = 'recommendations-meli_logo';
IconRecommendationsChevron.ICON_ID = 'recommendations-chevron';
IconRecommendationsBlackFriday.ICON_ID = 'recommendations-black-friday_icon';
IconRecommendationsMShopsLogo.ICON_ID = 'recommendations-mshops_logo';

export {
  IconRecommendationsFull,
  IconRecommendationsFullSuperMarket,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
};
