import React, { useMemo } from 'react';
import classnames from 'classnames';
import { arrayOf, shape, string } from 'prop-types';
import { PACKAGE_NAMESPACE } from './utils/constants';
import { snakeCaseToCamelCase } from './utils/utilities';
import SOURCES from './utils/sources';
import replaceObjectProps from './utils/replaceObjectProps';
import componentFactoryFrom from './factory';

const PillsBuilder = ({ pills, cssClass, sourceIcons }={}) => {
  const Component = useMemo(() => componentFactoryFrom(SOURCES), []);

  const formattedPills = pills.map(pill => {
    const overrides = {};

    if (pill?.styles) {
      overrides.styles = replaceObjectProps(pill.styles, '_', snakeCaseToCamelCase);
    }

    if (pill?.values.length) {
      overrides.values = [];

      pill.values.forEach(value => {
         const formattedValue = { ...value };

        if (formattedValue?.styles) {
          formattedValue.styles = replaceObjectProps(value.styles, '_', snakeCaseToCamelCase);
        }

        overrides.values.push(formattedValue);
      });
    }

    return {
      ...pill,
      ...overrides,
    };
  });


  if (formattedPills?.length < 1) return null;

  return (
    <div className={classnames(PACKAGE_NAMESPACE, cssClass)}>
      {formattedPills.map((pill, index) => (
        <div className={classnames(`${PACKAGE_NAMESPACE}-container`)} key={`${PACKAGE_NAMESPACE}-item${index}`}>
          <Component {...pill} sourceIcons={sourceIcons} />
        </div>
      ))}
    </div>
  );
};


PillsBuilder.propTypes = {
  pills: arrayOf(shape({
    class_name: string,
    id: string,
    styles: shape({}),
    text: string,
    type: string,
    values: arrayOf(shape({})),
  })),
  cssClass: string,
  sourceIcons: shape({}),
};


export default PillsBuilder;
