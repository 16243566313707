import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--free-shipping';
const ICON_ID = 'good_financing';

const IconGoodFinancing = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames(namespace, className)}
    aria-hidden="true"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconGoodFinancing.propTypes = {
  className: string,
};

export default React.memo(IconGoodFinancing);
