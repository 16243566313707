/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { arrayOf, bool, func, shape, string, number } from 'prop-types';

import debounce from 'lodash/debounce';
import cx from 'classnames';

import FullMap from '../full-map';
import CustomControls from '../lib/controls/custom-controls';
import MapTypeControl from '../lib/controls/map-type-control';
import ZoomControl from '../lib/controls/zoom-control';
import ZoomInIcon from '../../icons/map/zoom-in';
import ZoomOutIcon from '../../icons/map/zoom-out';
import { useMapState } from '../hooks/use-map-state';
import useMapConfig from '../hooks/use-map-config';
import Infowindow from './infowindow';

const namespace = 'ui-search-map-container';
const HOVER_DEBOUNCE_MS = 50;

const goToItemPage = (item) => {
  const newWindow = window.open(item.permalink, '_blank');

  newWindow.focus();
};

const isSingleMarker = (results) => results && results.length === 1;

const Map = ({
  className,
  fitMarkersInMap,
  isLoadingResults,
  markers,
  updateResults,
  fixedCoords,
  selectedCluster,
  selectedMarker,
  setSelectedMarker,
}) => {
  const { handleDragEnd, handleMapLoad, handleZoomChanged, handleBoundsChanged, handleIdle, mapStatus } = useMapState({
    fitMarkersInMap,
    isLoadingResults,
    markers,
    updateResults,
    fixedCoords,
    selectedMarker,
    setSelectedMarker,
  });
  const [infowindow, setInfowindow] = useState(null);
  const {
    controls: { mapType = {}, zoom = {} },
  } = useMapConfig();

  const handleSingleMarkerClick = (index) => {
    setSelectedMarker(null, null);
    setInfowindow(null);
    goToItemPage(markers[index]);
  };

  const handleMarkerClick = (index, results) => {
    const isSingle = isSingleMarker(results);
    const handleClick = isSingle ? handleSingleMarkerClick : setSelectedMarker;

    handleClick(index, results);
  };

  const handleHoverChange = useCallback(
    debounce((index, results) => {
      setSelectedMarker(index);
      setInfowindow(isSingleMarker(results) ? results[0] : null);
    }, HOVER_DEBOUNCE_MS),
    [setInfowindow, setSelectedMarker],
  );

  return (
    <FullMap
      className={cx(className, namespace)}
      markers={markers}
      selectedCluster={selectedCluster}
      selectedMarker={selectedMarker}
      handleBoundsChanged={handleBoundsChanged}
      handleMapLoad={handleMapLoad}
      handleDragEnd={handleDragEnd}
      handleZoomChanged={handleZoomChanged}
      handleIdle={handleIdle}
      handleMapClick={() => setSelectedMarker(null, null)}
      handleMarkerClick={handleMarkerClick}
      handleMarkerHover={handleHoverChange}
      mapStatus={mapStatus}
    >
      <CustomControls>
        <MapTypeControl labels={mapType} />
        <ZoomControl
          zoomInIcon={<ZoomInIcon />}
          zoomInLabel={zoom.zoom_in}
          zoomOutIcon={<ZoomOutIcon />}
          zoomOutLabel={zoom.zoom_out}
        />
      </CustomControls>

      {infowindow && (
        <Infowindow
          content={infowindow}
          index={selectedMarker}
          onClick={handleSingleMarkerClick}
          onHover={handleHoverChange}
        />
      )}
    </FullMap>
  );
};

Map.propTypes = {
  className: string,
  fitMarkersInMap: bool,
  fixedCoords: arrayOf(shape({ lat: number, lng: number })),
  isLoadingResults: bool,
  markers: arrayOf(shape()),
  selectedCluster: number,
  selectedMarker: number,
  setSelectedMarker: func.isRequired,
  updateResults: func.isRequired,
};

Map.defaultProps = {
  className: null,
  fitMarkersInMap: false,
  fixedCoords: undefined,
  isLoadingResults: false,
  markers: [],
  selectedMarker: null,
  selectedCluster: null,
};

export default Map;
