import usePagination from '../hooks/use-pagination';

const WrapperPagination = (Content) => {
  const pagination = usePagination();
  const hasPagination = pagination?.showPagination;

  return hasPagination ? <Content {...pagination} /> : null;
};

export default WrapperPagination;
