import { useSearch } from '../../hooks/context';
import { useCurrentLayout } from '../context/layout';

const useListingDisclaimer = () => {
  const { listing_disclaimer } = useSearch();
  const currentLayout = useCurrentLayout();

  return listing_disclaimer
    ? {
        ...listing_disclaimer,
        currentLayout,
      }
    : listing_disclaimer;
};

export default useListingDisclaimer;
