import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--cockade';
const ICON_ID = 'poly_cockade';

const IconCockade = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconCockade.propTypes = {
  className: string,
};

IconCockade.defaultProps = {
  className: null,
};

export default React.memo(IconCockade);
