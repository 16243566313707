import { useLayout } from './context';
import { DISPLAY_TYPES, FILTERS } from '../constants';
import { useHasFiltersState } from '../components/context/filters/helpers';

const { MAP } = DISPLAY_TYPES;
const {
  IDS: { DISPLAY_TYPE },
} = FILTERS;

const useClientSideOptions = () => {
  const { isMapLayout = false } = useLayout();

  const hasFiltersState = useHasFiltersState();
  const isClientSideSearch = isMapLayout && hasFiltersState;

  const clientSideFilters = isClientSideSearch ? { [DISPLAY_TYPE]: MAP } : {};

  return { isClientSideSearch, clientSideFilters };
};

export default useClientSideOptions;
