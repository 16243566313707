import React from 'react';
import { node, string, func, bool, shape } from 'prop-types';

import classnames from 'classnames';
import { Modal as AndesModal } from '@andes/modal';

import Link from '../../components-v2/link';
import { useSearch } from '../../hooks/context';

const namespace = 'ui-search-modal';

const Modal = ({
  children,
  label,
  actions = null,
  className = '',
  closeText = '',
  componentAsLabel = null,
  headerSuffix = '',
  icon = null,
  modalClassName = '',
  modalTitle = '',
  modalUrl = '',
  url = '',
  isOpen = false,
  openModal = null,
  hideModal = null,
  type = 'full',
  withLink = false,
  focusTab = false,
  ...rest
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <>
      {withLink && (
        <Link
          className={classnames(`${namespace}__link`, className)}
          href={focusTab && !url ? ' ' : url}
          onClick={openModal}
          title={label}
        >
          {icon && <div className={classnames(className, { [`${namespace}__icon`]: !componentAsLabel })}>{icon}</div>}
          {label || componentAsLabel}
        </Link>
      )}
      <AndesModal
        actions={actions}
        headerSuffix={headerSuffix}
        closeButtonSrLabel="close"
        url={modalUrl}
        type={type}
        className={classnames(namespace, modalClassName, className, { shops__modal: isShops })}
        open={isOpen}
        onClose={hideModal}
        title={modalTitle}
        closeText={closeText}
        showCloseButton
        {...rest}
      >
        {children}
      </AndesModal>
    </>
  );
};

Modal.propTypes = {
  actions: shape({
    buttonFilled: node,
  }),
  children: node.isRequired,
  className: string,
  closeText: string,
  componentAsLabel: node,
  focusTab: bool,
  headerSuffix: string,
  hideModal: func,
  icon: node,
  isOpen: bool,
  label: string.isRequired,
  modalClassName: string,
  modalTitle: string,
  modalUrl: string,
  openModal: func,
  type: string,
  url: string,
  withLink: bool,
};

export default Modal;
