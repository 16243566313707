import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-item__brand-discoverability';

const BrandDiscoverability = ({ className = null, brand }) => (
  <span className={classnames(namespace, className)}>{brand}</span>
);

BrandDiscoverability.propTypes = {
  brand: string.isRequired,
  className: string,
};

export default BrandDiscoverability;
