import React from 'react';
import classnames from 'classnames';
import { Tag } from '@andes/tag';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';
import Link from '../../../link';
import { namespace, textIconCarouselPropTypes } from './constants';

const TextIconCarousel = ({ tags, Icon }) => (
  <div className={classnames(namespace)}>
    <CarouselFree className={`${namespace}-carousel`}>
      {tags.map((tag) => (
        <CarouselFreeSlide key={tag.id} className={`${namespace}-item-slide`}>
          <Link className={`${namespace}__link`} href={tag.url} title={tag.label}>
            <Tag label={tag.label} asset={{ icon: <Icon /> }} />
          </Link>
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  </div>
);

TextIconCarousel.propTypes = {
  ...textIconCarouselPropTypes,
};

export default TextIconCarousel;
