import React from 'react';

import { loadable } from 'nordic/lazy';

import useCarousel from './use-carousel.mobile';

const ChunkCarousel = () => {
  const carousel = useCarousel();
  const CarouselSearch = loadable(async () => import(/* webpackChunkName: "chunk-carousel" */ './carousel.desktop'));

  return carousel ? <CarouselSearch items={carousel.values} type={carousel.type} filters={carousel.filters} /> : null;
};

export default ChunkCarousel;
