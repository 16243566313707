import React from 'react';

import useSearchAds from './use-search-ads';
import SearchShopsAds from './search-shops-ads';

const SearchShopsAdsContainer = () => {
  const bottomAds = useSearchAds();

  if (!bottomAds) {
    return null;
  }

  return <SearchShopsAds {...bottomAds} />;
};

export default SearchShopsAdsContainer;
