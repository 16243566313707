import React from 'react';
import { shape, string, func } from 'prop-types';

import Button from '@andes/button';

/* Default button for when the item is not in the cart */
const NoItemsButton = ({ action, className, handleAddToCartPut }) => {
  const { label } = action;

  return (
    <Button
      className={className}
      srLabel={action?.accessibility_text}
      fullWidth
      hierarchy="quiet"
      onClick={(e) => handleAddToCartPut(e)}
      size="medium"
    >
      {label?.text}
    </Button>
  );
};

/* PropTypes */
NoItemsButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
  }).isRequired,
  className: string,
  handleAddToCartPut: func.isRequired,
};

export default NoItemsButton;
