import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'supermarket_logo_full';
const namespace = 'ui-search-icon ui-search-icon--supermarket-fs';

const IconSupermarketFS = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="9"
    viewBox="0 0 100 9"
    fill="none"
  >
    <use href="#supermarket" />
  </svg>
);

IconSupermarketFS.propTypes = {
  className: string,
};

IconSupermarketFS.defaultProps = {
  className: null,
};

const IconApplMemo = React.memo(IconSupermarketFS);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
