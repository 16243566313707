import React from 'react';
import { node, oneOfType, shape, string } from 'prop-types';

import { formatStyles } from '../utils/formatStyles';

const Label = ({ text, styles, className }) => (
  <span style={formatStyles(styles)} className={className}>
    {text}
  </span>
);

Label.propTypes = {
  className: string,
  styles: shape({}),
  text: oneOfType([string, node]),
};

export default Label;
