import React from 'react';
import { objectOf, shape, string, any, number, func } from 'prop-types';

import classnames from 'classnames';
import InputStepper from '@andes/input-stepper';

import NoItemsButton from './no-items-button';
import OtherOptionsButton from './other-options-button';
import { runLabelInterpolator } from '../../../../utils/label-interpolator';
import { GRID } from '../../../layout/renderer/constants';

const namespace = 'ui-search-card-add-to-cart';
const ADD_TO_CART = 'ADD_TO_CART';
const OTHER_OPTIONS = 'OTHER_OPTIONS';

/* This components decides if we go for add to cart button or options. */
const RenderButton = ({
  itemId,
  type,
  action,
  maxValue,
  itemCount,
  label,
  labelCount,
  handleAddToCartPut,
  handleAddToCartDelete,
  sendButtonTracks,
  labelMinQuantity,
  permalink,
  viewMode,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, _, complementaryText] = runLabelInterpolator(labelCount);

  const showInputStepper = itemCount > 0;

  const decrementButtonProps = React.useMemo(() => ({ onClick: handleAddToCartDelete }), [handleAddToCartDelete]);
  const incrementButtonProps = React.useMemo(() => ({ onClick: handleAddToCartPut }), [handleAddToCartPut]);
  const formatValue = React.useCallback(() => value, [value]);

  switch (type) {
    case ADD_TO_CART:
      return (
        <>
          {showInputStepper ? (
            <InputStepper
              complementaryText={viewMode === 'intervention' ? null : complementaryText}
              decrementButtonProps={decrementButtonProps}
              id={itemId}
              incrementButtonProps={incrementButtonProps}
              maxValue={maxValue}
              size="small"
              value={itemCount}
              formatValue={formatValue}
              srLabel={label}
            />
          ) : (
            <NoItemsButton
              action={action}
              className={`${namespace}__no-items-button`}
              handleAddToCartPut={handleAddToCartPut}
            />
          )}
          {labelMinQuantity && (
            <span
              className={classnames(`${namespace}__minimum-label`, {
                [`${namespace}__minimum-label--grid`]: viewMode === GRID,
              })}
            >
              {labelMinQuantity.text}
            </span>
          )}
        </>
      );
    case OTHER_OPTIONS:
      return <OtherOptionsButton action={action} sendButtonTracks={sendButtonTracks} permalink={permalink} />;

    default:
      return null;
  }
};

RenderButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
    tracks: objectOf(any),
  }).isRequired,
  handleAddToCartDelete: func,
  handleAddToCartPut: func,
  itemCount: number.isRequired,
  itemId: string,
  label: string.isRequired,
  labelCount: objectOf(any),
  labelMinQuantity: string,
  maxValue: number.isRequired,
  permalink: string,
  sendButtonTracks: func,
  type: string.isRequired,
  viewMode: string,
};

RenderButton.defaultProps = {
  handleAddToCartDelete: () => {},
  handleAddToCartPut: () => {},
  labelCount: {},
  sendButtonTracks: () => {},
  viewMode: null,
};

const AddToCart = (props) => (
  <div className={namespace}>
    <RenderButton {...props} />
  </div>
);

export default AddToCart;
