import { arrayOf, shape, func, string } from 'prop-types';

const textIconCarouselPropTypes = {
  Icon: func.isRequired,
  tags: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
};

export { textIconCarouselPropTypes };
