import React from 'react';
import { string, node, shape } from 'prop-types';

import { Tooltip } from '@andes/tooltip';

import Link from '../../../components-v2/link';

const namespace = 'ui-search-alert-edition-tooltip';

const AlertEditionTooltip = ({ className, label, action, trigger }) => {
  const content = action ? (
    <div className={`${namespace}__content`}>
      <div className={`${namespace}__text`}>{label.text}</div>
      <Link
        className={`${namespace}__action-link`}
        href={action.target}
        tabIndex="-1"
        isInternal={false}
        title={action?.label?.text}
      >
        {action.label.text}
      </Link>
    </div>
  ) : (
    label.text
  );

  return (
    <Tooltip
      className={`${namespace} ${className}`}
      side="bottom"
      offsetX={2}
      offsetY={5}
      content={content}
      mask
      maskClosable={false}
      closable
    >
      {trigger}
    </Tooltip>
  );
};

AlertEditionTooltip.propTypes = {
  action: shape({
    label: shape({
      text: string,
    }),
    target: string,
  }),
  className: string,
  label: shape({
    text: string.isRequired,
  }).isRequired,
  trigger: node.isRequired,
};

AlertEditionTooltip.defaultProps = {
  action: null,
  className: null,
};

export default AlertEditionTooltip;
