import React from 'react';
import { func, node, oneOf, oneOfType, shape, string } from 'prop-types';
import classname from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';


const StyledLabel = ({
  accessibility_text,
  className = null,
  href = null,
  html_tag = 'span',
  id,
  onClick = null,
  styles,
  target,
  text = null,
}) => {
  const NAMESPACE = className
    ? `${PACKAGE_NAMESPACE}-label ${PACKAGE_NAMESPACE}-${className}`
    : `${PACKAGE_NAMESPACE}-label`;
  const Element = html_tag || 'span';
  const LINK_NAMESPACE = `${PACKAGE_NAMESPACE}__link`;

  return (
    <Element
      className={classname(NAMESPACE, id)}
      href={html_tag === 'a' && href ? href : null}
      onClick={onClick}
      style={{ ...styles }}
      aria-hidden={!!accessibility_text}
    >
      {html_tag !== 'a' && href ? (
        <a className={LINK_NAMESPACE} href={href} target={target}>
          {text && text.trim()}
        </a>
      ) : (
        text && text.trim()
      )}
    </Element>
  );
};


StyledLabel.propTypes = {
  accessibility_text: string,
  className: string,
  href: string,
  html_tag: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a']),
  id: string,
  onClick: func,
  styles: shape({}),
  target: string,
  text: oneOfType([string, node]),
};



export default StyledLabel;
