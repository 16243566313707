import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--hot-sale';

const ICON_ID = 'ic_hot_sale';

const Hotsale = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

Hotsale.propTypes = {
  className: string,
};

Hotsale.defaultProps = {
  className: null,
};

export default Hotsale;
