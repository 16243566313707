import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const ICON_ID = 'ic_gift';
const namespace = 'ui-search-icon ui-search-icon--gift';

const IconGift = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="gift.svg" alt="" />
  </div>
);

IconGift.propTypes = {
  className: string,
};

IconGift.defaultProps = {
  className: null,
};

const IconApplMemo = React.memo(IconGift);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
