import React, { useState, useEffect } from 'react';
import { oneOf, string, shape, bool, number } from 'prop-types';

import classnames from 'classnames';
import { escape } from 'html-escaper';
import get from 'lodash/get';
import { Modal } from '@andes/modal';
import { TextField } from '@andes/textfield';
import { Switch } from '@andes/switch';
import { Dropdown, DropdownItem } from '@andes/dropdown';
import { Button, ButtonText } from '@andes/button';
import { Snackbar } from '@andes/snackbar';

import SearchBookmarkCreatedIcon from '../icons/search-bookmark-created';
import { useUser } from '../context/user';
import useTimesViewed from '../../hooks/use-times-viewed';
import { trackEvent } from './trackings/track';
import APIService from '../../../services/alert';
import SaveSearchButton from './components/save-search-button';

const SEARCH_TYPE_NEW = 'new_search_configuration';
const SEARCH_TYPE_CREATED = 'created_search_configuration';
const SEARCH_TYPE_MODAL = 'modal_save_configuration';

const namespace = 'ui-search-alert';

const OFFSET_Y = 1;
const OFFSET_X = -95;

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const renderSearchCreated = (props) => {
  const snackbarSuccess = get(props, 'searchModalProps.snackbar_success', null);
  const snackbarText = get(props, 'action.label.text', null);
  const snackbarTarget = get(props, 'action.label.target', null);

  const trackAdminRedirect = get(props, 'action.tracks', null);

  const isSnackbarSuccess = get(props, 'isSnackbarSuccess');

  const handleRedirect = () => {
    if (trackAdminRedirect) {
      const { melidata_track } = trackAdminRedirect;

      trackEvent(null, melidata_track);
    }

    redirect(snackbarTarget);
  };

  return (
    <>
      {isSnackbarSuccess && (
        <Snackbar
          message={snackbarSuccess.label.text}
          color="green"
          show={isSnackbarSuccess}
          delay={6000}
          action={{
            text: snackbarSuccess.action.label.text,
            onClick: () => handleRedirect(snackbarSuccess.action.target),
          }}
        />
      )}
      <button type="button" className={`${namespace}__btn-save-new-search`} onClick={handleRedirect}>
        <SearchBookmarkCreatedIcon className={`${namespace}__icon-search`} />
        <p>{snackbarText}</p>
      </button>
    </>
  );
};

const renderSaveNewSearchGuest = (props) => {
  const { loginUrl, infoTooltipTimesViewed, isTooltipOpen, setOpenTooltip } = props;

  const { isAvailable, handleClose } = infoTooltipTimesViewed;

  const handleRedirect = () => {
    if (isAvailable && isTooltipOpen) {
      setOpenTooltip(false);
      handleClose();
    }

    redirect(loginUrl);

    return false;
  };

  return (
    <div>
      <SaveSearchButton
        className={classnames(`${namespace}__save-search-tooltip-info`)}
        onClick={handleRedirect}
        offsetY={OFFSET_Y}
        offsetX={OFFSET_X}
        {...props}
      />
    </div>
  );
};

const renderSaveNewSearch = (props) => {
  const {
    classNames,
    loginUrl,
    saveNewSearchProps,
    infoTooltipTimesViewed,
    isTooltipOpen,
    setOpenTooltip,
    isModalOpen,
    setOpenModal,
    searchModalProps,
    searchTitle,
    setSearchTitle,
    isNotificationsEnabled,
    setEnableNotifications,
    frequencyValue,
    setFrequencyValue,
    expirationValue,
    setExpirationValue,
    isSubmitLoading,
    setSubmitLoading,
    isDisabled,
    setComponentVisible,
    searchCreatedProps,
    setSnackbarSuccess,
    isSnackbarError,
    setSnackbarError,
  } = props;

  const trackSaveIntention = get(saveNewSearchProps, 'title_configuration.tracks', null);

  const title = get(searchModalProps, 'title_configuration.title', null);
  const subtitle = get(searchModalProps, 'title_configuration.subtitle', null);
  const inputLabel = get(searchModalProps, 'title_configuration.content.label', null);
  const title_max_length = get(searchModalProps, 'title_configuration.content.title_max_length', null);
  const title_placeholder = get(searchModalProps, 'title_configuration.content.title_placeholder', null);
  const title_length_placeholder = get(searchModalProps, 'title_configuration.content.title_length_placeholder', null);

  const notificationsTitle = get(searchModalProps, 'notifications_configuration.content.title', null);
  const enableNotificationsText = get(searchModalProps, 'notifications_configuration.content.action.text', null);
  const frequencyLabel = get(searchModalProps, 'notifications_configuration.content.frequency.label', null);
  const frequencyPlacehodler = get(searchModalProps, 'notifications_configuration.content.frequency.placeholder', null);
  const frequencyOptions = get(searchModalProps, 'notifications_configuration.content.frequency.options', null);
  const expirationLabel = get(searchModalProps, 'notifications_configuration.content.expiration.label', null);
  const expirationPlacehodler = get(
    searchModalProps,
    'notifications_configuration.content.expiration.placeholder',
    null,
  );
  const expirationOptions = get(searchModalProps, 'notifications_configuration.content.expiration.options', null);

  const submitButtonText = get(searchModalProps, 'action_configuration.label.text', null);
  const trackSaveAction = get(searchModalProps, 'action_configuration.tracks', null);
  const alertCreationData = get(searchModalProps, 'action_configuration.alert_creation_data', null);

  const trackCancelIntention = get(searchModalProps, 'tracks', null);
  const snackbarError = get(searchModalProps, 'snackbar_error', null);
  const trackSaveCongrats = get(searchModalProps, 'snackbar_success.tracks', null);

  const { isAvailable, handleClose } = infoTooltipTimesViewed;

  const handleOpenModal = () => {
    if (isAvailable && isTooltipOpen) {
      setOpenTooltip(false);
      handleClose();
    }

    setOpenModal(true);

    if (trackSaveIntention) {
      const { melidata_track } = trackSaveIntention;

      trackEvent(null, melidata_track);
    }
  };

  const handleSearchTitle = (e) => setSearchTitle(e.target.value);

  const handleEnableNotifications = (e) => {
    const isChecked = e.target.checked;

    setEnableNotifications(isChecked);

    if (!isChecked) {
      setFrequencyValue('');
      setExpirationValue(0);
    }
  };

  const handleFrequency = (e) => setFrequencyValue(e.target.value);

  const handleExpiration = (e) => setExpirationValue(e.target.value);

  const handleSuccess = () => {
    setComponentVisible(searchCreatedProps.type);
    setSubmitLoading(false);
    setSnackbarSuccess(true);

    if (trackSaveCongrats) {
      const { melidata_track } = trackSaveCongrats;

      trackEvent(null, melidata_track);
    }
  };

  const handleError = () => {
    setSubmitLoading(false);
    setSnackbarError(true);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    escape(searchTitle);

    setSubmitLoading(true);

    if (trackSaveAction) {
      const melidata_track = {
        ...trackSaveAction.melidata_track,
        event_data: {
          ...trackSaveAction.melidata_track.event_data,
          title: searchTitle,
          frequency: isNotificationsEnabled ? frequencyValue : null,
          expiration: isNotificationsEnabled ? parseInt(expirationValue, 10) : null,
        },
      };

      trackEvent(null, melidata_track);
    }

    const alertCreationPayload = {
      ...alertCreationData,
      title: searchTitle === '' ? alertCreationData?.title : searchTitle,
      enable_notifications: isNotificationsEnabled,
      frequency: isNotificationsEnabled ? frequencyValue : null,
      ttl: isNotificationsEnabled ? parseInt(expirationValue, 10) : null,
      status: isNotificationsEnabled ? 'active' : 'inactive',
    };

    APIService.addAlert(alertCreationPayload)
      .then(handleSuccess)
      .catch((ex) => {
        if (ex?.response && ex?.response.status === 403) {
          redirect(loginUrl);
        }

        handleError();
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchTitle('');
    setEnableNotifications(false);
    setFrequencyValue('');
    setExpirationValue(0);

    if (trackCancelIntention) {
      const { melidata_track } = trackCancelIntention;

      trackEvent(null, melidata_track);
    }
  };

  return (
    <div className={classNames}>
      <SaveSearchButton
        className={classnames(`${namespace}__save-search-tooltip-info`)}
        onClick={handleOpenModal}
        offsetY={OFFSET_Y}
        offsetX={OFFSET_X}
        {...props}
      />
      <Modal
        type="large"
        open={isModalOpen}
        onClose={handleCloseModal}
        title={
          <div>
            <h1>{title}</h1>
            <span className={`${namespace}__modal-subtitle`}>{subtitle}</span>
          </div>
        }
        className={`${namespace}__save-modal`}
      >
        <div className={`${namespace}__modal-content`}>
          <div className={`${namespace}__input-wrapper`}>
            <TextField
              label={<div className={`${namespace}__input-label`}>{inputLabel}</div>}
              helper={title_length_placeholder}
              placeholder={title_placeholder}
              maxLength={title_max_length}
              value={searchTitle}
              onChange={handleSearchTitle}
              countdown
            />
          </div>
          <hr />
          <p>{notificationsTitle}</p>
          <Switch
            label={enableNotificationsText}
            className={`${namespace}__notifications-switch`}
            labelPosition="right"
            checked={isNotificationsEnabled}
            onChange={handleEnableNotifications}
          />
          <div className={`${namespace}__frequency-container`}>
            <Dropdown
              className={`${namespace}__frequency-dropdown`}
              type="form"
              label={frequencyLabel}
              placeholder={frequencyPlacehodler}
              value={frequencyValue}
              onChange={handleFrequency}
              disabled={!isNotificationsEnabled}
            >
              {frequencyOptions.map(({ value, text: frequencyText }) => (
                <DropdownItem key={value} value={value} title={frequencyText} />
              ))}
            </Dropdown>
            <Dropdown
              className={`${namespace}__expiration-dropdown`}
              type="form"
              label={expirationLabel}
              placeholder={expirationPlacehodler}
              value={expirationValue}
              onChange={handleExpiration}
              disabled={!isNotificationsEnabled}
            >
              {expirationOptions.map(({ value, text: expirationText }) => (
                <DropdownItem key={value} value={value.toString()} title={expirationText} />
              ))}
            </Dropdown>
          </div>
          <div className={`${namespace}__submit-container`}>
            <Button disabled={isDisabled} loading={isSubmitLoading} onClick={handleSubmit}>
              <ButtonText>{submitButtonText}</ButtonText>
            </Button>
          </div>
        </div>
      </Modal>
      <Snackbar message={snackbarError.label.text} color="red" show={isSnackbarError} delay={6000} />
    </div>
  );
};

const SearchSave = (props) => {
  const [componentVisible, setComponentVisible] = useState(props.component_visible);
  const { loggedIn, loginUrl } = useUser();
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);
  const [frequencyValue, setFrequencyValue] = useState('');
  const [expirationValue, setExpirationValue] = useState(0);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSnackbarSuccess, setIsSnackbarSuccess] = useState(false);
  const [isSnackbarError, setIsSnackbarError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const saveNewSearchProps = props.components.find((item) => item.type === SEARCH_TYPE_NEW);
  const searchModalProps = props.components.find((item) => item.type === SEARCH_TYPE_MODAL);
  const searchCreatedProps = props.components.find((item) => item.type === SEARCH_TYPE_CREATED);

  const classNames = classnames({
    [`${namespace}__label-new`]: componentVisible === SEARCH_TYPE_NEW,
  });

  const infoTooltip = saveNewSearchProps?.title_configuration?.tooltip || null;

  const infoTooltipTimesViewed = useTimesViewed('infoTooltip', infoTooltip?.max_print);

  const isFrequencyAndExpirationEmpty = frequencyValue !== '' && expirationValue > 0;

  useEffect(() => {
    if (searchTitle.trim()) {
      if (isNotificationsEnabled) {
        setIsDisabled(!isFrequencyAndExpirationEmpty);
      } else {
        setFrequencyValue('');
        setExpirationValue(0);
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [searchTitle, isNotificationsEnabled, isFrequencyAndExpirationEmpty]);

  const newSearchProps = {
    loggedIn,
    loginUrl,
    saveNewSearchProps,
    infoTooltipTimesViewed,
    isTooltipOpen,
    setOpenTooltip: setIsTooltipOpen,
    classNames,
    isModalOpen,
    setOpenModal: setIsModalOpen,
    searchModalProps,
    searchTitle,
    setSearchTitle,
    isNotificationsEnabled,
    setEnableNotifications: setIsNotificationsEnabled,
    frequencyValue,
    setFrequencyValue,
    expirationValue,
    setExpirationValue,
    isSubmitLoading,
    setSubmitLoading: setIsSubmitLoading,
    infoTooltip,
    isDisabled,
    setComponentVisible,
    searchCreatedProps,
    setSnackbarSuccess: setIsSnackbarSuccess,
    isSnackbarError,
    setSnackbarError: setIsSnackbarError,
  };

  const searchCreatedParams = {
    ...searchCreatedProps,
    searchModalProps,
    isSnackbarSuccess,
    setSnackbarSuccess: setIsSnackbarSuccess,
  };

  if (!loggedIn) {
    return renderSaveNewSearchGuest(newSearchProps);
  }

  switch (componentVisible) {
    case SEARCH_TYPE_CREATED:
      return renderSearchCreated(searchCreatedParams);
    case SEARCH_TYPE_NEW:
      return renderSaveNewSearch(newSearchProps);

    default:
      return null;
  }
};

SearchSave.propTypes = {
  component_visible: string.isRequired,
  components: oneOf([
    shape({
      type: string.isRequired,
      title_configuration: shape({
        label: shape({
          text: string.isRequired,
          text_color: string,
          size: string,
        }),
        tooltip: shape({
          max_print: number.isRequired,
          text: string.isRequired,
          type: string.isRequired,
          location: string.isRequired,
        }),
        icon: shape({
          id: string,
          size: string,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
    }),
    shape({
      type: string.isRequired,
      title_configuration: shape({
        title: string.isRequired,
        subtitle: string.isRequired,
        content: shape({
          label: string.isRequired,
          title_placeholder: string.isRequired,
          title_length_placeholder: string.isRequired,
          title_max_length: number.isRequired,
        }),
      }),
      notifications_configuration: shape({
        content: shape({
          title: string.isRequired,
          action: shape({
            type: string.isRequired,
            text: string.isRequired,
          }),
          frequency: shape({
            label: string.isRequired,
            placeholder: string.isRequired,
            options: oneOf([
              shape({
                key: number.isRequired,
                value: string.isRequired,
                text: string.isRequired,
              }),
            ]),
          }),
          expiration: shape({
            label: string.isRequired,
            placeholder: string.isRequired,
            options: oneOf([
              shape({
                key: number.isRequired,
                value: string.isRequired,
                text: string.isRequired,
              }),
            ]),
          }),
        }),
      }),
      action_configuration: shape({
        type: string,
        size: string,
        hierarchy: string,
        label: shape({
          text: string.isRequired,
        }),
        target: string,
        alert_creation_data: shape({
          site_id: string.isRequired,
          user_id: number.isRequired,
          platform: string.isRequired,
          enable_notifications: bool.isRequired,
          frequency: string.isRequired,
          ttl: number.isRequired,
          search_params: shape({
            q: string.isRequired,
            category: string.isRequired,
          }),
          title: string.isRequired,
          subtitle: string.isRequired,
          email_body_subtitle: string.isRequired,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
      snackbar_error: shape({
        label: shape({
          text: string.isRequired,
        }),
      }),
      snackbar_success: shape({
        label: shape({
          text: string.isRequired,
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
        action: shape({
          label: shape({
            text: string.isRequired,
          }),
          text: string.isRequired,
        }),
      }),
      tracks: shape({
        melidata_track: shape({
          path: string.isRequired,
        }),
      }),
    }),
    shape({
      type: string.isRequired,
      background_color: string,
      corner_radius: string,
      action: shape({
        label: shape({
          text: string.isRequired,
          text_color: string,
          size: string,
          target: string.isRequired,
          icon: shape({
            id: string,
            size: string,
          }),
        }),
        tracks: shape({
          melidata_track: shape({
            path: string.isRequired,
          }),
        }),
      }),
    }),
  ]),
};

export default SearchSave;
