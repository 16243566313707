import React from 'react';
import { objectOf, shape, string, any, func } from 'prop-types';

import Button from '@andes/button';

/* Other Options Button */
const OtherOptionsButton = (props) => {
  const { action, sendButtonTracks, permalink } = props;
  const { label, tracks } = action;

  return (
    <Button
      fullWidth
      hierarchy="quiet"
      href={permalink}
      onClick={tracks && (() => sendButtonTracks(action))}
      size="medium"
    >
      {label?.text}
    </Button>
  );
};

/* PropTypes */
OtherOptionsButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    target: string,
    tracks: objectOf(any),
  }).isRequired,
  permalink: string,
  sendButtonTracks: func.isRequired,
};

export default OtherOptionsButton;
