import React from 'react';

const namespace = 'ui-search-icon ui-search-icon--play';

const ICON_ID = 'ic_play';

const IconPlay = () => (
  <svg className={namespace} width="13" height="16" viewBox="0 0 13 16" fill="none">
    <use href={`#${ICON_ID}`} />
  </svg>
);

export default IconPlay;
