import React from 'react';
import { string, shape, bool } from 'prop-types';

import { Banner as AdvertisingBanner } from 'advertising-banners-frontend';
import classnames from 'classnames';

import { useSearch } from '../../hooks/context';

const Advertising = ({ googleAd, segmentation, personalDataAdsDenied, slotId, className, viewportMargin, title }) => {
  const namespace = 'ui-search-sidebar-placements';
  const [adLoaded, setAdLoaded] = React.useState(false);
  const handleAdLoad = () => {
    setAdLoaded(true);
  };

  const { userConsentCookie } = useSearch();
  const validationClass = title && adLoaded;
  const contentClass = classnames({ [`${namespace} title-placements`]: validationClass });

  return (
    <div className={contentClass}>
      {title && adLoaded && <span className={`${namespace}__title`}>{title}</span>}
      <AdvertisingBanner
        userConsentCookie={userConsentCookie}
        slotId={slotId}
        googleAd={googleAd}
        viewportMargin={viewportMargin}
        personalDataAdsDenied={personalDataAdsDenied}
        segmentation={segmentation}
        onAdLoad={handleAdLoad}
        className={className}
      />
    </div>
  );
};

Advertising.propTypes = {
  className: string,
  googleAd: shape({
    unit: string,
    size: string,
    ppid: string,
    enabled: bool,
  }).isRequired,
  personalDataAdsDenied: string.isRequired,
  segmentation: shape({
    custID: string,
    Posiciones: string,
    platform: string,
  }).isRequired,
  slotId: string.isRequired,
  title: string,
  viewportMargin: string,
};

Advertising.defaultProps = {
  className: '',
  viewportMargin: null,
  title: null,
};

export default Advertising;
