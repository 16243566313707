import classnames from 'classnames';

import { NO_FOLLOW, NO_FOLLOW_SPONSORED } from '../constants';
import { trackEvent } from '../../../lib/tracking';

const relTagValue = (isAd, shouldAddNoFollow) => {
  if (isAd) {
    return NO_FOLLOW_SPONSORED;
  }

  if (shouldAddNoFollow) {
    return NO_FOLLOW;
  }

  return undefined;
};

const linkBuildProps = ({
  newHref,
  className,
  namespace,
  title,
  role,
  tabIndex,
  onClick,
  target,
  ariaLabel,
  ariaLabelledBy,
  tracks,
  shouldAddNoFollow,
  isAd,
}) => {
  const relTag = relTagValue(isAd, shouldAddNoFollow);

  const newProps = {
    href: newHref || '#',
    className: classnames(className, namespace),
    title,
    role,
    tabIndex,
    rel: relTag,
    onClick: tracks
      ? (params) => {
          trackEvent(tracks);
          window.location = newHref;

          if (onClick) {
            onClick(params);
          }
        }
      : onClick,
    target,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
  };

  return newProps;
};

export default linkBuildProps;
