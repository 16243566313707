const replaceObjectProps = (objectToReplace, coincidence, coincidenceCallback) => {
  const newObject = { ...objectToReplace };
  const keysObject = Object.keys(objectToReplace);

  if (!keysObject) {
    return objectToReplace;
  }

  keysObject.forEach((key) => {
    if (key.includes(coincidence)) {
      const replacedProp = coincidenceCallback(key);

      newObject[replacedProp] = newObject[key];
      delete newObject[key];
    }
  });

  return newObject;
};

export default replaceObjectProps;
