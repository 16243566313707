import React from 'react';

import { loadable } from 'nordic/lazy';

import useSnackbar from './use-snackbar';

const SnackBar = loadable(async () => import('./component'));

const SnackbarContainer = () => {
  const { currentSnackbar } = useSnackbar();

  return currentSnackbar ? <SnackBar {...currentSnackbar} /> : null;
};

export default SnackbarContainer;
