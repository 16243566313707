const React = require('react');
const { string } = require('prop-types');

const IconColor = ({ color }) => <div key={color} style={{ backgroundColor: color }} />;

IconColor.propTypes = {
  color: string,
};

module.exports = IconColor;
