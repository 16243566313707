import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import Link from '../../../components-v2/link';

const namespace = 'ui-search-item__pds-options';

const PdsOptions = ({ className, quantity_text, pds_permalink }) => (
  <div className={classnames(namespace, className)}>
    <Link href={pds_permalink} tabIndex="-1" title={quantity_text}>
      {quantity_text}
    </Link>
  </div>
);

PdsOptions.propTypes = {
  className: string,
  pds_permalink: string.isRequired,
  quantity_text: string.isRequired,
};

PdsOptions.defaultProps = {
  className: null,
};

export default PdsOptions;
