import React from 'react';
import { number, string } from 'prop-types';

import classnames from 'classnames';

import { LEVELS } from './constants';

const namespace = 'ui-search-reputation-scale';

const ReputationScale = ({ className, currentLevelID }) => (
  <ol className={classnames(namespace, className)}>
    {LEVELS.map((level) => (
      <li
        key={level.id}
        className={classnames(`${namespace}__level`, `${namespace}__level--${level.id}`, {
          [`${namespace}__level--active`]: currentLevelID === level.id,
        })}
        title={level.text}
      />
    ))}
  </ol>
);

ReputationScale.propTypes = {
  className: string,
  currentLevelID: number,
};

ReputationScale.defaultProps = {
  className: null,
  currentLevelID: null,
};

export default ReputationScale;
