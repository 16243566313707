import { useSearch } from '../../../hooks/context';
import { serialize } from '../utils/helpers';

const usePagination = () => {
  const { pagination } = useSearch();

  return serialize(pagination);
};

export default usePagination;
