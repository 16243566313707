import React from 'react';
import { element, shape, string } from 'prop-types';

import cx from 'classnames';

import { NAMESPACE, ZOOM_LEVELS } from './constants';

const { MAX, MIN } = ZOOM_LEVELS;

const useZoom = (mapInstance) => {
  const currentZoom = mapInstance.getZoom();

  return {
    isMaxZoom: currentZoom === MAX,
    isMinZoom: currentZoom === MIN,
    zoomIn: () => {
      const nextZoom = Math.min(currentZoom + 1, MAX);

      mapInstance.setZoom(nextZoom);
    },
    zoomOut: () => {
      const nextZoom = Math.max(currentZoom - 1, MIN);

      mapInstance.setZoom(nextZoom);
    },
  };
};

const ZoomControl = ({ map, zoomInIcon, zoomInLabel, zoomOutIcon, zoomOutLabel }) => {
  const { isMaxZoom, isMinZoom, zoomIn, zoomOut } = useZoom(map);

  return (
    <div className={cx(NAMESPACE, `${NAMESPACE}-button-container`, `${NAMESPACE}-zoom-container`)}>
      <button
        type="button"
        aria-label={zoomInLabel}
        onClick={zoomIn}
        disabled={isMaxZoom}
        className={cx(NAMESPACE, `${NAMESPACE}-button`, `${NAMESPACE}-zoom-button`)}
      >
        {zoomInIcon}
      </button>
      <hr className={cx(NAMESPACE, `${NAMESPACE}-button-separator`)} />
      <button
        type="button"
        aria-label={zoomOutLabel}
        onClick={zoomOut}
        disabled={isMinZoom}
        className={cx(NAMESPACE, `${NAMESPACE}-button`, `${NAMESPACE}-zoom-button`)}
      >
        {zoomOutIcon}
      </button>
    </div>
  );
};

ZoomControl.propTypes = {
  map: shape({}).isRequired,
  zoomInIcon: element.isRequired,
  zoomInLabel: string,
  zoomOutIcon: element.isRequired,
  zoomOutLabel: string,
};

ZoomControl.defaultProps = {
  zoomInLabel: '',
  zoomOutLabel: '',
};

export default ZoomControl;
