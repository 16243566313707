import { REGEX_LIB } from './constants';

const {
  GET_SNAKE_CASE,
  GET_VARIABLES,
  INTERPOLATION,
} = REGEX_LIB;

const hasOwnProperty = (object, key) => `{${object.key}}` === key;

const splitVariablesAndText = (text) => text.split(INTERPOLATION).filter((res) => !!res.trim());

const getVariablesFromText = text => text?.match(GET_VARIABLES)?.map((str) => str.trim());

const getVariablesObject = (vars, content) => vars?.map(
  (variable) => content.find((value) => hasOwnProperty(value, variable)) || variable,
);

const snakeCaseToCamelCase = snakeCase => snakeCase.replace(GET_SNAKE_CASE, (_, letter) => letter.toUpperCase());


export {
  hasOwnProperty,
  getVariablesFromText,
  getVariablesObject,
  snakeCaseToCamelCase,
  splitVariablesAndText,
};
