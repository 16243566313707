import React from 'react';
import { string } from 'prop-types';

const ICON_ID = 'ic_search_bookmark';

const SearchBookmarkIcon = ({ className }) => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <use href={`#${ICON_ID}`} />
  </svg>
);

SearchBookmarkIcon.propTypes = {
  className: string,
};

SearchBookmarkIcon.defaultProps = {
  className: null,
};

export default SearchBookmarkIcon;
