import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--elipse-check';

const ICON_ID = 'ic_elipse_check';

const IconElipseCheck = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconElipseCheck.propTypes = {
  className: string,
};

IconElipseCheck.defaultProps = {
  className: null,
};

export default React.memo(IconElipseCheck);
