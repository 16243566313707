import React from 'react';

import types from './component-types';

const componentDependsOnFactory = (component) => component.type === types.GROUPED;

function componentFactoryFrom(sources) {
  const ComponentFactory = (component) => {
    const source =
      sources['component_visible' in component && component.type === 'ALERT' ? `${component.type}_V2` : component.type];

    if (!source) {
      return null;
    }

    const props = componentDependsOnFactory(component)
      ? { ...component, factory: componentFactoryFrom(sources) }
      : component;

    const componentName = component.id ? `${component.id} - ${component.type}` : component.type;

    source.displayName = `Component(${componentName})`;

    return React.createElement(source, props);
  };

  return ComponentFactory;
}

export default componentFactoryFrom;
