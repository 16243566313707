import React, { useState, useRef, useEffect } from 'react';
import { bool } from 'prop-types';

import classnames from 'classnames';
import Snackbar from '@andes/snackbar';

import FullSnackbar from './full-shipping-info';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { markWithCookie, isCookieMarked } from '../../lib/cookies';

const COOKIE_NAME = 'searchFullCPG';
const namespace = 'ui-search-fullpopup';

const FullTopEmpty = ({ disabledByCookie }) => {
  let initialState = !isCookieMarked(COOKIE_NAME);

  const refCruce = useRef();

  const [isOnScreen] = useIntersectionObserver(refCruce, { threshold: 0.2 }, 0.1, true);

  if (disabledByCookie) {
    initialState = false;
  }

  const [visible, setVisible] = useState(initialState);

  useEffect(() => {
    if (visible) {
      const header = document.querySelector('.nav-header');

      if (header) {
        header.style.zIndex = 10;
      }
    }
  }, [visible]);

  const disableBottom = () => {
    setVisible(false);
    document.querySelector('.nav-header').style = null;
    markWithCookie(COOKIE_NAME, 86400);
  };

  return (
    <div ref={refCruce}>
      <div
        className={classnames(
          {
            'ui-search-fullpopup-up-float': isOnScreen,
            'ui-search-fullpopup-up-sticky': !isOnScreen,
          },
          { 'ui-search-fullpopup-up-float-closeable': isOnScreen },
        )}
      >
        <Snackbar
          show={visible}
          className={classnames(namespace, `${namespace}-up`, {
            [`${namespace}-up--animate-show`]: visible,
            [`${namespace}-up--animate-hide`]: !visible,
          })}
        >
          <FullSnackbar closeAction={() => disableBottom()} />
        </Snackbar>
      </div>
    </div>
  );
};

FullTopEmpty.propTypes = {
  disabledByCookie: bool,
};

FullTopEmpty.defaultProps = {
  disabledByCookie: false,
};

export default FullTopEmpty;
export { COOKIE_NAME };
