import React from 'react';

import { VERTICAL_TYPE_RES } from '../../layout/renderer/constants';
import { ResContent } from '../../layout/renderer/map/vertical-content/res-content';
import { ResContent as ResContentV2 } from './res-content';
import { DEVICE_TYPES } from '../../../constants';

const { MOBILE } = DEVICE_TYPES;

// eslint-disable-next-line @typescript-eslint/default-param-last
const verticalContentRenderer = (item, deviceType, version = 1, permalink, target, classNameLink, titleA11Y) => {
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_RES: {
      if (version === 2 && deviceType === MOBILE) {
        return <ResContentV2 item={item} />;
      }

      return (
        <ResContent
          item={item}
          device={deviceType}
          permalink={permalink}
          target={target}
          classNameLink={classNameLink}
          titleA11Y={titleA11Y}
        />
      );
    }

    default:
      return null;
  }
};

export { verticalContentRenderer };
