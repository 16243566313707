import React from 'react';
import { string, shape, arrayOf, func } from 'prop-types';

import { Button } from '@andes/button';

import { setCookie } from '../../../lib/cookies';

const namespace = 'ui-search-rescue';
const ActionsRescue = ({ actions: initialActions, actionButton }) => {
  const actions = initialActions || [];

  const Actions = actions.map((action) => {
    switch (action.type) {
      case 'button':
        return (
          <Button
            key={action.label.text}
            className={`${namespace}__button`}
            href={action.target}
            hierarchy="loud"
            onClick={() => setCookie('pr_categ', 'userhide', { 'max-age': 5184000 })}
          >
            {action.label.text}
          </Button>
        );

      case 'button_self':
        return (
          <Button
            key={action.label.text}
            className={`${namespace}__button`}
            href={action.target}
            hierarchy="loud"
            onClick={() => {
              setCookie('pr_categ', 'userhide', { 'max-age': 5184000 });

              if (typeof actionButton === 'function') {
                actionButton(action?.onClick, action);
              }
            }}
          >
            {action.label.text}
          </Button>
        );

      case 'link':
        return (
          <a
            key={action.label.text}
            className={`${namespace}__link--decline`}
            href={action.target}
            target="_blank"
            rel="noreferrer"
          >
            {action.label.text}
          </a>
        );

      default:
        return null;
    }
  });

  return <> {Actions} </>;
};

ActionsRescue.propTypes = {
  actionButton: func,
  actions: arrayOf(
    shape({
      type: string,
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ).isRequired,
};

export default ActionsRescue;
