import React from 'react';
import { number, string, shape } from 'prop-types';

import cx from 'classnames';
import MoneyAmount from '@andes/money-amount';

import IconMelicoin from '../../icons/icon-melicoin';
import { getSplitText } from '../../../utils/split-test';

const namespace = 'ui-search-melicoin';

const splitContent = (width, label) => {
  const characterSize = 6.3;

  return getSplitText(label, characterSize, width);
};

const findClassToFill = (start, end) => {
  let classToFill = '';

  if (start !== '' && end !== '') {
    classToFill = `${namespace}__spaceMiddle`;
  } else if (start !== '') {
    classToFill = `${namespace}__spaceStart`;
  } else if (end !== '') {
    classToFill = `${namespace}__spaceFinal`;
  }

  return classToFill;
};

const getWordsForLabel = (textsForPills) => {
  let firstPillText = '';
  let secondPillText = '';

  textsForPills.forEach((text) => {
    if (text.includes('{')) {
      firstPillText = text;
    } else {
      secondPillText = secondPillText.concat(' ', text);
    }
  });

  return [firstPillText, secondPillText];
};
const fitPriceInText = (text, currency) => {
  const centsTypeStyles = currency.centsType === 'dot' ? '2px' : '';

  let startWording = '';
  let finalWording = '';
  let priceSetted = false;

  text.split(' ').forEach((word) => {
    if (word.includes('{')) {
      startWording = startWording === '' ? startWording : startWording.concat(' ');
      priceSetted = true;

      return;
    }

    if (priceSetted) {
      finalWording = finalWording.concat(' ', word);
    } else {
      startWording = startWording === '' ? startWording.concat('', word) : startWording.concat(' ', word);
    }
  });

  return (
    <>
      {startWording}
      {currency && priceSetted && (
        <MoneyAmount
          className={findClassToFill(startWording, finalWording)}
          value={{
            fraction: currency.fraction,
            cents: currency.cents,
          }}
          symbol={currency.symbol}
          currencyId={currency.currencyId}
          centsType={currency.centsType}
          weight="semibold"
          size={12}
        />
      )}
      <div style={{ marginTop: centsTypeStyles }}>{finalWording}</div>
    </>
  );
};

const MeliCoin = ({ label, currency, class_name, contentWidth }) => {
  const width = typeof contentWidth === 'undefined' ? 0 : contentWidth;
  const cleanLabel = label.replace(/ *\{[^)]*\} */g, ` {${currency.fraction}${currency.cents}} `);
  const textsForPills = splitContent(width, cleanLabel);
  const splitWords = getWordsForLabel(textsForPills);

  return cleanLabel.length > 18 && width === 0 ? (
    <>
      <div className={`${namespace} ${namespace}__split`}>
        <div className={`${namespace}__label`}>{fitPriceInText(splitWords[0], currency)}</div>
      </div>
      <div className={cx(namespace, class_name)}>
        <div className={`${namespace}__label`}>
          {fitPriceInText(splitWords[1], currency)}
          <IconMelicoin centsType={currency.centsType} />
        </div>
      </div>
    </>
  ) : (
    <div className={cx(namespace, class_name)}>
      <div className={`${namespace}__label`}>
        {fitPriceInText(label, currency)}
        <IconMelicoin centsType={currency.centsType} />
      </div>
    </div>
  );
};

MeliCoin.propTypes = {
  class_name: number.isRequired,
  contentWidth: number.isRequired,
  currency: shape().isRequired,
  label: string.isRequired,
};

MeliCoin.defaultProps = {
  className: null,
  currency: shape({
    centsType: '',
    cents: '',
    currencyId: '',
    fraction: '',
    symbol: '',
  }),
  label: '',
};

export default MeliCoin;
