import React from 'react';

import { loadable } from 'nordic/lazy';

import useCarousel from './use-carousel';

const ChunkCarouselAndes = () => {
  const carousel = useCarousel();
  const CarouselAndesSearch = loadable(async () =>
    import(/* webpackChunkName: "chunk-carousel" */ './components/wrapper'),
  );

  return carousel && <CarouselAndesSearch items={carousel.values} type={carousel.type} filters={carousel.filters} />;
};

export default ChunkCarouselAndes;
