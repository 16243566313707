import { arrayOf, shape, string, number, bool } from 'prop-types';

const objValuesPropTypes = {
  id: string.isRequired,
  name: string.isRequired,
  results: number,
  url: string.isRequired,
};

const linkConfig = {
  keepLayout: bool,
  isInternal: bool,
};

const valuesPropTypes = shape(objValuesPropTypes);
const colorValuesPropTypes = {
  ...objValuesPropTypes,
  option: string.isRequired,
  namespace: string.isRequired,
};
const valueWithLinkConfigPropTypes = {
  ...objValuesPropTypes,
  filterId: string,
  linkConfig: shape(linkConfig),
  namespace: string.isRequired,
  accessibilityText: string,
};
const valueLinkPropTypes = {
  ...objValuesPropTypes,
  namespace: string.isRequired,
  filterId: string.isRequired,
  accessibilityText: string,
  ids: shape({
    SUGGESTED_PRODUCTS: string.isRequired,
    FULFILLMENT: string.isRequired,
  }),
};
const filterPropTypes = {
  id: string.isRequired,
  name: string.isRequired,
  fragment: string,
  modal: shape({
    type: string.isRequired,
    labels: shape({
      modal_label: string.isRequired,
      modal_not_found_message: string.isRequired,
      modal_place_holder: string.isRequired,
    }).isRequired,
  }),
  type: string.isRequired,
  values: arrayOf(shape(valuesPropTypes)),
  expanded_values: arrayOf(valuesPropTypes),
};

const objLabelsPropTypes = {
  label: string.isRequired,
  type: string.isRequired,
};

export {
  filterPropTypes,
  valuesPropTypes,
  objValuesPropTypes,
  colorValuesPropTypes,
  objLabelsPropTypes,
  valueWithLinkConfigPropTypes,
  valueLinkPropTypes,
};
