import { createSetter, useSubscribedState } from 'nordic/page/store';

export const useSuggest = () => useSubscribedState().suggest;
export const useOpenSuggestions = () => useSuggest().openSuggestions;
export const useCurrentItemSuggestion = () => useSuggest().currentItemSuggestion;
export const useSuggestions = () => useSuggest().suggestions;
export const useCartFetching = () => useSuggest().cartFetching;

export const setOpenSuggestions = createSetter(({ suggest }, payload) => {
  suggest.openSuggestions = payload;
});
export const setCurrentItemSuggestion = createSetter(({ suggest }, payload) => {
  suggest.currentItemSuggestion = payload;
});
export const setSuggestions = createSetter(({ suggest }, payload) => {
  suggest.suggestions = payload;
});
export const setCartFetching = createSetter(({ suggest }, payload) => {
  suggest.cartFetching = payload;
});
