import React from 'react';
import { bool, string } from 'prop-types';

import classnames from 'classnames';

const NAMESPACE_RAW = 'ui-search-icon';
const NAMESPACE = `${NAMESPACE_RAW} ${NAMESPACE_RAW}--loyalty`;

const ICON_ID = 'ic_loyalty';

const IconLoyalty = ({ className, hidden }) => (
  <svg
    className={classnames(NAMESPACE, className, { [`${NAMESPACE_RAW}--hidden`]: hidden })}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconLoyalty.propTypes = {
  className: string,
  hidden: bool,
};

IconLoyalty.defaultProps = {
  className: null,
};

export default IconLoyalty;
