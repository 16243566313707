import React from 'react';

import { renderPriceGroup, renderVerticalHighlightGroup, ContentSkeleton } from '../common';
import {
  renderTitleGroup,
  renderHighlightInfo,
  renderShippingGroup,
  renderDetailsGroup,
  renderVariationsTextGroup,
  renderRebates,
  renderMeliCoinGroup,
  renderPillPromotions,
  renderPills,
  renderBottomPills,
  renderPdsInfoGroup,
} from '../core';

const renderContent = (item, options, layout) => (
  <ContentSkeleton
    topComponents={[
      renderHighlightInfo(item),
      renderTitleGroup(item, options, layout, true),
      renderPriceGroup(item, options),
      renderMeliCoinGroup(item, layout),
      renderPillPromotions(item, layout),
      renderPills(item, layout),
      renderRebates(item),
      renderShippingGroup(item, layout),
      renderDetailsGroup(item),
      renderVerticalHighlightGroup(item),
      renderVariationsTextGroup(item),
      renderPdsInfoGroup(item),
      renderBottomPills(item, layout),
    ]}
  />
);

export default renderContent;
