import React, { forwardRef } from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import cx from 'classnames';

import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-pagination';

const ContentPagination = forwardRef(({ className = null, children = null }, ref) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <section className={cx(namespace, className, { 'shops__pagination-content': isShops })} ref={ref}>
      <nav className={cx(namespace, 'andes-pagination', { shops__pagination: isShops })}>{children}</nav>
    </section>
  );
});

ContentPagination.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
};

ContentPagination.displayName = 'Content';

export default ContentPagination;
