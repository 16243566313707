const bookmarksRestclient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 2000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { BOOKMARKS_SERVICE_BASE_PATH } = require('../constants/paths');

class Service {
  static async toggleBookmarkService(itemId, state) {
    return Service[state ? 'addBookmark' : 'removeBookmark'](itemId);
  }

  static async addBookmark(itemId) {
    const response = await bookmarksRestclient.post(`${BOOKMARKS_SERVICE_BASE_PATH}`, {
      withCredentials: true,
      headers: {
        Encoding: 'UTF-8',
        'Content-Type': 'application/json',
      },
      data: {
        itemId,
      },
    });

    return response.data;
  }

  static async removeBookmark(itemId) {
    const response = await bookmarksRestclient.delete(`${BOOKMARKS_SERVICE_BASE_PATH}/${itemId}`, {
      withCredentials: true,
    });

    return response.data;
  }
}

module.exports = Service;
module.exports.restclient = bookmarksRestclient;
