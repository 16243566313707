const MLA = 'MLA';
const MLB = 'MLB';
const MLM = 'MLM';

/**
 * Generic message errors used only when we can't comunicate with the backend
 * we must review if we follow this path or if we use i18n capabilities instead
 */
const getMessagesErrorBySiteId = (siteId) => {
  switch (siteId) {
    case MLA:
      return {
        title: '¡Ups! hubo un error',
        description: 'Intentalo más tarde',
      };
    case MLB:
      return {
        title: 'Ops! Ocorreu um erro',
        description: 'Tente novamente mais tarde',
      };
    case MLM:
      return {
        title: '¡Ups! hubo un error',
        description: 'Inténtalo más tarde',
      };

    default:
      return {
        title: '¡Ups! hubo un error',
        description: 'Inténtalo más tarde',
      };
  }
};

export { getMessagesErrorBySiteId };
