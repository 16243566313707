import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import Image from 'nordic/image';

const namespace = 'ui-search-icon ui-search-icon--eshop-image';

const IconEshop = ({ className, image }) => (
  <Image className={classnames(namespace, className)} src={image} alt="icon-eshop" lazyload="off" />
);

IconEshop.propTypes = {
  className: string,
  image: string,
};

IconEshop.defaultProps = {
  className: null,
};

export default React.memo(IconEshop);
