import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--supermarket-logo';

const DEFAULT_COLOR = '#c70f5a';

const IconSupermarketLogo = ({ className, color }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 213 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color || DEFAULT_COLOR}
  >
    <use href="#supermarket-logo" />
  </svg>
);

IconSupermarketLogo.propTypes = {
  className: string,
  color: string,
};

IconSupermarketLogo.defaultProps = {
  className: '',
  color: '',
};

export default React.memo(IconSupermarketLogo);
