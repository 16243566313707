/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { arrayOf, shape } from 'prop-types';

import classnames from 'classnames';

import FilterOfficialStore from '../filter-official-store/filter-official-store.desktop';
import FilterHighlighted from '../highlighted-filter/highlighted-filter.desktop';
import RenderValueLink from './components/value-link';
import IconLabel from '../../../icon-label/icon-label';
import RenderFilterRange from './components/filter-range/filter-range';
import RenderColorsValues from './components/colors-values/colors-values';
import Modal from '../../../modal/base.mobile';
import useModal from '../../../../hooks/use-modal';
import { filterPropTypes, objValuesPropTypes } from './propTypes';
import { STYLE_MODIFIERS } from './constants';
import SearchModalContainer from '../search-modal/search-modal.desktop';
import MoneyPicker from '../../../money-picker/money-picker.desktop';
import { useStaticProps } from '../../../context/static-props';
import { FILTERS } from '../../../../constants';
import { useSearch } from '../../../../hooks/context';
import { setHideModal, hasFilters } from '../../../context/filters';

const namespace = 'ui-search-filter';
const { IDS, TYPES } = FILTERS;

const renderValues = ({ id, name, results, url, tooltip, tracks, accessibility_text, isShops }, filterId) => (
  <li key={id} className={classnames(`${namespace}-container`, { 'shops__container-lists': isShops })}>
    <RenderValueLink
      namespace={namespace}
      id={id}
      name={name}
      results={results}
      url={url}
      tooltip={tooltip}
      filterId={filterId}
      tracks={tracks}
      ids={IDS}
      modifier={STYLE_MODIFIERS.FULL_ICON}
      accessibilityText={accessibility_text}
    />
  </li>
);

renderValues.propTypes = {
  ...objValuesPropTypes,
};

const getFilterValuesContent = ({ id, name, type, values, expanded_values, currencies, isShops }) => {
  if (type === TYPES.HIGHLIGHTED) {
    return <FilterHighlighted id={id} values={values} expanded_values={expanded_values} />;
  }

  if (id === IDS.COLOR) {
    return (
      <li>
        <ul className={`${namespace}-row wrap`}>
          {expanded_values.map((value) => (
            <RenderColorsValues
              key={value.id}
              id={value.id}
              namespace={namespace}
              name={value.name}
              results={value.results}
              url={value.url}
            />
          ))}
        </ul>
      </li>
    );
  }

  if (id === IDS.OFFICIAL_STORE && type === TYPES.WITH_PICTURES) {
    return <FilterOfficialStore id={id} values={values} expanded_values={expanded_values} name={name} />;
  }

  if (id === IDS.PRICE && currencies) {
    return null;
  }

  return expanded_values.map((ev) => renderValues({ ...ev, isShops }, id));
};

const getFilterRangeContent = (id, values, url_templates, type, activeCurrencyId, labels, fragment) => {
  if (url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION)) {
    return id === IDS.VEHICLE_YEAR ? (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          type="dropdown"
          modifier={STYLE_MODIFIERS.YEAR_RANGE}
          fragment={fragment}
        />
      </li>
    ) : (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          modifier={id}
          activeCurrencyId={activeCurrencyId}
          type="input"
          fragment={fragment}
        />
      </li>
    );
  }

  return null;
};

const getFilterRangeContentPI = (id, values, url_templates, type, activeCurrencyId, labels, fragment) =>
  url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION) ? (
    <li>
      <RenderFilterRange
        values={values}
        url_templates={url_templates}
        labels={labels}
        modifier={id}
        type="input"
        activeCurrencyId={activeCurrencyId}
        fragment={fragment}
      />
    </li>
  ) : null;

const getModalModifier = (filterId) => {
  switch (filterId) {
    case IDS.VEHICLE_BODY_TYPE:
      return STYLE_MODIFIERS.VEHICLE_BODY_MODAL;

    default:
      return STYLE_MODIFIERS.DEFAULT_MODAL;
  }
};

const getActiveCurrency = (currencies) => {
  const activeCurrency = currencies?.filter((c) => c.selected).pop();

  return activeCurrency?.id;
};

const FilterItem = ({
  id,
  name,
  values = [],
  modal,
  expanded_values,
  url_templates,
  url,
  currencies,
  values_with_conversion,
  type,
  labels,
  fragment,
  icons,
} = {}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const hasMoneyPicker = id === IDS.PRICE && currencies;
  const [activeCurrencyId, setActiveCurrencyId] = useState(getActiveCurrency(currencies));
  const { visualId } = useStaticProps();

  const filterRangeRenderer = visualId === 'pi' ? getFilterRangeContentPI : getFilterRangeContent;
  const [isOpen, openModal, hideModal] = useModal();

  useEffect(() => {
    const withFilters = hasFilters();

    if (withFilters && isOpen) {
      setHideModal(() => hideModal);
    }
  }, [isOpen]);

  useEffect(() => {
    setActiveCurrencyId(getActiveCurrency(currencies));
  }, [currencies]);

  return (
    <div className={classnames(`${namespace}-dl`, { 'shops__filter-items': isShops })} key={id}>
      {!hasMoneyPicker &&
        type !== TYPES.HIGHLIGHTED &&
        (icons ? (
          <IconLabel
            aria-level="3"
            className={classnames(`${namespace}-dt-title`, { 'shops-custom-primary-font': isShops })}
            icons={icons}
            text={name}
          />
        ) : (
          <h3 aria-level="3" className={classnames(`${namespace}-dt-title`, { 'shops-custom-primary-font': isShops })}>
            {name}
          </h3>
        ))}
      <ul>
        {hasMoneyPicker && (
          <MoneyPicker
            currencies={currencies}
            filterValues={values_with_conversion}
            title={name}
            activeCurrencyId={activeCurrencyId}
            handleCurrencyChange={setActiveCurrencyId}
          />
        )}
        {getFilterValuesContent({ id, name, type, values, expanded_values, currencies, isShops })}
        {modal && (
          <li>
            <Modal
              className={`ui-search-modal--${getModalModifier(id)}`}
              label={modal.labels.modal_label}
              modalUrl="#filter"
              url={url}
              type="small"
              withLink
              open={isOpen}
              openModal={openModal}
              hideModal={hideModal}
              modalTitle={name}
            >
              <SearchModalContainer values={values} id={id} {...modal} title={name} />
            </Modal>
          </li>
        )}
        {filterRangeRenderer(id, values, url_templates, type, activeCurrencyId, labels, fragment)}
      </ul>
    </div>
  );
};

FilterItem.propTypes = {
  ...filterPropTypes,
};

const Filters = ({ filters: initialFilters, labels: initialLabels }) => {
  const filters = initialFilters || [];
  const labels = initialLabels || [];

  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    filters && (
      <section className={classnames(`${namespace}-groups`, { 'shops__filter-groups': isShops })}>
        {filters.map((filter) => (
          <FilterItem key={filter.id} {...filter} labels={labels} />
        ))}
      </section>
    )
  );
};

Filters.propTypes = {
  filters: arrayOf(shape({})),
  labels: arrayOf(shape({})),
};

export default Filters;
