import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Card, { CardContent, CardHeader } from '@andes/card';

import CollapsibleCardList from '../collapsible-card-list/collapsible-card-list';
import InfoTextBox from '../info-text-box/info-text-box';
import BlogsComponent from './blogs-component';
import SeoSmartTabs from '../seo-smart-tabs/seo-smart-tabs';
import SeoExtendedMenu from './seo-extended-menu';

const namespace = 'search-ui-seo-contents';

const InfoTextBoxIntervention = ({ title, description, see_more, see_less }) => (
  <CardHeader className={classnames(`${namespace}__header`)}>
    <InfoTextBox title={title} description={description} see_more={see_more} see_less={see_less} />
  </CardHeader>
);

const FaqIntervention = ({ titleFaqs, faqs, schema }) => (
  <CardContent className={classnames(`${namespace}__content`)}>
    <span className={classnames(`${namespace}__faqs-title`)}>{titleFaqs}</span>
    <CollapsibleCardList
      className={classnames(`${namespace}__faqs`)}
      items={faqs.map((faq) => ({
        title: faq.question,
        content: faq.answer,
      }))}
    />
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: schema }}
    />
  </CardContent>
);

const SeoContents = ({ items }) => {
  const getObjectById = (arr, id) => arr.find((obj) => obj.id === id);

  const textBoxInterventionObject = getObjectById(items, 'SEO_TEXT_BOX_INTERVENTION');
  const faqsInterventionObject = getObjectById(items, 'SEO_FAQS_INTERVENTION');
  const smartTabsInterventionObject = getObjectById(items, 'SEO_SMART_TABS_INTERVENTION');
  const extendedMenuInterventionObject = getObjectById(items, 'SEO_EXTENDED_MENU_INTERVENTION');

  let seoBlogsInterventionObject = null;

  const seoBlogs = getObjectById(items, 'SEO_BLOGS');

  if (seoBlogs) {
    seoBlogsInterventionObject = seoBlogs;
  } else {
    seoBlogsInterventionObject = getObjectById(items, 'SEO_BLOGS_INTERVENTION');
  }

  const { title, description, see_more, see_less } = textBoxInterventionObject || {};
  const { title: titleFaqs, faqs, schema } = faqsInterventionObject || {};
  const { title: tabsTitle, tabs, tracks } = smartTabsInterventionObject || {};

  return (
    <Card className={classnames(namespace)}>
      {textBoxInterventionObject && (
        <InfoTextBoxIntervention title={title} description={description} see_more={see_more} see_less={see_less} />
      )}

      {faqsInterventionObject && <FaqIntervention titleFaqs={titleFaqs} faqs={faqs} schema={schema} />}

      {seoBlogsInterventionObject && (
        <BlogsComponent
          seoBlogsInterventionObject={seoBlogsInterventionObject}
          isSeoIntervention={Boolean(faqsInterventionObject)}
        />
      )}

      {smartTabsInterventionObject && <SeoSmartTabs title={tabsTitle} tabs={tabs} tracks={tracks} />}

      {extendedMenuInterventionObject && (
        <SeoExtendedMenu extendedMenuInterventionObject={extendedMenuInterventionObject} />
      )}
    </Card>
  );
};

SeoContents.propTypes = {
  items: PropTypes.array.isRequired,
};
FaqIntervention.propTypes = {
  faqs: PropTypes.string.isRequired,
  schema: PropTypes.bool.isRequired,
  titleFaqs: PropTypes.string.isRequired,
};
InfoTextBoxIntervention.propTypes = {
  description: PropTypes.string.isRequired,
  see_less: PropTypes.bool.isRequired,
  see_more: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SeoContents;
