/* eslint-disable react-hooks/exhaustive-deps */
import React, { Children, cloneElement, useEffect, useRef, useState, useCallback } from 'react';
import { func, node, number, shape, string } from 'prop-types';

import debounce from 'lodash.debounce';

import { EVENTS, DEFAULT_MAP_OPTIONS, DEBOUNCE_MILLISECONDS } from './constants';
import helpers from './helpers';

const { addListener, getMap } = helpers;

const RESIZE = 'resize';

const renderChildren = (mapInstance, containerMeasurements, children) => {
  const validChildren = Children.toArray(children).filter(Boolean);

  return Children.map(validChildren, (child) => cloneElement(child, { map: mapInstance, containerMeasurements }));
};

const Map = ({
  center,
  children,
  className,
  height,
  options,
  onBoundsChanged,
  onClick,
  onDragEnd,
  onLoad,
  onZoomChanged,
  handleIdle,
  width,
}) => {
  const [map, setMap] = useState(null);
  const [containerMeasurements, setContainerMeasurements] = useState(null);
  const mapDomNode = useRef();

  const getContainerPosition = useCallback(() => {
    if (mapDomNode.current) {
      const containerPosition = mapDomNode.current.getBoundingClientRect();

      setContainerMeasurements({
        x: containerPosition.left,
        y: containerPosition.top + window.scrollY,
        height: containerPosition.height,
        width: containerPosition.width,
      });
    }
  }, [setContainerMeasurements]);

  const getContainerPositionDebounced = useCallback(debounce(getContainerPosition, DEBOUNCE_MILLISECONDS), [
    getContainerPosition,
  ]);

  useEffect(() => {
    const mapInstance = getMap(mapDomNode.current, {
      ...DEFAULT_MAP_OPTIONS,
      center,
      ...options,
    });

    setMap(mapInstance);

    if (onLoad) {
      onLoad(mapInstance);
    }
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.setCenter(center);
  }, [center]);

  useEffect(() => addListener(map, EVENTS.BOUNDS_CHANGED, onBoundsChanged), [map, onBoundsChanged]);

  useEffect(() => addListener(map, EVENTS.DRAG_END, onDragEnd), [map, onDragEnd]);

  useEffect(() => addListener(map, EVENTS.ZOOM_CHANGED, onZoomChanged), [map, onZoomChanged]);

  useEffect(() => addListener(map, EVENTS.CLICK, onClick), [map, onClick]);

  useEffect(() => addListener(map, EVENTS.IDLE, handleIdle), [map, handleIdle]);

  useEffect(() => {
    getContainerPositionDebounced();

    window.addEventListener(RESIZE, getContainerPositionDebounced);

    return () => window.removeEventListener(RESIZE, getContainerPositionDebounced);
  }, [getContainerPositionDebounced]);

  return (
    <div ref={mapDomNode} style={{ height, width }} className={className}>
      {map ? renderChildren(map, containerMeasurements, children) : null}
    </div>
  );
};

Map.propTypes = {
  center: shape({
    lat: number,
    lng: number,
  }),
  children: node,
  className: string,
  handleIdle: func,
  height: string,
  options: shape(),
  width: string,
  onBoundsChanged: func,
  onClick: func,
  onDragEnd: func,
  onLoad: func,
  onZoomChanged: func,
};

Map.defaultProps = {
  options: {},
  center: {
    lat: 30,
    lng: 70,
  },
  height: '800px',
  width: '100%',
};

export default Map;
