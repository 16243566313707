import noop from 'lodash/noop';

import { canUseDOM } from '../../../lib/dom-utils';

// Glossary:
// Bounds > a LatLngBounds object containing both SW and NE LatLngs
// Point > an object of { x, y } coordinates
// Coordinate > either x or y value from the map 2d plane projection
// Pixel > Distance in pixels between a coordinate and the map container's axis (top and left borders).
// Scale > 2 to the power of the current map zoom (approximation of the values seen here: http://webhelp.esri.com/arcgisserver/9.3/java/index.htm#designing_overlay_gm_mve.htm)

const safeGoogleAccess =
  (callback) =>
  (...args) =>
    canUseDOM && window.google ? callback(...args) : noop();

const addListener = (target, event, callback = noop) => {
  if (!target) {
    return noop;
  }

  const listener = target.addListener(event, callback);
  const cleanup = () => window.google.maps.event.removeListener(listener);

  return safeGoogleAccess(cleanup);
};

const addMarkerToBounds = (bounds, { latitude, longitude }) =>
  bounds.extend(new window.google.maps.LatLng(latitude, longitude));

const getBounds = (markers = [], defaultCoords = []) =>
  markers.reduce(addMarkerToBounds, new window.google.maps.LatLngBounds(...defaultCoords));

const getControlPosition = (position) => window.google.maps.ControlPosition[position];

const toBoundsArray = (bounds) => {
  const { west, south, east, north } = bounds.toJSON();

  return [west, south, east, north];
};

const getMarker = (options = {}) => new window.google.maps.Marker(options);

const getMarkerMaxZIndex = () => window.google.maps.Marker.MAX_ZINDEX;

const getMap = (domNode, options = {}) => new window.google.maps.Map(domNode, options);

const getSize = (x, y) => new window.google.maps.Size(x, y);

// The relation between a Coordinate and its Pixel value is described by
// the following expression: Coordinate * Scale = Pixel.
const fromCoordsToPixel = (initialCoord, finalCoord, scale, padding = 0) =>
  (initialCoord - finalCoord) * scale - padding;

// Calculate the pixel's coordinate in relation to an axis, then offset it
// by the coordinate value of said axis in order to get pixel's coordinate
// in relation to the current map projection.
const fromPixelToCoord = (pixelValue, scale, origCoord = 0) => pixelValue / scale + origCoord;

// Padding a point consists in moving its coordinates X pixels in its axis, where
// the reference system's origin is located at the topLeft corner of the map's container.
const padPoints = (
  { topRight, bottomLeft },
  /* eslint-disable-next-line @typescript-eslint/default-param-last */
  { top = 0, left = 0, bottom = 0, right = 0 } = {},
  scale,
) => {
  const { Point } = window.google.maps;

  // In order to move topRight.x and bottomLeft.y corners, we first need to calculate
  // the distance in pixels from the topLeft corner to each coordinate, minus `right`
  // and `bottom` paddings respectively.
  const paddedSwYPixel = fromCoordsToPixel(bottomLeft.y, topRight.y, scale, bottom);
  const paddedNeXPixel = fromCoordsToPixel(topRight.x, bottomLeft.x, scale, right);

  // To move the topRight.y and bottomLeft.x coordinates, we can simply use
  // the `top` and `left` padding values since they already represent the distance
  // between the topLeft and the padded coordinate.
  return {
    paddedSwPoint: new Point(
      fromPixelToCoord(left, scale, bottomLeft.x),
      fromPixelToCoord(paddedSwYPixel, scale, topRight.y),
    ),
    paddedNePoint: new Point(
      fromPixelToCoord(paddedNeXPixel, scale, bottomLeft.x),
      fromPixelToCoord(top, scale, topRight.y),
    ),
  };
};

// Turns a bounds object into a pair of { x, y } coordinates.
const getBoundingPoints = (bounds, projection) => ({
  bottomLeft: projection.fromLatLngToPoint(bounds.getSouthWest()),
  topRight: projection.fromLatLngToPoint(bounds.getNorthEast()),
});

// Returns a new LatLngBounds object where both SW and NE are padded
// a given number of pixels.
// See: https://stackoverflow.com/questions/34894732/add-padding-to-google-maps-bounds-contains
const padBounds = (map, paddings) => {
  const { LatLngBounds } = window.google.maps;
  const projection = map.getProjection();
  const scale = 2 ** map.getZoom();

  const { paddedSwPoint, paddedNePoint } = padPoints(getBoundingPoints(map.getBounds(), projection), paddings, scale);

  return new LatLngBounds(projection.fromPointToLatLng(paddedSwPoint), projection.fromPointToLatLng(paddedNePoint));
};

const getPointPosition = (map, latLng) => {
  const naturalPosition = new window.google.maps.LatLng(latLng);
  const projection = map.getProjection();
  const scale = 2 ** map.getZoom();
  const worldPoint = projection.fromLatLngToPoint(naturalPosition);
  const { topRight, bottomLeft } = getBoundingPoints(map.getBounds(), projection);

  const pointPixel = new window.google.maps.Point(
    fromCoordsToPixel(worldPoint.x, bottomLeft.x, scale),
    fromCoordsToPixel(worldPoint.y, topRight.y, scale),
  );

  return pointPixel;
};

export default {
  addListener,
  getBounds: safeGoogleAccess(getBounds),
  getControlPosition: safeGoogleAccess(getControlPosition),
  getMarker: safeGoogleAccess(getMarker),
  getMarkerMaxZIndex: safeGoogleAccess(getMarkerMaxZIndex),
  getMap: safeGoogleAccess(getMap),
  getPointPosition: safeGoogleAccess(getPointPosition),
  getSize: safeGoogleAccess(getSize),
  padBounds: safeGoogleAccess(padBounds),
  safeGoogleAccess,
  toBoundsArray,
};
