const distributeInColumns = (items, maxColumns, maxItemsPerColumn) => {
  const totalItemsToRender = Math.min(items.length, maxColumns * maxItemsPerColumn);
  const totalColumnsToRender = Math.min(totalItemsToRender, maxColumns);

  const itemsPerColumn = parseInt(totalItemsToRender / totalColumnsToRender, 10);

  let remaining = totalItemsToRender % totalColumnsToRender;

  const actualItemsPerColumn = [];

  for (let i = 0; i < totalColumnsToRender; i += 1) {
    actualItemsPerColumn.push(itemsPerColumn);
  }

  while (remaining > 0) {
    for (let i = 0; i < actualItemsPerColumn.length && remaining > 0; i += 1) {
      actualItemsPerColumn[i] += 1;
      remaining -= 1;
    }
  }

  let itemsToRender = items;

  const columns = [];

  actualItemsPerColumn.forEach((itemsToAddToColumn) => {
    const columnItems = itemsToRender.slice(0, itemsToAddToColumn);

    itemsToRender = itemsToRender.slice(itemsToAddToColumn);
    columns.push(columnItems);
  });

  return columns;
};

export { distributeInColumns };
