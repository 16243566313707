import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'ic_full_super-mlb';
const namespace = 'ui-search-icon ui-search-icon--full-super ui-search-icon--full-super-mlb';

const FullSuperMlb = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 152 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Full Super"
    role="img"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

FullSuperMlb.propTypes = {
  className: string,
};

const IconApplMemo = React.memo(FullSuperMlb);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
