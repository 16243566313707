import React from 'react';
import { arrayOf } from 'prop-types';

import Group from '../group/group';
import ComposedLabel from '../../composed-label/composed-label';

const namespace = 'ui-search-item__spotlight';

const SpotlightGroup = ({ spotlight }) => (
  <Group noSeparator className={namespace}>
    {spotlight.map((destaque) => (
      <ComposedLabel {...destaque} className={`${namespace}__highlight`} />
    ))}
  </Group>
);

SpotlightGroup.propTypes = {
  spotlight: arrayOf([]),
};

SpotlightGroup.defaultProps = {
  spotlight: [],
};

export default SpotlightGroup;
