import React from 'react';

import { loadable } from 'nordic/lazy';

import useListingDisclaimer from './use-listing-disclaimer';

const ChunkListingDisclaimer = () => {
  const listingDisclaimerProps = useListingDisclaimer();
  const ListingDisclaimer = loadable(async () =>
    import(/* webpackChunkName: "chunk-listing-disclaimer" */ './listing-disclaimer.mobile'),
  );

  return listingDisclaimerProps ? <ListingDisclaimer {...listingDisclaimerProps} /> : null;
};

export default ChunkListingDisclaimer;
