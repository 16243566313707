import React from 'react';
import { string, bool, shape } from 'prop-types';
import classNames from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';

import MoneyAmount from '@andes/money-amount';

const NAMESPACE = `${PACKAGE_NAMESPACE}-price__part`;

const PricePart = ({
  className,
  currency_symbol: currencySymbol,
  decimal,
  id,
  strikeThrough,
  styles,
  text,
}) => (
  <span style={{ ...styles }}>
    <MoneyAmount
      className={classNames(NAMESPACE, className, id)}
      value={{
        fraction: text,
        cents: decimal || null,
      }}
      disabled={strikeThrough}
      symbol={currencySymbol}
      previous={strikeThrough}
      size={16}
      centsType="superscript"
    />
  </span>
);


PricePart.propTypes = {
  className: string,
  currency_symbol: string,
  decimal: string,
  id: string,
  strikeThrough: bool,
  styles: shape(),
  text: string,
};

PricePart.defaultProps = {
  className: null,
  currency_symbol: null,
  decimal: true,
  strikeThrough: false,
  styles: null,
  text: null,
};

export default PricePart;
