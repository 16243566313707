import { trackEvent as trackAnalyticsEvent } from '../../../lib/analytics';
import { trackEvent as trackMelidataEvent } from '../../../lib/melidata';

const trackEvent = (analytics, melidata_track) => {
  if (melidata_track) {
    trackMelidataEvent(melidata_track);
  }

  if (analytics) {
    trackAnalyticsEvent({
      category: analytics.analytics_track_category,
      action: analytics.analytics_track_action,
      label: analytics.analytics_track_label,
    });
  }
};

export { trackEvent };
