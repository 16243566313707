import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import PricePart from './price-part';
import IconLoyalty from '../../icons/loyalty';
import { useStaticProps } from '../../context/static-props';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-price';

const { bool, string, number, oneOf, shape } = PropTypes;

const Price = ({
  bold = false,
  className = null,
  decimalSeparator = null,
  original_price = null,
  size = 'medium',
  currency_id,
  thousandSeparator = null,
  amount,
  discount_label = null,
  discountSource = null,
  from = null,
  showDecimals = true,
  showOriginalValue,
  billing = false,
  bulksale = null,
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { country } = useStaticProps();
  const { currencies } = country;
  const { symbol } = currencies[`${currency_id}`];
  const defaultThousandSeparator = country.thousands_separator;
  const defaultDecimalSeparator = country.decimal_separator;
  const computedThousandSeparator = thousandSeparator || defaultThousandSeparator;
  const computedDecimalSeparator = decimalSeparator || defaultDecimalSeparator;

  return (
    <div
      className={classNames(namespace, `${namespace}--size-${size}`, className, {
        shops__price: isShops,
      })}
    >
      {showOriginalValue && (
        <PricePart
          className={`${namespace}__original-value`}
          value={bulksale?.original_price ?? original_price}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          strikeThrough
          billing={billing}
          size="small"
        />
      )}
      {from && <div className={`${namespace}__front-price`}>{from.text}</div>}
      <div
        className={classNames(`${namespace}__second-line`, {
          [`${namespace}__second-line--bold`]: bold,
          [`${namespace}__second-line--decimal`]: billing,
          'shops__price-second-line': isShops,
        })}
      >
        <PricePart
          value={bulksale?.price ?? amount}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          billing={billing}
          size={size}
          bulksale={bulksale}
        />
        {(discountSource || discount_label) && (
          <span
            className={classNames(`${namespace}__second-line__label`, { 'shops__price-second-line__label': isShops })}
          >
            {discountSource === 'loyalty' && <IconLoyalty hidden />}
            {discount_label && (
              <>
                {discount_label.prefix && (
                  <span className={`${namespace}__discount_prefix`}>{discount_label.prefix}</span>
                )}
                {discount_label.text && (
                  <span className={classNames(`${namespace}__discount`, { 'shops__price-discount': isShops })}>
                    {discount_label.text}
                  </span>
                )}
              </>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

Price.propTypes = {
  amount: number.isRequired,
  billing: bool,
  bold: bool,
  bulksale: shape({}),
  className: string,
  currency_id: string.isRequired,
  decimalSeparator: string,
  discount_label: shape({
    text: string.isRequired,
    color: string,
    prefix: string,
    prefix_color: string,
  }),
  discountSource: string,
  from: shape({ text: string }),
  original_price: number,
  showDecimals: bool,
  showOriginalValue: bool.isRequired,
  size: oneOf(['tiny', 'small', 'medium', 'large']),
  thousandSeparator: string,
};

export default Price;
