import React from 'react';

import classnames from 'classnames';

import LoadingCard from '../loading';
import { namespace } from '../../constants';

const repeats = Array.from(Array(5));

const createKey = (index) => `list-placeholder--${index}`;

const ListPlaceholder = () => (
  <div>
    {repeats.map((_, index) => (
      <div className={classnames(namespace, `${namespace}__item`)} key={createKey(index)}>
        <LoadingCard />
      </div>
    ))}
  </div>
);

export default ListPlaceholder;
