import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--installations';

const ICON_ID = 'installations_icon';

const IconInstallation = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    aria-hidden="true"
  >
    <path
      d="M3.14789 14.7411C3.38278 14.976 3.76362 14.976 3.99851 14.7411C4.2334 14.5062 4.2334 14.1254 3.99851 13.8905C3.76362 13.6556 3.38278 13.6556 3.14789 13.8905C2.913 14.1254 2.913 14.5062 3.14789 14.7411Z"
      fill="black"
      fillOpacity="0.9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7574 0.569824C10.2083 0.569824 8.14183 2.6363 8.14183 5.18542C8.14183 5.64349 8.20856 6.08598 8.33283 6.50369L1.38764 12.9404C0.336755 13.9144 0.305381 15.5664 1.31853 16.5796C2.32659 17.5876 3.96859 17.5624 4.94519 16.5238L11.4449 9.61173C11.8609 9.7349 12.3015 9.80102 12.7574 9.80102C15.3065 9.80101 17.373 7.73454 17.373 5.18542C17.373 4.57566 17.2545 3.99212 17.0386 3.45766L16.6991 2.61704L14.0543 5.26181L12.9849 4.9073L12.6304 3.83791L15.2669 1.20138L14.4015 0.871404C13.89 0.676361 13.3354 0.569824 12.7574 0.569824ZM9.34183 5.18542C9.34183 3.29904 10.871 1.76982 12.7574 1.76982C12.8368 1.76982 12.9155 1.77252 12.9934 1.77783L11.2584 3.51284L12.0354 5.85677L14.3794 6.63379L16.1574 4.85579C16.1677 4.96419 16.173 5.07414 16.173 5.18542C16.173 7.0718 14.6438 8.60101 12.7574 8.60102C10.871 8.60102 9.34183 7.0718 9.34183 5.18542ZM10.2908 9.08735C9.71348 8.72161 9.22288 8.23128 8.85684 7.65415L2.20334 13.8206C1.65164 14.3319 1.63517 15.1992 2.16706 15.731C2.69627 16.2603 3.55829 16.247 4.07099 15.7018L10.2908 9.08735Z"
      fill="black"
      fillOpacity="0.9"
    />
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconInstallation.propTypes = {
  className: string,
};

export default React.memo(IconInstallation);
