import React from 'react';

import { renderTitleGroup, renderShippingGroup } from '../core';
import { renderPriceGroup, ContentSkeleton } from '../common';

function renderContent(item, options, layout) {
  return (
    <ContentSkeleton
      topComponents={[
        renderTitleGroup(item, options, layout),
        renderPriceGroup(item, options),
        renderShippingGroup(item, layout),
      ]}
    />
  );
}

export default renderContent;
