import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-full';

const IconStarEmpty = ({ className = null }) => (
  <svg className={classnames(namespace, className)} width="100%" height="100%" viewBox="0 0 11 10">
    <use href="#poly_star" />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

export default React.memo(IconStarEmpty);
