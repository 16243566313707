import React from 'react';
import { shape, string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--url';

const ImageIconUrl = ({ className, tags }) => (
  <Image
    className={classnames(namespace, className)}
    src={tags.url}
    width={tags.width}
    height={tags.height}
    alt={tags.alt_text ?? ''}
    preload={tags.preload}
    lazyload="off"
  />
);

ImageIconUrl.propTypes = {
  className: string,
  tags: shape({}),
};
ImageIconUrl.defaultProps = {
  className: null,
  tags: {},
};

export default ImageIconUrl;
