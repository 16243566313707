import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--installation';

const IconInstallation = ({ description, color, className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    aria-label={description}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2239 0.734314C9.00752 0.734314 7.21082 2.53102 7.21082 4.74737C7.21082 5.12597 7.26324 5.49232 7.36123 5.83956L1.49894 11.2727C0.568284 12.1352 0.540499 13.5982 1.43773 14.4955C2.33046 15.3882 3.7846 15.3658 4.64946 14.4461L10.1362 8.61128C10.4821 8.70845 10.8469 8.76042 11.2239 8.76042C13.4402 8.76042 15.2369 6.96372 15.2369 4.74737C15.2369 4.21736 15.1339 3.70994 14.9461 3.24514L14.6066 2.40452L12.3018 4.70935L11.4885 4.43974L11.2188 3.62643L13.5187 1.32653L12.6533 0.996549C12.2085 0.826927 11.7263 0.734314 11.2239 0.734314ZM8.41082 4.74737C8.41082 3.19709 9.66487 1.93971 11.2139 1.93433L9.84686 3.30136L10.539 5.3892L12.6268 6.08134L14.0359 4.67222C14.0366 4.69719 14.0369 4.72224 14.0369 4.74737C14.0369 6.30097 12.7775 7.56042 11.2239 7.56042C9.67026 7.56042 8.41082 6.30097 8.41082 4.74737ZM8.98814 8.08045C8.55545 7.78964 8.18248 7.4168 7.89153 6.9842L2.31463 12.1528C1.88317 12.5527 1.87029 13.231 2.28626 13.6469C2.70014 14.0608 3.37429 14.0505 3.77526 13.6241L8.98814 8.08045Z"
      fill={color}
    />
  </svg>
);

IconInstallation.propTypes = {
  className: string,
  color: string,
  description: string,
};

IconInstallation.defaultProps = {
  description: null,
  color: '#3483FA',
  className: null,
};

export default React.memo(IconInstallation);
