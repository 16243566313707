const { PICTURES_CAROUSEL_SERVICE_BASE_PATH } = require('../constants/paths');
const picturesCarouselClient = require('../client/restclient')({
  timeout: 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});

class Service {
  static getCarouselPictures(itemId, variationIds, isPolycard = false) {
    const polycardHeader = isPolycard && { 'x-card-type': 'polycard' };
    const variationsIds = isPolycard ? variationIds : variationIds?.join(',');

    return picturesCarouselClient
      .get(`${PICTURES_CAROUSEL_SERVICE_BASE_PATH}?item_id=${itemId}&variation_ids=${variationsIds}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
          ...polycardHeader,
        },
      })
      .then((response) => {
        let newResonse;

        if (isPolycard) {
          newResonse = response.data.variations;
        } else {
          newResonse = response.data.pictures;
        }

        return newResonse;
      });
  }
}

module.exports = Service;
