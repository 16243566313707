import React from 'react';
import { string, shape } from 'prop-types';

import classnames from 'classnames';

import IconSupermarketLogo from '../../icons/supermarket-logo';
import IconInternationalLogo from '../../icons/international-logo';

const SUPERMARKET_LOGO_ICON = 'supermarket_logo';
const INTERNATIONAL_LOGO = 'international_logo';

const namespace = 'ui-search-vertical-highlight';

const renderIcon = (icon) => {
  switch (icon.id) {
    case SUPERMARKET_LOGO_ICON:
      return <IconSupermarketLogo className={`${namespace}__icon`} {...icon} />;
    case INTERNATIONAL_LOGO:
      return <IconInternationalLogo className={`${namespace}__icon`} {...icon} />;

    default:
      return null;
  }
};

const VerticalHighlight = ({ className, id, icon }) => (
  <div className={classnames(namespace, className, `${namespace}--${id}`)}>{renderIcon(icon)}</div>
);

VerticalHighlight.propTypes = {
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
  id: string.isRequired,
};

VerticalHighlight.defaultProps = {
  className: null,
};

export default VerticalHighlight;
