import React from 'react';
import { string, shape, bool } from 'prop-types';

import sources from './utils/component-types';
import resources from './utils/map-components';
import { SEARCH_API_BASE_PATH } from '../../../services/constants/paths';

const wrapSticky = (comp) => <div className="wrapper-sticky">{comp}</div>;

const ComponentFactory = ({ component, properties, children, hidden, siteId }) => {
  const source = resources[component];

  if (hidden || !source) {
    return null;
  }

  const isPH = component === sources.PROFILE_HEADER;

  const { is_repurchase = false, ...rest } = properties;

  let props = isPH ? { ...properties, baseURL: `${SEARCH_API_BASE_PATH}/` } : { ...rest, isRepurchase: is_repurchase };

  props = { ...props, siteId };

  const params =
    children?.length > 0 ? [source, props, children?.map((ch) => <ComponentFactory {...ch} />)] : [source, props];

  if (isPH) {
    return wrapSticky(React.createElement(...params));
  }

  return React.createElement(...params);
};

ComponentFactory.propTypes = {
  children: shape.isRequired,
  component: string.isRequired,
  hidden: bool.isRequired,
  is_repurchase: bool.isRequired,
  properties: shape.isRequired,
  siteId: string.isRequired,
};

export default ComponentFactory;
