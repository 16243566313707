import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--not-found';

const IconNotFound = ({ className = null }) => (
  <div className={classnames(namespace, className)}>
    <Image src="not-found.svg" alt="" />
  </div>
);

IconNotFound.propTypes = {
  className: string,
};

export default IconNotFound;
