import { useSearch } from '../../../hooks/context';

const buildTrackData = ({ analytics_track_event: analytics, melidata_track } = {}) => ({
  analytics,
  melidata: { melidata_track },
});

const useMapConfig = () => {
  const {
    map_configuration: {
      redesign_map_available = false,
      autosuggest_enabled_sites = [],
      cluster_message,
      custom_controls: { map_view, zoom } = {},
      error_message: { button_retry, message } = {},
      loading_message,
      open_carousel = {},
      open_pictures_carousel = {},
      pagination: { selected_page, next_page, previous_page, show_pagination, page_count } = {},
      results_message,
      zrp: { zrp_message, zrp_title } = {},
      filters,
      pictures_back_message,
      pictures_list_message,
      pictures_loading_message,
    } = {},
    new_faceted_search,
    melidata_track,
  } = useSearch();

  return {
    autosuggestEnabledSites: autosuggest_enabled_sites,
    badgeText: results_message,
    controls: { mapType: map_view, zoom },
    clusterText: cluster_message,
    error: { action: button_retry, message },
    loadingText: loading_message,
    pagination: {
      currentPage: selected_page,
      nextPage: next_page,
      previousPage: previous_page,
      showPagination: show_pagination,
      pageCount: page_count,
    },
    track: {
      openMobileCardCarousel: buildTrackData(open_carousel),
      openMobilePicturesCarousel: buildTrackData(open_pictures_carousel),
    },
    zrp: { message: zrp_message, title: zrp_title },
    filters,
    searchTrack: melidata_track,
    newFacetedSearch: new_faceted_search,
    backButtonText: pictures_back_message,
    picturesListText: pictures_list_message,
    picturesLoadingMessage: pictures_loading_message,
    redesign_map_available,
  };
};

export default useMapConfig;
