import { useState } from 'react';

// You can add more options if needed
const useModal = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  function openModal(e) {
    if (!e.ctrlKey && !e.metaKey) {
      e.preventDefault();
      setIsOpen(true);
    }
  }

  function hideModal() {
    setIsOpen(false);
  }

  return [isOpen, openModal, hideModal];
};

export default useModal;
