import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--black-friday';
const ICON_ID = 'black_friday';

const BlackFriday = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

BlackFriday.propTypes = {
  className: string,
};

BlackFriday.defaultProps = {
  className: null,
};

export default BlackFriday;
