import { createSetter, useInitialState, useSubscribedState, getSnapshot } from 'nordic/page/store';

export const useInitialFilters = () => useInitialState().filters;

export const useFilters = () => useSubscribedState().filters;
export const useIsSecondaryFilterSelected = () => useFilters().isSecondaryFilterSelected;
export const useLastFilterId = () => useFilters().lastFilterId;

export const getFilters = () => getSnapshot().filters;
export const getHideModal = () => getFilters().hideModal;
export const getIsSecondaryFilterSelected = () => getFilters().isSecondaryFilterSelected;

export const hasFilters = () => !!getFilters();

export const setIsSecondaryFilterSelected = createSetter(({ filters }, payload) => {
  filters.isSecondaryFilterSelected = payload;
});
export const setLastFilterId = createSetter(({ filters }, payload) => {
  filters.lastFilterId = payload;
});
export const setHideModal = createSetter(({ filters }, payload) => {
  filters.hideModal = payload;
});
