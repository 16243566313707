import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import StyledLabelWrapper from '../styled-label/styled-label';
import IconSupermarketLogo from '../icons/supermarket-logo';
import IconElipseCheck from '../icons/elipse-check-icon';
import IconMeliCoin from '../icons/icon-melicoin';
import IconLoyalty from '../icons/loyalty';
import ImageIconUrl from '../icons/image-icon-url';

const namespace = 'ui-search-composed-label-value';

const TYPES = {
  TEXT: 'text',
  BOLD_TEXT: 'bold_text',
  ICON: 'icon',
};

const ICON_TYPES = {
  SUPERMARKET_LOGO: 'supermarket_logo',
  ELIPSE_CHEK: 'destaque-compatible-icon',
  MELICOIN_ICON: 'melicoin_icon',
  LOTALTY: 'loyalty',
  URL: 'url-icon',
};

const renderIcon = ({ id, ...props }) => {
  switch (id) {
    case ICON_TYPES.SUPERMARKET_LOGO:
      return <IconSupermarketLogo {...props} />;
    case ICON_TYPES.ELIPSE_CHEK:
      return <IconElipseCheck {...props} />;
    case ICON_TYPES.MELICOIN_ICON:
      return <IconMeliCoin {...props} />;
    case ICON_TYPES.LOTALTY:
      return <IconLoyalty {...props} />;
    case ICON_TYPES.URL:
      return <ImageIconUrl {...props} />;

    default:
      return null;
  }
};

const ComposedLabelValue = ({ type, className, ...props }) => {
  const valueClassName = classnames(className, namespace);

  switch (type) {
    case TYPES.TEXT:
    case TYPES.BOLD_TEXT:
      return <StyledLabelWrapper className={valueClassName} {...props} />;
    case TYPES.ICON:
      return renderIcon({ className: valueClassName, ...props });

    default:
      return null;
  }
};

ComposedLabelValue.propTypes = {
  className: string,
  type: string,
};

ComposedLabelValue.defaultProps = {
  className: null,
  type: null,
};

export default ComposedLabelValue;
