import React from 'react';

function componentFactoryFrom(sources) {
  function ComponentFactory(component) {
    const { type } = component;
    const typeInUppercase = type.toUpperCase();

    const { [typeInUppercase]: source } = sources;

    if (!source) return null;

    const props = component;

    return React.createElement(source, props);
  }

  return ComponentFactory;
}

export default componentFactoryFrom;
