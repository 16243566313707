/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

import { isLocalStorageSupported } from '../lib/dom-utils';

const saveVisibility = (id) => {
  try {
    localStorage.setItem(id, false);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving in localStorage');
  }
};

const useMessage = (id) => {
  const localStorageSupported = isLocalStorageSupported();
  const [visible, setVisible] = useState(false);

  function handleClose() {
    if (localStorageSupported) {
      saveVisibility(id);
    }

    setVisible(false);
  }

  useEffect(() => {
    if (localStorageSupported) {
      const messageStatus = localStorage.getItem(id);

      if (!messageStatus) {
        setVisible(true);
      }
    } else {
      setVisible(false);
    }
  }, [localStorageSupported]);

  return [visible, handleClose];
};

export default useMessage;
