import React from 'react';

import { loadable } from 'nordic/lazy';

import usePagination from './hooks/use-pagination';

const ChunkPaginationMobile = () => {
  const paginationProps = usePagination();
  const PaginationMobile = loadable(async () =>
    import(/* webpackChunkName: "chunk-related-searches" */ './pagination.mobile'),
  );
  const hasPagination = paginationProps?.showPagination;

  return hasPagination ? <PaginationMobile {...paginationProps} /> : null;
};

export default ChunkPaginationMobile;
