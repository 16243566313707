/* eslint-disable security/detect-object-injection */
import React from 'react';

import classNames from 'classnames';
import { string, shape } from 'prop-types';
import { libraryIcons } from './common';

const namespace = 'icon-custom';

const defaultStyles = {};
const Icon = ({ icon, size, class_name, color, description, sourceIcons }) => {
  const iconName = libraryIcons?.[icon]?.[String(size)];
  const { [iconName]: IconCustom } = sourceIcons;

  if (!iconName || !IconCustom) return null;

  return (
    <span
      style={{ ...defaultStyles }}
      className={classNames(namespace, class_name)}
      aria-label={description}
      text={description}
    >
      <IconCustom color={color} />
    </span>
  );
};


Icon.defaultProps = {
  icon: null,
  size: null,
  color: null,
  class_name: null,
  description: null,
  sourceIcons: {},
};

Icon.propTypes = {
  icon: string,
  size: string,
  color: string,
  class_name: string,
  description: string,
  sourceIcons: shape({}),
};

export default Icon;
