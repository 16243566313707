import React, { useEffect } from 'react';
import { shape, func } from 'prop-types';

import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';
import { Portal } from '@andes/common';
import { Text } from '@andes/typography';

import useItem from '../../../hooks/use-item';
import useMapConfig from '../hooks/use-map-config';
import { namespace as baseNamespace, GRID } from '../../layout/renderer/constants';
import { PicturesCarousel } from './pictures-carousel';

const namespace = `${baseNamespace}__picture-swipe`;

const PictureSwipe = ({ item, options, onClose }) => {
  const { picturesLoadingMessage } = useMapConfig();

  const { isLoadingPictures, itemPictures, handleCardHover } = useItem({
    middlewareItem: item,
    layout: GRID,
    cardRef: {},
  });

  useEffect(() => {
    handleCardHover();
  }, [handleCardHover]);

  return (
    <Portal>
      <dialog className={namespace}>
        {isLoadingPictures() ? (
          <div className={`${namespace}__loading-container`}>
            <ProgressIndicatorCircular size="large" />
            <Text color="inverted" className={`${namespace}__loading-text`}>
              {picturesLoadingMessage}
            </Text>
          </div>
        ) : (
          <PicturesCarousel pictures={itemPictures} item={item} options={options} onClose={onClose} />
        )}
      </dialog>
    </Portal>
  );
};

PictureSwipe.propTypes = {
  item: shape({}).isRequired,
  options: shape({}).isRequired,
  onClose: func.isRequired,
};

export { PictureSwipe };
