import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--international';

const IconInternationalCBT = ({ className, srLabel = 'Compra Internacional' }) => (
  <svg
    width="156"
    height="16"
    viewBox="0 0 156 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames(namespace, className)}
    alt={srLabel}
  >
    <use href="#cbt_international" />
  </svg>
);

IconInternationalCBT.propTypes = {
  className: string,
};

export default React.memo(IconInternationalCBT);
