import React from 'react';
import { arrayOf, bool, func, shape, string, number } from 'prop-types';

import Header from './header';
import FullMap from './full-map';
import { useMapState } from './hooks/use-map-state';
import useMapConfig from './hooks/use-map-config';
import { trackClickEvent } from './track';

const MapMobile = ({
  fitMarkersInMap,
  className,
  isLoadingResults,
  markers,
  updateResults,
  fixedCoords,
  selectedMarker,
  setSelectedMarker,
  showError,
}) => {
  const { handleDragEnd, handleMapLoad, handleZoomChanged, handleBoundsChanged, mapStatus, handleIdle } = useMapState({
    fitMarkersInMap,
    isLoadingResults,
    markers,
    updateResults,
    fixedCoords,
    selectedMarker,
    setSelectedMarker,
    panToSelectedMarker: true,
  });
  const {
    track: {
      openMobileCardCarousel: { analytics, melidata },
    },
  } = useMapConfig();
  const handleMarkerClick = (markerIndex) => {
    trackClickEvent(analytics, melidata);
    setSelectedMarker(markerIndex);
  };

  return (
    <FullMap
      className={className}
      mapStatus={mapStatus}
      markers={markers}
      handleBoundsChanged={handleBoundsChanged}
      handleMapLoad={handleMapLoad}
      handleDragEnd={handleDragEnd}
      handleZoomChanged={handleZoomChanged}
      handleMapClick={() => setSelectedMarker(null)}
      handleMarkerClick={handleMarkerClick}
      selectedMarker={selectedMarker}
      handleIdle={handleIdle}
    >
      {!showError && <Header isLoadingResults={isLoadingResults} />}
    </FullMap>
  );
};

MapMobile.propTypes = {
  className: string,
  fitMarkersInMap: bool,
  fixedCoords: arrayOf(shape({ lat: number, lng: number })),
  isLoadingResults: bool,
  markers: arrayOf(shape()),
  selectedMarker: number,
  setSelectedMarker: func.isRequired,
  showError: bool,
  updateResults: func.isRequired,
};

MapMobile.defaultProps = {
  className: null,
  fitMarkersInMap: false,
  fixedCoords: undefined,
  isLoadingResults: false,
  markers: [],
  selectedMarker: null,
  showError: false,
};

export default MapMobile;
