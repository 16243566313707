import getHistory from '../../../lib/history';
import { useInitialFilters, setHideModal, getHideModal, setLastFilterId } from './index';
import SearchUrlService from '../../../../services/search-url';
import { FILTERS } from '../../../constants';

const VALUE_SEPARATOR = ',';
const { IDS } = FILTERS;
const LOCATION_FILTERS = [IDS.STATE, IDS.CITY, IDS.NEIGHBORHOOD];

// SearchUrl expects PIN instead of `pi` for the `visual_id` param.
const MAPPED_VISUAL_IDS = {
  pi: 'PIN',
};

const getAppliedSort = (sorts) => ({
  sort: (sorts.find(({ selected }) => selected) || {})?.id,
});

const getId = ({ id }) => id;

const mergeAppliedValues = (filters, currentFilter) => ({
  ...filters,
  [currentFilter.id]: currentFilter.values.map(getId).join(VALUE_SEPARATOR),
});

const getAppliedFilters = (filters) => filters.reduce(mergeAppliedValues, {});

const isLocationFilter = (filterId) => LOCATION_FILTERS.includes(filterId);

const getUrlFilters = ({ sortingOptions, filters, removeOffset, useExistingFilters }) => {
  const selectedSort = getAppliedSort(sortingOptions);
  const validFilters = filters.filter(({ id }) => useExistingFilters || !isLocationFilter(id));

  if (!removeOffset && validFilters.length === filters.length) {
    return null;
  }

  const appliedFilters = getAppliedFilters(validFilters);

  return {
    ...selectedSort,
    ...appliedFilters,
  };
};

// Omit any filter with `null` value.
const mergeValidFilters = (urlFilters, otherUrlFilters) => {
  const allFilters = { ...urlFilters, ...otherUrlFilters };
  const validFilters = {};

  Object.keys(allFilters).forEach((filterId) => {
    const value = allFilters[filterId];

    if (value !== null && value !== undefined) {
      validFilters[filterId] = value;
    }
  });

  return validFilters;
};

const buildPathname = ({ siteId, visualId, ...urlFilters }) =>
  SearchUrlService.getSearchUrl(urlFilters, siteId, MAPPED_VISUAL_IDS[visualId] || visualId).then(
    ({ data }) => new URL(data.url).pathname,
  );

const getPathname = ({
  sortingOptions,
  appliedFilters,
  removeOffset = false,
  useExistingFilters = false,
  ...otherUrlFilters
}) => {
  const urlFilters = getUrlFilters({
    sortingOptions,
    filters: appliedFilters,
    removeOffset,
    useExistingFilters,
  });

  return urlFilters ? buildPathname(mergeValidFilters(urlFilters, otherUrlFilters)) : Promise.resolve(null);
};

const serializeItemLocation = ({ south, north, west, east }) =>
  `_item*location_lat:${south}*${north},lon:${west}*${east}`;

// String pattern for a lat or lng value, e.g. `-85.709562`
const COORD_PATTERN = '-?\\d+\\.?\\d*';

const coordRegex = new RegExp(COORD_PATTERN, 'g');

const deserializeItemLocation = (filterValue = '') => {
  const coords = filterValue.match(coordRegex);

  if (!coords) {
    return [];
  }

  const [swLat, neLat, swLng, neLng] = coords.map((coord) => Number(coord));

  return [
    { lat: swLat, lng: swLng },
    { lat: neLat, lng: neLng },
  ];
};

// String pattern for a lat*lat or lng*lng value, e.g. `-46.64342*-12.331441`
const coordSetPattern = `${COORD_PATTERN}\\*${COORD_PATTERN}`;

const itemLocationRegex = new RegExp(`_item\\*location_lat:${coordSetPattern},lon:${coordSetPattern}`);

const removeItemLocation = (pathname) => pathname.replace(itemLocationRegex, '');

const filterSearch = (href, id, hash) => (event) => {
  event?.preventDefault();

  const { pathname } = new URL(href, window.location);
  const history = getHistory();

  history.push({
    pathname: isLocationFilter(id) ? removeItemLocation(pathname) : pathname,
    search: history.location.search,
    hash: hash ? `#${hash}` : undefined,
  });

  setLastFilterId(id);

  if (getHideModal()) {
    setHideModal();
  }
};

const useHasFiltersState = () => !!useInitialFilters();

export {
  deserializeItemLocation,
  getAppliedFilters,
  getAppliedSort,
  getPathname,
  isLocationFilter,
  removeItemLocation,
  serializeItemLocation,
  filterSearch,
  useHasFiltersState,
};
