import React from 'react';

import { loadable } from 'nordic/lazy';

import useCrossSite from './use-cross-site';

const ChunkCrossSiteModal = () => {
  const crossSiteProps = useCrossSite();
  const CrossSiteModal = loadable(async () =>
    import(/* webpackChunkName: "chunk-cross-site-modal" */ './cross-site-modal'),
  );

  return crossSiteProps ? <CrossSiteModal {...crossSiteProps} /> : null;
};

export default ChunkCrossSiteModal;
