import React from 'react';
import { string, arrayOf, shape, number } from 'prop-types';

import useVehicleBodySelector from './use-vehicle-body-selector';
import { useCurrentLayout } from '../context/layout';
import useCarouselBreakpoints from '../../hooks/use-carousel-breakpoints';
import VehicleBodyItem from './vehicle-body-item';
import VehicleBodyViewMore from './vehicle-body-viewmore';

const VEHICLE_BODY_SELECTOR = 'VEHICLE_BODY_SELECTOR';

const namespace = 'ui-search-vehicle-body-selector';

const VehicleBodySelector = ({ image, values, modal, total_body_types }) => {
  const currentLayout = useCurrentLayout();
  const { slidesToShow, viewMoreText } = useCarouselBreakpoints(
    VEHICLE_BODY_SELECTOR,
    currentLayout.id,
    total_body_types,
  );

  return (
    <div className={namespace}>
      <ul>
        {values
          .filter((_, index) => index < slidesToShow)
          .map((value) => (
            <VehicleBodyItem key={value.id} value={value} image={image} />
          ))}
        {viewMoreText && <VehicleBodyViewMore modal={modal} text={viewMoreText} />}
      </ul>
    </div>
  );
};

VehicleBodySelector.propTypes = {
  image: string.isRequired,
  modal: shape().isRequired,
  total_body_types: number.isRequired,
  values: arrayOf().isRequired,
};

const VehicleBodySelectorContainer = () => {
  const vehicleBodySelector = useVehicleBodySelector();

  return vehicleBodySelector ? <VehicleBodySelector {...vehicleBodySelector} /> : null;
};

export default VehicleBodySelectorContainer;
export { VehicleBodySelector };
