import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';

import HighlightLabel from '../highlight/highlight-label';

const namespace = 'ui-search-value-proposition';

/* eslint-disable react/no-array-index-key */
const ValueProposition = ({ className, id, pills, styled_icon: icon }) => (
  <div className={classnames(namespace, className, { [`${namespace}--${id}`]: !!id })}>
    <div className={`${namespace}__pills`}>
      {pills.map((pill, index) => (
        <HighlightLabel key={`${pill.type}-${index}`} className={`${namespace}__pill`} icon={icon} {...pill} />
      ))}
    </div>
  </div>
);

/* eslint-enable */
ValueProposition.propTypes = {
  className: string,
  id: string,
  pills: arrayOf(
    shape({
      type: string,
      icon: shape(),
      label: shape(),
    }),
  ).isRequired,
  styled_icon: shape({
    id: string,
    color: string,
  }),
};

ValueProposition.defaultProps = {
  id: null,
  className: null,
  styled_icon: { highlight_position: 'left' },
};

export default ValueProposition;
