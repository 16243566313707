import { useMemo, useEffect, useState } from 'react';

import { useSearch } from '../../../hooks/context';
import useMapConfig from './use-map-config';
import { trackClickEvent } from '../track';
import { filterSearch } from '../../context/filters/helpers';
import getHistory from '../../../lib/history';

const MAX_VISIBLE_ITEMS = 50;
const PAGINATION_CHANGE = 'pagination_change';

const getInitialPage = (clientToServerPageRatio, currentPage) => {
  const prevPage = currentPage - 1;
  const clientSidePrevPages = prevPage * clientToServerPageRatio;
  const currentClientSidePage = clientSidePrevPages + 1;

  return currentClientSidePage;
};

const usePagination = ({ overrideHashPage = false, isLoadingResults, results }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [localPage, setLocalPage] = useState(overrideHashPage ? 1 : null);
  const { currentHashPage } = useSearch();
  const {
    pagination: { currentPage, pageCount, previousPage = {}, nextPage = {}, showPagination },
  } = useMapConfig();
  const history = getHistory();
  const clientToServerPageRatio = Math.ceil(results.length / MAX_VISIBLE_ITEMS);
  const serverPageCount = overrideHashPage ? 1 : pageCount;
  const lastPage = clientToServerPageRatio * serverPageCount;
  const pageFromHash = Math.min(currentHashPage, currentPage * clientToServerPageRatio);
  const page = localPage || pageFromHash || getInitialPage(clientToServerPageRatio, currentPage);
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const prevPageIndex = (page - 1) % clientToServerPageRatio;
  const prevPageHasRequest = prevPageIndex === 0;
  const currentPageIndex = page % clientToServerPageRatio;
  const nextPageHasRequest = currentPageIndex === 0;

  const offset = prevPageIndex * MAX_VISIBLE_ITEMS;
  const paginatedResults = useMemo(() => results.slice(offset, offset + MAX_VISIBLE_ITEMS), [results, offset]);

  const showServerPagination = overrideHashPage ? false : showPagination;

  const createChangePageHandler = (pageChange, url, hasPendingRequest) => (event) => {
    event.preventDefault();

    trackClickEvent(null, track.melidata);

    const newPage = page + pageChange;
    const nextUrl = hasPendingRequest ? url : history.location.pathname;

    if (overrideHashPage) {
      return setLocalPage(newPage);
    }

    filterSearch(nextUrl, PAGINATION_CHANGE, newPage)();

    return setLocalPage(null);
  };

  const handleNextPage = createChangePageHandler(1, nextPage.url, nextPageHasRequest);
  const handlePrevPage = createChangePageHandler(-1, previousPage.url, prevPageHasRequest);

  useEffect(() => {
    setLocalPage(overrideHashPage ? 1 : null);
  }, [overrideHashPage, setLocalPage]);

  // We force a loading state while we paginate and set it to false when the
  // component mounts. After the first mount, we sync our local loading state
  // with the global one.
  useEffect(() => {
    setIsLoading(isLoadingResults);
  }, [isLoadingResults]);

  // If we can't calculate a page correctly we return unpaginated results.
  if (!page || page < 0) {
    return {
      isLoading,
      paginatedResults: results,
      showPagination: false,
    };
  }

  return {
    isLoading,
    paginatedResults,
    currentPage: page,
    nextPage: {
      href: nextPageHasRequest ? nextPage.url : null,
      onClick: handleNextPage,
      show: page < lastPage,
      title: nextPage.value,
    },
    previousPage: {
      href: prevPageHasRequest ? previousPage.url : null,
      onClick: handlePrevPage,
      show: page !== 1,
      title: previousPage.value,
    },
    showPagination: showServerPagination || results.length > MAX_VISIBLE_ITEMS,
    offset,
  };
};

export default usePagination;
