const addToCartClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});

const CART_PATHS = {
  getCartItems: '/api/search/cartShops/get-items',
  addToCart: '/api/search/cartShops/add-item',
  updateCartItem: '/api/search/cartShops/update-item',
  deleteCartItem: '/api/search/cartShops/delete-item',
};

class CarShopsApiService {
  static addToCart(id, quantity, variation, userZipCode) {
    return addToCartClient
      .post(CART_PATHS.addToCart, {
        data: { id, quantity, userZipCode, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => response);
  }

  static updateCartItem(id, quantity, variation, userZipCode) {
    return addToCartClient
      .put(CART_PATHS.updateCartItem, {
        data: { id, quantity, userZipCode, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => response);
  }

  // quantity is added to keep the same signature as the other methods
  static deleteCartItem(id, quantity, variation, userZipCode) {
    return addToCartClient
      .put(CART_PATHS.deleteCartItem, {
        data: { id, userZipCode, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => response);
  }

  static getCartItems() {
    return addToCartClient
      .put(CART_PATHS.getCartItems, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then((response) => response);
  }
}

module.exports = CarShopsApiService;
