import { bool, shape, string } from 'prop-types';

const FILTER_BASE_PROPS = {
  highlight: shape(),
  label: shape(),
  subtitle: shape(),
  title: shape(),
  tooltip: shape(),
  hasHtml: bool,
};

const FILTER_EXTENDED_PROPS = {
  ...FILTER_BASE_PROPS,
  switch: shape({ is_on: bool }),
  url: string,
};

export { FILTER_BASE_PROPS, FILTER_EXTENDED_PROPS };
