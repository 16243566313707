const alertsRestClient = require('../client/restclient')({
  timeout: 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { ALERTS_SERVICE_BASE_PATH } = require('../constants/paths');

class Service {
  static addAlert(alertCreationData) {
    return alertsRestClient
      .post(`${ALERTS_SERVICE_BASE_PATH}`, {
        data: {
          alertCreationData,
        },
      })
      .then((response) => response.data);
  }
}

module.exports = Service;
