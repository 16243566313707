import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';

const NAMESPACE = `${PACKAGE_NAMESPACE}-icon ${PACKAGE_NAMESPACE}-icon--like`;

const ICON_ID = 'ic_like';

/* eslint-disable max-len */
const IconLike = ({ className, description }) => (
  <svg
    className={classnames(NAMESPACE, className)}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title={description}
    aria-label={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);


IconLike.propTypes = {
  className: string,
  description: string,
};

IconLike.defaultProps = {
  className: null,
};

export default React.memo(IconLike);
