import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--meli';

const IconMeli = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="meli.svg" alt="" />
  </div>
);

IconMeli.propTypes = {
  className: string,
};

IconMeli.defaultProps = {
  className: null,
};

export default IconMeli;
