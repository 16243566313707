/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { arrayOf, elementType, shape, string } from 'prop-types';

import cx from 'classnames';
import get from 'lodash/get';

import { markWithCookie } from '../../../lib/cookies';
import { useStaticProps } from '../../context/static-props';
import { useIsFade } from '../../context/layout-fade';
import MapMobile from '../../map/map-container.mobile';
import MapDesktop from '../../map/map-container.desktop';

const namespace = 'ui-search-layout';

const COMPONENTS = {
  mobile: MapMobile,
  desktop: MapDesktop,
};

const COOKIE_EXPIRATION_DATE = 60 * 60 * 24 * 7; // 7 days

const MapLayout = ({ itemComponent, layout, results }) => {
  const { deviceType, visualId, hotjar } = useStaticProps();
  const isFade = useIsFade();
  const MapComponent = COMPONENTS[deviceType] || COMPONENTS.desktop;

  useEffect(() => {
    const cookies = get(hotjar, 'extraData.clientSideConfig.cookies', []);

    cookies.forEach((cookieName) => {
      markWithCookie(cookieName, COOKIE_EXPIRATION_DATE);
    });
  }, []);

  return (
    <MapComponent
      className={cx(
        namespace,
        { [`${namespace}--${layout}`]: layout },
        { [`${namespace}--${visualId}`]: visualId },
        { [`${namespace}--fade`]: isFade },
      )}
      itemComponent={itemComponent}
      results={results}
    />
  );
};

MapLayout.propTypes = {
  itemComponent: elementType.isRequired,
  layout: string.isRequired,
  results: arrayOf(shape()),
};

MapLayout.defaultProps = {
  results: [],
};

export default MapLayout;
