/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { shape, arrayOf, string } from 'prop-types';

import ComponentFactory from './component-factory';

const WrapperEshops = ({ children, siteId }) => {
  const componentCreation = (comp) => <ComponentFactory {...comp} key={comp.id} siteId={siteId} />;
  const componentWrapper = children?.map((component) => componentCreation(component));

  return componentWrapper || <></>;
};

WrapperEshops.propTypes = {
  children: arrayOf(shape).isRequired,
  siteId: string.isRequired,
};

export default WrapperEshops;
