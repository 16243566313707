import React from 'react';
import { arrayOf, elementType, func, number, shape } from 'prop-types';

import classnames from 'classnames';

import { namespace } from '../../constants';

const IMAGE_OPTIONS = { lazyload: 'on', mounted: true };

const ItemsMap = ({ results, ItemComponent, onItemHover, offset }) => (
  <div>
    {results.map((item, index) => (
      <div
        id={item.id}
        className={classnames(namespace, `${namespace}__item`, {
          [`${namespace}__item--development`]: item.is_development,
        })}
        onMouseEnter={() => onItemHover(index + offset)}
        onMouseLeave={() => onItemHover(null)}
        key={item.id}
      >
        <ItemComponent item={item} options={IMAGE_OPTIONS} />
      </div>
    ))}
  </div>
);

ItemsMap.propTypes = {
  ItemComponent: elementType.isRequired,
  offset: number,
  results: arrayOf(shape()),
  onItemHover: func.isRequired,
};

ItemsMap.defaultProps = {
  results: [],
  offset: 0,
};

export default ItemsMap;
