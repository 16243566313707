/* eslint-disable max-len */
import React from 'react';

import { string } from 'prop-types';
import classnames from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';

const NAMESPACE = `${PACKAGE_NAMESPACE}-icon ${PACKAGE_NAMESPACE}-icon--trade_in`;

const ICON_ID = 'poly_trade_in';

const IconTrade = ({ className, description }) => (
  <svg
    className={classnames(NAMESPACE, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);


IconTrade.propTypes = {
  className: string,
  description: string,
};

IconTrade.defaultProps = {
  className: null,
};

export default React.memo(IconTrade);
