import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'ic_full_super';
const namespace = 'ui-search-icon ui-search-icon--full-super';

const FullSuper = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 151 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Full Súper"
    role="img"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

FullSuper.propTypes = {
  className: string,
};

const IconApplMemo = React.memo(FullSuper);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
