import get from 'lodash/get';

import { useSearch } from '../../hooks/context';

const useTopKeywords = () => {
  const { top_keywords } = useSearch();

  return get(top_keywords, 'keywords', false) ? top_keywords : null;
};

export default useTopKeywords;
