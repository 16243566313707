/* eslint-disable react/prop-types */
import React from 'react';

import { loadable } from 'nordic/lazy';

const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../../../components-v2/intervention/filter-intervention/desktop'
  ),
);

const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../../../components-v2/intervention/carousel/carousel-intervention'
  ),
);

const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shoppingcart" */
    '../../shopping-cart'
  ),
);

const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../compats/compats-intervention'
  ),
);

const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../billboard/billboard-intervention.desktop'
  ),
);

const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../meliplay/stack/meliplay-intervention.desktop'
  ),
);

const VehicleBodySelectorIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-vehicle-body-selector" */
    '../../vehicle-body-selector/vehicle-body-selector-intervention.desktop'
  ),
);

const ZrpDisclaimerIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-zrp-disclaimer" */
    '../../zrp-disclaimer/zrp-disclaimer'
  ),
);

const AndesMessageIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-andes-messsage" */
    '../../andes-message-intervention/andes-message-intervention'
  ),
);

const MoreInfoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-more-info" */
    '../../more-info-intervention/more-info-intervention.desktop'
  ),
);

const QuickAccessIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-quick-access-intervention" */
    '../../quick-access-intervention/quick-access-intervention.desktop'
  ),
);

// Reminder: When creating a new intervention component, the top hierarchical node must be a `div` so as not to generate a mismatch error.

const renderInterventionsDesktop = {
  FILTER_INTERVENTION: ({ item, deviceType }) => <FilterIntervention intervention={item} deviceType={deviceType} />,
  CAROUSEL_INTERVENTION: ({ item: { content } }) => <CarouselIntervention intervention={content} />,
  CART_INTERVENTION: ({ item }) => <ShoppingCartIntervention intervention={item} />,
  COMPATS_INTERVENTION: ({ item }) => <CompatsIntervention intervention={item} />,
  BILLBOARD_INTERVENTION: ({ item }) => <BillboardIntervention intervention={item} />,
  MELIPLAY_INTERVENTION: ({ item, position }) => <MeliPlayIntervention intervention={item} position={position} />,
  VEHICLE_BODY_SELECTOR_INTERVENTION: ({ item }) => <VehicleBodySelectorIntervention intervention={item} />,
  ZRP_DISCLAIMER_COMPONENT: ({ item }) => <ZrpDisclaimerIntervention intervention={item} />,
  ANDES_MESSAGE_INTERVENTION: ({ item }) => <AndesMessageIntervention intervention={item} />,
  PHARMA_INTERVENTION: ({ item }) => <MoreInfoIntervention intervention={item} />,
  QUICK_ACCESS_INTERVENTION: ({ item }) => <QuickAccessIntervention intervention={item} />,
};

export { renderInterventionsDesktop };
