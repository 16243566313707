import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import IconFull from '../../icons/full';

const namespace = 'ui-search-item__fulfillment';

const Full = ({ className = null }) => (
  <span className={classnames(namespace, className)}>
    <IconFull />
  </span>
);

Full.propTypes = {
  className: string,
};

export default Full;
