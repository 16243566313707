import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

const GroupedComponent = ({ namespace, container, components, factory }) => {
  const elementName = container ? `${container.toLowerCase()}-container` : 'container';

  return (
    <div className={`${namespace}__${elementName}`}>
      {components.map((component) => React.createElement(factory, { ...component, key: component.id }))}
    </div>
  );
};

GroupedComponent.propTypes = {
  components: arrayOf(shape).isRequired,
  container: string,
  factory: func.isRequired,
  namespace: string.isRequired,
};

GroupedComponent.defaultProps = {
  container: null,
};

export default GroupedComponent;
