import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'full_super_small';
const namespace = 'ui-search-icon ui-search-icon--full-super-small';

const FullSuperSmall = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    height="15px"
    viewBox="0 0 91 15"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Full Súper"
    role="img"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

FullSuperSmall.propTypes = {
  className: string,
};

const IconApplMemo = React.memo(FullSuperSmall);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
