import { useSearch } from '../../hooks/context';
import { useCurrentLayout } from '../context/layout';

const useResults = () => {
  const { results, polycard_context, layout_options } = useSearch();
  const currentLayout = useCurrentLayout();

  return { currentLayout, results, polycard_context, layout_options };
};

export default useResults;
