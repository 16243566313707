import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classnames from 'classnames';

import ValueProposition from './value-proposition';

const namespace = 'ui-search-value-propositions-list';

/* eslint-disable react/no-array-index-key */
const ValuePropositions = ({ className, propositions }) => (
  <div className={classnames(namespace, className)}>
    {propositions.map((proposition, index) => (
      <ValueProposition key={`${proposition.id}-${index}`} {...proposition} />
    ))}
  </div>
);

/* eslint-enable */
ValuePropositions.propTypes = {
  className: string,
  propositions: arrayOf(shape({ id: string.isRequired })).isRequired,
};

ValueProposition.defaultProps = {
  className: '',
};

export default ValuePropositions;
