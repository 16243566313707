import React from 'react';

import IconFull from '../../../../../icons/full';
import RenderValueLinkWithIcon from './components/value-link-with-icon/value-link-with-icon';
import RenderValueLinkWithText from './components/value-link-with-text/value-link-with-text';
import { valueLinkPropTypes } from '../../propTypes';

const getValueIcon = (id, IDS, FULL_ICON) => {
  switch (id) {
    case IDS.FULFILLMENT:
      return [IconFull, FULL_ICON];

    default:
      return [null, null];
  }
};

const shouldKeepLayout = (filterId, IDS) => {
  switch (filterId) {
    case IDS.RELATED_SEARCHES:
      return false;

    default:
      return true;
  }
};

const shouldKeepParams = (filterId, IDS) => {
  switch (filterId) {
    case IDS.RELATED_SEARCHES:
      return false;

    default:
      return true;
  }
};

const ValueLink = ({
  namespace,
  id,
  name,
  results,
  url,
  tooltip,
  filterId,
  tracks,
  ids,
  modifier,
  accessibilityText,
}) => {
  const [Icon, mod] = getValueIcon(id, ids, modifier);
  const linkConfig = {
    keepLayout: shouldKeepLayout(filterId, ids),
    isInternal: shouldKeepParams(filterId, ids),
    tracks,
  };

  if (Icon) {
    return (
      <RenderValueLinkWithIcon
        namespace={namespace}
        Icon={Icon}
        results={results}
        url={url}
        linkConfig={linkConfig}
        modifier={mod}
        name={name}
        accessibilityText={accessibilityText}
      />
    );
  }

  return (
    <RenderValueLinkWithText
      namespace={namespace}
      name={name}
      results={results}
      url={url}
      linkConfig={linkConfig}
      tooltip={tooltip}
      filterId={filterId}
      accessibilityText={accessibilityText}
    />
  );
};

ValueLink.propTypes = {
  ...valueLinkPropTypes,
};

export default ValueLink;
