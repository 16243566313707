import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--close';

const ICON_ID = 'poly_close';

const IconClose = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconClose.propTypes = {
  className: string,
};

IconClose.defaultProps = {
  className: null,
};

export default React.memo(IconClose);
