import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--badge';

const ICON_ID = 'ic_badge';

const IconBadge = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconBadge.propTypes = {
  className: string,
};

IconBadge.defaultProps = {
  className: null,
};

export default IconBadge;
