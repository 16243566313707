import React from 'react';
import { arrayOf, shape, bool } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../hooks/context';
import ComponentFactory from '../factory/factory.desktop';

const namespace = 'ui-search-sidebar';

const Sidebar = ({ components, multiNeighborhood = false }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <aside
      className={classnames(namespace, {
        [`${namespace}--h1-multi-neighborhood`]: multiNeighborhood,
        shops__sidebar: isShops,
      })}
    >
      {components?.map((component) => (
        <ComponentFactory {...component} key={component.id} namespace={namespace} type={component?.type} />
      ))}
    </aside>
  );
};

Sidebar.propTypes = {
  components: arrayOf(shape).isRequired,
  multiNeighborhood: bool,
};

const hasMultiNeighborhoods = ({ filters }) => !!filters.find((it) => it.id === 'neighborhood' && it.values.length > 1);

const SidebarContainer = () => {
  const { sidebar } = useSearch();

  if (!sidebar) {
    return null;
  }

  const { components } = sidebar;
  const appliedFilters = components?.find((it) => it.type === 'APPLIED_FILTERS');
  const multiNeighborhood = appliedFilters && hasMultiNeighborhoods(appliedFilters);

  return <Sidebar {...sidebar} multiNeighborhood={multiNeighborhood} />;
};

export default SidebarContainer;
export { Sidebar };
