import React from 'react';
import { shape, string, arrayOf } from 'prop-types';

import classnames from 'classnames';

import { namespace } from '../../constants';

const WHITE_SPACE = '\xa0';
const ITEM_SEPARATOR = '·';

const Attributes = ({ className, attributes, separator }) => (
  <div className={classnames(className, `${namespace}__content-attributes`)}>
    {attributes.map(({ label }) => label).join(`${WHITE_SPACE}${separator}${WHITE_SPACE}`)}
  </div>
);

Attributes.propTypes = {
  attributes: arrayOf(
    shape({
      label: string.isRequired,
    }),
  ),
  className: string,
  separator: string,
};

Attributes.defaultProps = {
  attributes: [],
  className: null,
  separator: ITEM_SEPARATOR,
};

export { Attributes };
