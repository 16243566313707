import React from 'react';
import { string, shape, number } from 'prop-types';

import classnames from 'classnames';

import IconMeli from '../../icons/meli';
import GiftIcon from '../../icons/gift';
import IconAhora30 from '../../icons/ahora30';
import BlackFriday from '../../icons/black-friday';
import HotSale from '../../icons/hot-sale';
import IconVirtualTour from '../../icons/virtual-tour';
import MeliPlusIcon from '../../icons/meli-plus';
import IconCartVolumeDiscount from '../../icons/cart-volume-discount';
import IconMeliChoice from '../../icons/melichoice';
import { getStyleProps } from '../../../lib/style-props';
import StyledLabelWrapper from '../../styled-label/styled-label';
import ComposedLabel from '../../composed-label/composed-label';

const namespace = 'ui-search-item__highlight-label';

const renderIcon = (icon) => {
  switch (icon.id) {
    case 'black_friday_icon':
      return <BlackFriday {...icon} />;
    case 'ic_gift':
      return <GiftIcon {...icon} />;
    case 'ic_ahora30':
      return <IconAhora30 {...icon} />;
    case 'ic_hot_sale':
      return <HotSale {...icon} />;
    case 'meli_icon':
      return <IconMeli {...icon} />;
    case 'virtual_tour_icon':
      return <IconVirtualTour {...icon} />;
    case 'meli_plus_icon':
      return <MeliPlusIcon {...icon} />;
    case 'cart_icon':
      return <IconCartVolumeDiscount {...icon} />;
    case 'melichoice_icon':
      return <IconMeliChoice {...icon} />;

    default:
      return null;
  }
};

const renderComponent = (label, secondary_label, htmlForIDValue) => {
  const { values } = label;

  if (values) {
    return (
      <div>
        <ComposedLabel className={`${namespace}--composed-title`} {...label} />
        {secondary_label && <StyledLabelWrapper className={`${namespace}--secondary-label`} {...secondary_label} />}
      </div>
    );
  }

  return <StyledLabelWrapper className={`${namespace}__text`} htmlForIDValue={htmlForIDValue} {...label} />;
};

const HighlightLabel = ({
  className = null,
  type = null,
  label = null,
  secondary_label = null,
  htmlForIDValue = '',
  icon: initialIcon,
  ...props
}) => {
  const icon = initialIcon || { highlight_position: 'left' };
  const highlight_position = icon?.highlight_position || 'left';

  return (
    <div
      className={classnames(
        namespace,
        className,
        { [`${namespace}--${type}`]: !!type },
        { [`${namespace}--no-background`]: !props.background },
      )}
      style={getStyleProps(props)}
    >
      <div className={`${namespace}__container`}>
        {icon && highlight_position === 'left' && renderIcon(icon)}
        {label && renderComponent(label, secondary_label, htmlForIDValue)}
        {icon && highlight_position === 'right' && renderIcon(icon)}
      </div>
    </div>
  );
};

HighlightLabel.propTypes = {
  background: string,
  className: string,
  htmlForIDValue: string,
  icon: shape({
    id: string.isRequired,
    color: string,
    highlight_position: string,
  }),
  label: shape({
    text: string.isRequired,
    color: string,
    background: string,
    size: number,
    weight: number,
    style: string,
  }),
  secondary_label: shape({
    text: string.isRequired,
  }),
  type: string,
};

export default HighlightLabel;
