import { WrapperBase as BrandAds } from '@ads-placements-frontend/brand-wrapper';
import { WrapperBase as BrandAdsShowroom } from '@ads-placements-frontend/brand-wrapper-showroom';

import { SHOWROOM_LAYOUT } from './constants';

const getTemplateTag = (config) => config?.content?.fast_loading?.items?.[0]?.template?.tag;

const getBrandsAdsLayout = (config) => {
  const layout = getTemplateTag(config);

  if (layout === SHOWROOM_LAYOUT) {
    return BrandAdsShowroom;
  }

  return BrandAds;
};

export default getBrandsAdsLayout;
