import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-filter-colors';

const FiltersColors = ({ id = '', className = '' }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)} />
);

FiltersColors.propTypes = {
  className: string,
  id: string,
};

export default FiltersColors;
