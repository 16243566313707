import { useSearch } from '../../hooks/context';
import { useStaticProps } from '../context/static-props';

const useBillboard = () => {
  const { lowEnd, billboardState } = useStaticProps();
  const { billboard, bookmark } = useSearch();

  return { billboard, lowEnd, billboardState, bookmark };
};

export default useBillboard;
