const restclient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 1300 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { SEARCH_URL_BASE_PATH } = require('../constants/paths');

class Service {
  static getSearchUrl(filterObject, siteId, visualId) {
    const params = {
      filterObject: JSON.stringify(filterObject),
      siteId,
      visualId,
    };

    return restclient
      .get(`${SEARCH_URL_BASE_PATH}/search_url`, {
        params,
      })
      .then((response) => response);
  }

  static readSearchUrl(url, siteId) {
    return restclient
      .get(`${SEARCH_URL_BASE_PATH}/searchUrlReader?url=${url}&siteId=${siteId}`)
      .then((response) => response);
  }
}

module.exports = Service;
module.exports.restclient = restclient;
