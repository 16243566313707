import React, { useMemo } from 'react';
import { arrayOf, shape, number, func } from 'prop-types';

import { useStaticProps } from '../context/static-props';
import { getIconConfig } from './icons';
import { Marker, useClusters, toBoundsArray, DEFAULT_CLUSTER_OPTIONS } from './lib';

// Ensure all markers have a z index less than zero to avoid collisions
// with other UI elements
const ZINDEX_OFFSET = 10;
const ZINDEX_VISIBILITY_FACTOR = -1;

const MarkerGroup = ({
  bounds,
  map,
  markers,
  onMarkerHover,
  onMarkerSelect,
  selectedCluster,
  selectedMarker,
  zoom,
}) => {
  const { deviceType } = useStaticProps();
  const memoizedBounds = useMemo(() => (bounds ? toBoundsArray(bounds) : null), [bounds]);
  const { clusters, checkSelectionStatus } = useClusters({
    markers,
    bounds: memoizedBounds,
    zoom: Math.min(zoom, DEFAULT_CLUSTER_OPTIONS.maxZoom),
    options: {},
  });

  return clusters.map(({ id, lat, lng, markerIndex, clusterResults }, clusterIndex) => {
    const [{ is_development: isDevelopment }] = clusterResults;
    const isSelected =
      checkSelectionStatus(selectedMarker, clusterIndex) || checkSelectionStatus(selectedCluster, clusterIndex);
    const markerKey = `${id}-${clusterIndex}`;
    const indexToNotify = isSelected ? selectedMarker : markerIndex;

    const onMarkerClick = () => onMarkerSelect(indexToNotify, clusterResults);
    const handleMarkerHover = () => onMarkerHover(indexToNotify, clusterResults);
    const handleMarkerHoverOut = () => onMarkerHover(null, null);

    return (
      <Marker
        bringToFront={isSelected}
        key={markerKey}
        lat={lat}
        lng={lng}
        onClick={onMarkerClick}
        map={map}
        {...getIconConfig({
          clusterSize: clusterResults.length,
          deviceType,
          isDevelopment,
          isSelected,
        })}
        onCleanup={handleMarkerHoverOut}
        onMouseOver={handleMarkerHover}
        onMouseOut={handleMarkerHoverOut}
        zIndex={(clusterIndex + ZINDEX_OFFSET) * ZINDEX_VISIBILITY_FACTOR}
      />
    );
  });
};

MarkerGroup.propTypes = {
  bounds: shape({ top: number, right: number, bottom: number, left: number }),
  map: shape(),
  markers: arrayOf(shape()),
  selectedCluster: number,
  selectedMarker: number,
  zoom: number,
  onMarkerHover: func,
  onMarkerSelect: func,
};

MarkerGroup.defaultProps = {
  bounds: null,
  map: undefined,
  markers: [],
  selectedCluster: null,
  selectedMarker: null,
  zoom: null,
  onMarkerHover: () => undefined,
  onMarkerSelect: () => undefined,
};

export default MarkerGroup;
