import React from 'react';
import { string, shape } from 'prop-types';

import StyledLabelWrapper from '../../styled-label/styled-label';

const namespace = 'ui-search-item__promise-message';

const PromiseMessage = ({ manufacturing_time }) => {
  const { font_size: size, ...rest } = manufacturing_time;

  return manufacturing_time ? <StyledLabelWrapper className={namespace} size={size} {...rest} /> : null;
};

PromiseMessage.propTypes = {
  manufacturing_time: shape({
    id: string,
    text: string,
    color: string,
    font_size: string,
    font_weight: string,
  }).isRequired,
};

PromiseMessage.defaultProps = {
  manufacturing_time: null,
};

export default PromiseMessage;
