import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--bookmark';

const IconBookmark = ({ className = null }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
    <use href="#poly_bookmark" />
  </svg>
);

IconBookmark.propTypes = {
  className: string,
};

export default IconBookmark;
