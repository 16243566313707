import { trackEvent as analyticsTrackEvent } from './analytics';
import { trackEvent } from './melidata';

function handleClickTrackEvents(action) {
  return (event) => {
    event.preventDefault();

    const { href } = event.currentTarget;

    if (action.tracks) {
      if (action.tracks.analytics_track) {
        analyticsTrackEvent(action.tracks.analytics_track);
      }

      trackEvent(action.tracks.melidata_track);
    }

    window.location.href = href;
  };
}

export { handleClickTrackEvents };
