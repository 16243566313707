import React from 'react';
import { string, number } from 'prop-types';

import classnames from 'classnames';

import Price from '../price';

const namespace = 'ui-search-item__pds-best-price';

const PdsBestPrice = ({ className, amount, currency_id }) => (
  <Price
    amount={amount}
    currency_id={currency_id}
    size="x-tiny"
    className={classnames(namespace, className, 'ui-search-color--black')}
    showDecimals={false}
  />
);

PdsBestPrice.propTypes = {
  amount: number.isRequired,
  className: string,
  currency_id: string.isRequired,
};

PdsBestPrice.defaultProps = {
  className: null,
};

export default PdsBestPrice;
