import React from 'react';
import { bool, string } from 'prop-types';

import cx from 'classnames';
import { Pill } from '@andes/badge';
import { ProgressIndicatorCircular } from '@andes/progress-indicator-circular';

import useMapConfig from '../hooks/use-map-config';

const namespace = 'ui-search-map-header';

const Header = ({ badgeText, loadingText, isLoadingResults }) => (
  <div className={`${namespace}__container`}>
    <Pill
      className={cx(`${namespace}__pill`, {
        [`${namespace}__pill--loading`]: isLoadingResults,
      })}
      color="gray"
      size="small"
    >
      {isLoadingResults && (
        <ProgressIndicatorCircular className={`${namespace}__pill-progress`} modifier="inline" size="xsmall" />
      )}
      <span
        className={cx(`${namespace}__pill-text`, {
          [`${namespace}__pill-text--loading`]: isLoadingResults,
        })}
      >
        {isLoadingResults ? loadingText : badgeText}
      </span>
    </Pill>
  </div>
);

Header.propTypes = {
  badgeText: string,
  isLoadingResults: bool,
  loadingText: string,
};

Header.defaultProps = {
  badgeText: '',
  loadingText: '',
  isLoadingResults: false,
};

const HeaderContainer = ({ isLoadingResults }) => {
  const { badgeText, loadingText } = useMapConfig();

  return <Header badgeText={badgeText} loadingText={loadingText} isLoadingResults={isLoadingResults} />;
};

HeaderContainer.propTypes = {
  isLoadingResults: bool,
};

HeaderContainer.defaultProps = {
  isLoadingResults: false,
};

export default HeaderContainer;
export { Header };
