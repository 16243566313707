import React from 'react';
import { string } from 'prop-types';

import EmptyState from 'ui-library_ml/ui/empty-state';

import Icon from '../../icons/map/loader-error';

const namespace = 'ui-search-map-zrp';

const Zrp = ({ description, title }) => (
  <EmptyState
    className={namespace}
    description={description}
    icon={<Icon className={`${namespace}__icon`} />}
    title={title}
  />
);

Zrp.propTypes = {
  description: string,
  title: string,
};

Zrp.defaultProps = {
  description: '',
  title: '',
};

export default Zrp;
