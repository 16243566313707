import replaceObjectProps from './replaceObjectProps';
import { snakeCaseToCamelCase } from './utilities';

export const formatStyles = (styles) => {
  if (!styles) {
    return null;
  }

  const formattedStyles = replaceObjectProps(styles, '_', snakeCaseToCamelCase);

  if (formattedStyles.values) {
    formattedStyles.values = formattedStyles.values.map((value) => ({
      ...value,
      styles: value.styles ? replaceObjectProps(value.styles, '_', snakeCaseToCamelCase) : value.styles,
    }));
  }

  return formattedStyles;
};
