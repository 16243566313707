import React, { useState, useEffect } from 'react';
import { shape, arrayOf, string, number, func } from 'prop-types';

import { Image } from 'nordic/image';
import { VisuallyHidden } from '@andes/common';
import { Text } from '@andes/typography';
import CarouselSnapped, { CarouselSnappedSlide } from '@andes/carousel-snapped';
import ArrowLeft from '@andes/icons/ArrowLeft24';

import { namespace as baseNamespace } from '../../layout/renderer/constants';
import { normalizePictureData } from '../../layout/renderer/utils';
import useMapConfig from '../hooks/use-map-config';
import { trackEvent } from '../../../lib/melidata';

const namespace = `${baseNamespace}__picture-swipe__carousel`;

const PicturesCarousel = ({ item, pictures, options, onClose }) => {
  const { backButtonText, picturesListText, track } = useMapConfig();
  const [selectedPicture, setSelectedPicture] = useState(1);
  const { title, pictures_qty } = item;

  useEffect(() => {
    if (track.openMobilePicturesCarousel.melidata?.melidata_track) {
      trackEvent({
        path: track.openMobilePicturesCarousel.melidata.melidata_track.path,
        event_data: {
          item_id: item.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={namespace}>
      <div className={`${namespace}__toolbar`}>
        <button className={`${namespace}__toolbar__close-button`} type="button" onClick={onClose}>
          <VisuallyHidden>{backButtonText}</VisuallyHidden>
          <ArrowLeft color="white" />
        </button>
        <Text size="m" color="inverted" weight="semibold">
          {`${selectedPicture} / ${pictures_qty}`}
        </Text>
      </div>
      <CarouselSnapped
        srLabel={picturesListText}
        infinite
        pagination={false}
        arrows={false}
        strictBoundaries={false}
        afterChange={(newIndex) => setSelectedPicture(newIndex + 1)}
      >
        {pictures.map((itemPicture) => {
          const picture = normalizePictureData(itemPicture, title, options);

          return (
            <CarouselSnappedSlide key={picture.src}>
              <Image
                className={`${namespace}__picture`}
                alt={picture.title}
                src={picture.src}
                lazyload={picture.lazyload}
                lowEnd={picture.lowEnd}
                carousel
              />
            </CarouselSnappedSlide>
          );
        })}
      </CarouselSnapped>
    </div>
  );
};

PicturesCarousel.propTypes = {
  item: shape({
    id: string,
    title: string,
    pictures_qty: number,
  }).isRequired,
  options: shape({}).isRequired,
  pictures: arrayOf(shape({})).isRequired,
  onClose: func.isRequired,
};

export { PicturesCarousel };
