const getThousandSeparator = (siteId) => {
  switch (siteId) {
    case 'MCR':
    case 'MRD':
    case 'MGT':
    case 'MHN':
    case 'MLM':
    case 'MSV':
    case 'MPA':
      return ',';

    default:
      return '.';
  }
};

const getFormattedIntFraction = (price, separator) => {
  if (!price) {
    return null;
  }

  const rawFraction = Math.floor(price).toString();

  return rawFraction.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, (n) => `${n}${separator}`);
};

const getFormattedDecimalFraction = (price) => {
  if (!price) {
    return null;
  }

  const fixedStr = price.toFixed(2);

  return fixedStr.split('.')[1] === '00' ? null : fixedStr.split('.')[1];
};

module.exports = {
  getThousandSeparator,
  getFormattedIntFraction,
  getFormattedDecimalFraction,
};
