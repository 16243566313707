import React from 'react';
import classnames from 'classnames';
import { arrayOf, element, oneOf, oneOfType, shape, string } from 'prop-types';
import { NAMESPACE, NAMESPACE_CONTENT, NAMESPACE_WRAPPER } from './utils/constants';

const HIGHLIGHT_PARENT_TAG = {
  BUILDER: 'p',
  SINGLE: 'span',
};

const HighlightUI = ({ class_name, content, id, type='SINGLE', styles,accessibility_text }) => {
  const { [type]: ParentTag } = HIGHLIGHT_PARENT_TAG;

  return (
    <ParentTag className={classnames(class_name, NAMESPACE_WRAPPER, id)}>
      <span className={classnames(`${NAMESPACE_CONTENT}`)}>
        <span className={NAMESPACE} style={{ ...styles }} aria-label={accessibility_text || undefined}>
          { content }
        </span>
      </span>
    </ParentTag>
  );
};


HighlightUI.propTypes = {
  accessibility_text: string,
  class_name: string,
  content: oneOfType([string, arrayOf(element)]),
  id: string,
  styles: shape({}),
  type: oneOf(['BUILDER', 'SINGLE']),
};


export default React.memo(HighlightUI);
