import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-map-link';

const ICON_ID = 'icon_map_desktop';

const IconMap = ({ className }) => (
  <svg
    data-testid="link-map"
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 20"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconMap.propTypes = {
  className: string,
};

IconMap.defaultProps = {
  className: null,
};

export default React.memo(IconMap);
