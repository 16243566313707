import React from 'react';

import classnames from 'classnames';

import IconOnlineVisitRequest from '../../../../icons/online-visit-request';
import Group from '../../../../card/group/group';
import { CARD_DATA_GROUPS, GRID, STACK } from '../../constants';
import {
  renderTitle,
  renderOfficialStore,
  renderPriceGroup,
  renderAttributesGroup,
  renderHighlightGroup,
  renderMediaTagGroup,
  ContentSkeleton,
  truncateString,
  isLayoutGallery,
} from '../common';

const renderTitleGroup = (item, layout) => {
  const className = classnames({
    'ui-search-item__group--development-title': item.is_development,
    'ui-search-item__group--title-grid': isLayoutGallery(layout),
  });
  const itemSubtitle = item?.subtitles?.operation ? item.subtitles.operation : item.title;

  return (
    <Group noSeparator name={CARD_DATA_GROUPS.TITLE} className={className}>
      {itemSubtitle && (
        <span className={`ui-search-item__subtitle${isLayoutGallery(layout) ? '-grid' : ''}`}>{itemSubtitle}</span>
      )}
      {isLayoutGallery(layout) ? (
        <div className="ui-search-item__title-grid">
          {renderTitle(item.sub_title, layout, item.permalink, item.target, item.is_ad)}
        </div>
      ) : (
        renderTitle(item.sub_title, layout, item.permalink, item.target, item.is_ad)
      )}
    </Group>
  );
};

const renderLocationGroup = (item, options) =>
  item.location && (
    <Group noSeparator name={CARD_DATA_GROUPS.LOCATION}>
      <span className="ui-search-item__location">{item.location}</span>
      {renderOfficialStore(item.vertical, item.official_store, options)}
    </Group>
  );

const renderLocationGroupSingle = (item) =>
  item.location && (
    <div className="ui-search-item__location-container-grid">
      <span className="ui-search-item__location-label">{truncateString(item.location, 35)}</span>
    </div>
  );

const renderPossessionGroup = (item, layout) =>
  item.possession &&
  (isLayoutGallery(layout) ? (
    <div className="ui-search-item__possession-container-grid">
      <span className="ui-search-item__possession-label">{item.possession.replace(':', '')}</span>
    </div>
  ) : (
    <Group noSeparator name={CARD_DATA_GROUPS.POSSESSION}>
      <span className="ui-search-item__possession">{item.possession}</span>
    </Group>
  ));

const renderAvailableUnitsGroup = (item, layout) =>
  item.available_units &&
  (isLayoutGallery(layout) ? (
    <div className="ui-search-item__available-units-container-grid">
      <span className="ui-search-item__available-units-label">{item.available_units}</span>
    </div>
  ) : (
    <Group noSeparator name={CARD_DATA_GROUPS.AVAILABLE_UNITS}>
      <span className="ui-search-item__available-units">{item.available_units}</span>
    </Group>
  ));

const renderOnlineVisitRequestGroup = (item, layout) =>
  item.online_visit_request &&
  isLayoutGallery(layout) && (
    <div className="ui-search-item__online-visit-container-grid">
      <IconOnlineVisitRequest />
      <span className="ui-search-item__online-visit-label">{item.online_visit_request}</span>
    </div>
  );

function renderContent(item, options, layout) {
  switch (layout) {
    case GRID:
      return (
        <ContentSkeleton
          topComponents={[
            renderTitleGroup(item, layout),
            renderPriceGroup(item, options, layout),
            renderHighlightGroup({ highlight: item.highlight, layout }),
            renderAttributesGroup(item, layout),
            renderLocationGroupSingle(item, options),
            renderPossessionGroup(item, layout),
            renderAvailableUnitsGroup(item, layout),
            renderOnlineVisitRequestGroup(item, layout),
            renderOfficialStore(item.vertical, item.official_store, options, null, layout),
          ]}
        />
      );
    case STACK:

    // falls through
    default:
      return (
        <ContentSkeleton
          topComponents={[
            renderHighlightGroup(item),
            renderMediaTagGroup(item),
            renderPriceGroup(item, options),
            renderAttributesGroup(item),
            renderTitleGroup(item, layout),
            renderLocationGroup(item, options),
            renderPossessionGroup(item),
            renderAvailableUnitsGroup(item),
          ]}
        />
      );
  }
}

export default renderContent;
