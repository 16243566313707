import React from 'react';
import { bool, node, shape, string } from 'prop-types';

import { renderAddToCartComponent } from '../desktop-card-content/common';

const Wrapper = ({ adLabel, children, className, isCpg, isGrid, item, isMobile }) => {
  const isGridMobile = isCpg && isGrid && isMobile;

  return (
    <>
      {isGridMobile && children}
      <div className={className}>
        {!isGridMobile && children}
        {isCpg && renderAddToCartComponent(item)}
        {isCpg && adLabel}
      </div>
    </>
  );
};

Wrapper.propTypes = {
  adLabel: node,
  children: node.isRequired,
  className: string,
  isCpg: bool,
  isGrid: bool,
  isMobile: bool,
  item: shape(),
};

export default Wrapper;
