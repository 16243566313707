import { COMPONENT_TYPE } from '../constants';
import Label from '../components/label';
import Link from '../../link';
import Price from '../components/price';
import getIcon from '../../../components/icons/get-icon';

const { FN_ICON, JSX } = COMPONENT_TYPE;

const SOURCES = {
  LABEL: {
    Component: Label,
    kind: JSX,
  },
  ICON: {
    Component: getIcon,
    kind: FN_ICON,
  },
  PRICE: {
    Component: Price,
    kind: JSX,
  },
  LINK: {
    Component: Link,
    kind: JSX,
  },
};

export default SOURCES;
