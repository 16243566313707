const serialize = (pagination) =>
  pagination
    ? {
        nextPage: pagination.next_page,
        previousPage: pagination.previous_page,
        selectedPage: pagination.selected_page,
        paginationNodes: pagination.pagination_nodes_url,
        showPagination: pagination.show_pagination,
        pageCount: Number(pagination.page_count),
      }
    : null;

const findIndex = (paginationNodes, page) => paginationNodes.findIndex((node) => Number(node.value) === page);

const formatUrl = (paginationNodes, firstPage, lastPage) => (href, page) => {
  if (page < firstPage || page > lastPage) {
    return href;
  }

  const nodePagePosition = findIndex(paginationNodes, page);

  let url = href;

  if (paginationNodes[nodePagePosition]) {
    url = paginationNodes[nodePagePosition].url;
  } else {
    // TODO FIXME Agregar métrica Datadog
    const tempPage = paginationNodes[findIndex(paginationNodes, page - 1)];

    if (tempPage) {
      url = tempPage.url;
    }
  }

  return url;
};

export { serialize, formatUrl };
