import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'fulfillment';
const namespace = 'ui-search-icon ui-search-icon--full';

const IconFull = ({ className = null }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 100 32"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="full"
    role="img"
  >
    <use href="#poly_full" />
  </svg>
);

IconFull.propTypes = {
  className: string,
};

const IconApplMemo = React.memo(IconFull);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
