import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { PACKAGE_NAMESPACE } from '../utils/constants';

const NAMESPACE = `${PACKAGE_NAMESPACE}-icon ${PACKAGE_NAMESPACE}-icon--meli_plus`;

const ICON_ID = 'poly_meli_plus';

const IconMeliPlus = ({ className, description }) => (
  <svg
    className={classnames(NAMESPACE, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="14"
    viewBox="0 0 42 16"
    fill="none"
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
    <linearGradient
            id="paint0_linear_8761_4741"
            x1="0.00292969"
            y1="7.95271"
            x2="36.8258"
            y2="7.95271"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.298365" stopColor="#5A238D" />
            <stop offset="1" stopColor="#AA1192" />
    </linearGradient>
  </svg>
);


IconMeliPlus.propTypes = {
  className: string,
  description: string,
};

IconMeliPlus.defaultProps = {
  className: null,
};

export default React.memo(IconMeliPlus);
