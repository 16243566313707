import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--like';

const ICON_ID = 'ic_like';

const IconLike = ({ className, description }) => (
  <svg
    className={classnames(namespace, className)}
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title={description}
    aria-label={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconLike.propTypes = {
  className: string,
  description: string,
};

IconLike.defaultProps = {
  className: null,
};

export default React.memo(IconLike);
