import React from 'react';
import { string, arrayOf, shape, oneOf } from 'prop-types';

import SearchModal from '../sidebar/components/search-modal/search-modal.desktop';
import Modal from '../modal/modal.mobile';

const namespace = 'ui-search-vehicle-body-viewmore';

const VehicleBodyViewMore = ({ text, modal }) => (
  <li className={namespace}>
    <Modal
      modalClassName={`${namespace}__modal`}
      label={text}
      modalUrl="#filter"
      url=""
      type="small"
      withLink
      modalTitle={modal.title}
    >
      <SearchModal {...modal} values={modal.values.map((value) => ({ ...value, image: modal.image }))} />
    </Modal>
  </li>
);

VehicleBodyViewMore.propTypes = {
  modal: shape({
    id: string.isRequired,
    type: oneOf(['DEFAULT', 'WITH_SEARCH']),
    title: string.isRequired,
    image: string.isRequired,
    values: arrayOf(
      shape({
        id: string.isRequired,
        name: string.isRequired,
        url: string.isRequired,
      }),
    ),
  }),
  text: string.isRequired,
};

export default VehicleBodyViewMore;
