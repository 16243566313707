const PACKAGE_NAMESPACE = 'ui-pb';
const REGEX_LIB = {
  INTERPOLATION: /{([\s\S]+?)}/g,
  GET_VARIABLES: /\{([^{}]*)\}/g,
  GET_SNAKE_CASE: /_([a-zA-Z0-p])/g,
};

const TRANSLATION_TYPE = {
  BREAKPOINT: 'BREAK',
  HIGHLIGHT: 'HIGHLIGHT',
  ICON: 'ICON',
  LABEL: 'LABEL',
  PRICE: 'PRICE',
};

const COMPONENT_TYPE = {
  JSX: 'JSX',
  SINGLE_TAG: 'SINGLE_TAG',
  FN_ICON: 'FN_ICON',
};


export {
  COMPONENT_TYPE,
  PACKAGE_NAMESPACE,
  REGEX_LIB,
  TRANSLATION_TYPE,
};
