import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';

import classnames from 'classnames';

import Group from '../group/group';
import Separator from '../group/separator';
import ItemFull from '../fulfillment/fulfillment';
import ItemPromise from '../promise/promise';
import ItemHighlight from '../highlight/highlight-label';
import { CARD_DATA_GROUPS } from '../../layout/renderer/constants';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-item__shipping';

const ShippingGroup = ({ shipping: initialShipping, tags, contentWidth }) => {
  const shipping = initialShipping || {};
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const { label, highlight, promise } = shipping;
  const hasFulfillment = tags?.indexOf('fulfillment') > -1;
  const shouldShowPromise = promise && promise.id !== 'other';
  const showFirstRow = label || hasFulfillment || shouldShowPromise;
  const genericGroupClassName = 'ui-search-item__group';
  const shippingGroupClassName = classnames(`${genericGroupClassName}--${CARD_DATA_GROUPS.SHIPPING}`, {
    [`${genericGroupClassName}--promise`]: shouldShowPromise,
  });
  const shippingFreeTextClasses = classnames(namespace, `${namespace}--free`, {
    'shops__item-shipping-free': isShops,
  });

  if (!showFirstRow && !highlight) {
    return null;
  }

  return (
    <Group noSeparator className={shippingGroupClassName}>
      {showFirstRow && (
        <div className="ui-search-item__group__element--shipping">
          {label && !shouldShowPromise && <p className={shippingFreeTextClasses}>{label.text}</p>}
          {hasFulfillment && !shouldShowPromise && <ItemFull />}
          {shouldShowPromise && (
            <ItemPromise {...promise} hasFulfillment={hasFulfillment} contentWidth={contentWidth} />
          )}
        </div>
      )}
      {showFirstRow && highlight && <Separator />}
      {highlight && (
        <div>
          <ItemHighlight {...highlight} className={`${namespace}__highlight`} />
        </div>
      )}
    </Group>
  );
};

ShippingGroup.propTypes = {
  className: string,
  contentWidth: number.isRequired,
  shipping: shape({
    highlight: shape({}),
    label: shape({
      text: string.isRequired,
    }),
    promise: shape({}),
  }),
  tags: arrayOf(string).isRequired,
};

export default ShippingGroup;
