import React from 'react';
import { string, shape, bool } from 'prop-types';

import Advertising from '../../../advertising/advertising';

const namespace = 'ui-search-sidebar-placements';

const SkyBanner = (props) => {
  const { google_ad, segmentation, personal_data_ads_denied, slot_id, viewport_margin, title } = props;

  return (
    <div className={`${namespace}__display`}>
      <Advertising
        slotId={slot_id}
        googleAd={google_ad}
        personalDataAdsDenied={personal_data_ads_denied}
        viewportMargin={viewport_margin}
        segmentation={segmentation}
        title={title}
        namespace={namespace}
      />
    </div>
  );
};

SkyBanner.propTypes = {
  google_ad: shape({
    unit: string,
    size: string,
    ppid: string,
    enabled: bool,
  }).isRequired,
  personal_data_ads_denied: string.isRequired,
  segmentation: shape({
    custID: string,
    Posiciones: string,
    platform: string,
  }).isRequired,
  slot_id: string.isRequired,
  title: string,
  viewport_margin: string,
};

SkyBanner.defaultProps = {
  viewport_margin: null,
  title: null,
};

export default SkyBanner;
