import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--star';

const ICON_ID = 'ic_circled_star';

const IconStar = ({ className, description }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames(namespace, className)}
    aria-label={description}
    title={description}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconStar.propTypes = {
  className: string,
  description: string,
};

export default React.memo(IconStar);
