/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useRef } from 'react';
import { bool, func, node, number, shape } from 'prop-types';

import useInfowindowStyles from './use-infowindow-styles';
import helpers from './helpers';

const { getPointPosition } = helpers;

const InfowindowInstance = ({
  children,
  containerMeasurements,
  isMountedInRealDom,
  map,
  lat,
  lng,
  onMouseOut,
  onMouseOver,
}) => {
  const domNode = useRef(null);
  const point = useMemo(() => getPointPosition(map, { lat, lng }), [lat, lng]);
  const styles = useInfowindowStyles({
    containerMeasurements,
    domNode: isMountedInRealDom ? domNode.current : null,
    point,
  });

  return (
    <div style={styles} ref={domNode} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
      {children}
    </div>
  );
};

InfowindowInstance.propTypes = {
  children: node,
  containerMeasurements: shape({
    x: number,
    y: number,
    height: number,
    width: number,
  }).isRequired,
  isMountedInRealDom: bool,
  lat: number.isRequired,
  lng: number.isRequired,
  map: shape().isRequired,
  onMouseOut: func,
  onMouseOver: func,
};

InfowindowInstance.defaultProps = {
  isMountedInRealDom: false,
};

export default InfowindowInstance;
