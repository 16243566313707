import { useCallback, useState } from 'react';

const INITIAL_SELECTED_RESULTS = { index: null, items: null };

const useSelectedItem = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [selectedResults, setSelectedResults] = useState(INITIAL_SELECTED_RESULTS);

  const handleMarkerSelection = useCallback(
    (index, items) => {
      setSelectedMarker(index);
      setSelectedSlide(index);

      if (items !== undefined) {
        setSelectedResults({ index, items });
      }
    },
    [setSelectedMarker, setSelectedResults],
  );

  const handleSlideSelection = (slideIndex) => setSelectedMarker(slideIndex);

  return {
    selectedMarker,
    selectedSlide,
    selectedResults: selectedResults.items,
    selectedResultsIndex: selectedResults.index,
    setSelectedMarker: handleMarkerSelection,
    setSelectedSlide: handleSlideSelection,
  };
};

export { useSelectedItem };
