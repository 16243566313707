/* eslint-disable import/order */
import React from 'react';

import { number, string, elementType } from 'prop-types';
import classnames from 'classnames';
import { getSplitText } from '../../../utils/split-test';
import useElementResize from '../../../hooks/use-element-resize';

const { useRef } = React;

const namespace = 'ui-search-coupon';

const splitContent = (width, label) => {
  const characterSize = 8.5;
  const iconWithPromo = 20;

  return getSplitText(label, characterSize, width - iconWithPromo);
};

const getWordsForLabel = (textsForPills) => Array.from(textsForPills).filter((text) => text !== '');

const fitPriceInText = (text) => text.replace(/[\])}[{(]/g, '');

const Promotion = ({ label, background, color, contentWidth, deviceType, promotionIcon: IconComponent }) => {
  const containerRef = useRef();
  const { width: containerWidth } = useElementResize(containerRef, contentWidth);

  const textsForPills = splitContent(containerWidth, label);
  const splitWords = getWordsForLabel(textsForPills);
  const isMobile = deviceType !== 'desktop';

  /* eslint-disable react/no-array-index-key */
  return (
    <div ref={containerRef} className="container-promotion">
      {splitWords.map((word, index) => (
        <div
          key={`${word}-${index}`}
          className={classnames(namespace, {
            'label-padding-mobile': isMobile,
            'label-padding-desktop': !isMobile,
          })}
          style={{ backgroundColor: background }}
        >
          <div
            className={classnames(`${namespace}__label`, {
              'label-text-mobile': isMobile,
              'label-text-desktop': !isMobile,
            })}
            style={{ color }}
          >
            {index === 0 && (
              <div className={classnames(`${namespace}__label__icon`)}>
                <IconComponent />
              </div>
            )}
            {fitPriceInText(word)}
          </div>
        </div>
      ))}
    </div>
  );
};

/* eslint-enable import/order */
/* eslint-enable react/no-array-index-key */
Promotion.propTypes = {
  background: string,
  color: string,
  contentWidth: number.isRequired,
  deviceType: string.isRequired,
  label: string.isRequired,
  promotionIcon: elementType.isRequired,
};

export default Promotion;
