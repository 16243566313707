import React from 'react';

import warning from 'warning';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import classnames from 'classnames';

const IS_DEV = process.env.NODE_ENV !== 'production';
const getIconComponent = (id, iconSet) => get(iconSet, id);

const buildComponent = (Component, props) => <Component {...props} />;

const IconFactory = (iconSet) => {
  const icons = iconSet?.reduce((acc, i) => {
    acc[i.ICON_ID] = i;

    return acc;
  }, {});

  return (icon, className) => {
    if (React.isValidElement(icon)) {
      return React.cloneElement(icon, { className });
    }

    if (!isArray(iconSet)) {
      throw new Error('Bad Parameter, array expected');
    }

    let id;

    if (typeof icon === 'string') {
      id = icon.toLowerCase();
    } else if (isObject(icon) && typeof icon.id === 'string') {
      id = icon.id.toLowerCase();
    } else {
      if (IS_DEV) {
        warning(false, `icon must be an string or an object with id property, ${typeof icon} provided`);
      }

      return null;
    }

    const Component = getIconComponent(id, icons);

    if (!Component) {
      if (IS_DEV) {
        warning(false, `icon "${id}" not found`);
      }

      return null;
    }

    return Component
      ? buildComponent(Component, { className: classnames(className), description: icon.description })
      : Component;
  };
};

const factoryBuilder = (iconSet) => IconFactory(iconSet);

export default factoryBuilder;
