/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { node, string, object } from 'prop-types';

import { PolycardContext } from '@polycard/card';

import { PRELOAD_MOBILE, DEVICE_TYPES, PRELOAD_DESKTOP } from '../../constants';

const WrappedContext = ({
  children = null,
  contextValue = null,
  deviceType = DEVICE_TYPES.MOBILE,
  layout = 'grid',
  countryConfig = null,
}) => {
  const numberOfPreload =
    deviceType === DEVICE_TYPES.MOBILE ? PRELOAD_MOBILE[`${layout}`] : PRELOAD_DESKTOP[`${layout}`];

  if (contextValue) {
    return (
      <PolycardContext
        contextValue={{ ...contextValue, title_tag: 'h2' }}
        deviceType={deviceType}
        isNavigableToVariation
        countPreloadImages={numberOfPreload}
        thousandSeparator={countryConfig?.thousands_separator}
        decimalSeparator={countryConfig?.decimal_separator}
      >
        {children}
      </PolycardContext>
    );
  }

  return <>{children}</>;
};

WrappedContext.propTypes = {
  children: node,
  contextValue: object,
  countryConfig: object,
  deviceType: string,
  layout: string,
};

export default WrappedContext;
