import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--international-filter-cbt-mobile';

const IconInternationalFilterCBTMobile = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="international-filter-cbt-mobile.svg" alt="Compra Internacional" />
  </div>
);

IconInternationalFilterCBTMobile.propTypes = {
  className: string,
};

IconInternationalFilterCBTMobile.defaultProps = {
  className: '',
};

export default React.memo(IconInternationalFilterCBTMobile);
