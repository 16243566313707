import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--shippingtruck';

const ICON_ID = 'ic_shipping_truck';

const IconShippingTruck = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    aria-hidden="true"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconShippingTruck.propTypes = {
  className: string,
};

export default React.memo(IconShippingTruck);
