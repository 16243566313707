import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--international-logo';

const DEFAULT_COLOR = '#1F4E96';

const IconInternationalLogo = ({ className, color, srLabel = 'Compra Internacional' }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 213 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color || DEFAULT_COLOR}
    alt={srLabel}
  >
    <use href="#international-logo" />
  </svg>
);

IconInternationalLogo.propTypes = {
  className: string,
  color: string,
};

IconInternationalLogo.defaultProps = {
  className: '',
  color: '',
};

export default React.memo(IconInternationalLogo);
