const BLACKLISTED_PARAMS = [
  'redirectedFromOfficialStoreZrp',
  'redirectedFromDealZrp',
  'redirectedFromCpgZrp',
  'redirectedFromEshopZrp',
];

const layoutRegex = /_DisplayType_[A-Z]{1,2}/;

const NO_FOLLOW = 'nofollow';
const NO_FOLLOW_SPONSORED = 'nofollow,sponsored';

const namespace = 'ui-search-link';

const NO_INDEX_PARAM = '_NoIndex_True';

export { BLACKLISTED_PARAMS, NO_FOLLOW, NO_FOLLOW_SPONSORED, NO_INDEX_PARAM, layoutRegex, namespace };
