import { useEffect, useState } from 'react';

function useElementResize(elementRef, initialWidth, initialHeight = 0) {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    setWidth(elementRef.current.clientWidth);
    setHeight(elementRef.current.clientHeight);
  }, [elementRef]);

  useEffect(() => {
    function handleResize() {
      setWidth(elementRef.current.clientWidth);
      setHeight(elementRef.current.clientHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [elementRef]);

  return {
    width,
    height,
  };
}

export default useElementResize;
