import React from 'react';

import { useStaticProps } from '../../components/context/static-props';
import { useLayout } from '../../hooks/context';
import { linkBuildProps, linkBuildNewHref } from './utils';
import { NO_INDEX_PARAM, namespace } from './constants';
import { linkPropTypes } from './propTypes';

const shouldAddNoFollow = (href) => href && href.indexOf(NO_INDEX_PARAM) !== -1;

const Link = ({
  className = '',
  href = '',
  linkTag = 'a',
  title = null,
  role = null,
  tabIndex = null,
  onClick = null,
  isInternal = true,
  keepLayout = true,
  ariaLabel = null,
  ariaLabelledBy = null,
  tracks = null,
  target = null,
  children,
  isAd,
  adLabel,
}) => {
  const { queryParams } = useStaticProps();
  const { currentLayout, initialLayout } = useLayout();

  const newHref = linkBuildNewHref({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout });
  const addNoFollow = shouldAddNoFollow(href) || adLabel;
  const linkProps = linkBuildProps({
    linkTag,
    newHref,
    href,
    className,
    namespace,
    title,
    role,
    tabIndex,
    onClick,
    target,
    ariaLabel,
    ariaLabelledBy,
    tracks,
    shouldAddNoFollow: addNoFollow,
    isAd,
  });

  return React.createElement(linkTag, { ...linkProps }, children);
};

Link.propTypes = {
  ...linkPropTypes,
};

export default Link;
