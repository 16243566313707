import React from 'react';
import { string, shape, arrayOf } from 'prop-types';

import classNames from 'classnames';

import { libraryIcons } from './common';

const namespace = 'icon-custom';

const defaultStyles = {};
const Icon = ({ icon, size, class_name, color, description, sourceIcons }) => {
  const iconName = libraryIcons?.[icon]?.[String(size)];
  const { [iconName]: IconCustom } = sourceIcons;

  if (!iconName || !IconCustom) {
    return null;
  }

  return (
    <span style={{ ...defaultStyles }} className={classNames(namespace, class_name)} aria-label={description}>
      <IconCustom color={color} />
    </span>
  );
};

Icon.propTypes = {
  class_name: string,
  color: string,
  description: string,
  icon: string,
  size: string,
  sourceIcons: arrayOf(
    shape({
      className: string,
      color: string,
      description: string,
      icon: string.isRequired,
      key: string,
      size: string,
    }),
  ),
};

export default Icon;
