import React from 'react';

import { useSearch } from '../../hooks/context';
import BrandAdsDesktop from '../brand-ads/brand-ads.desktop';
import ComponentList from '../../utils/component-list/index';

const availableComponents = {
  BRAND_ADS_INTERVENTION: BrandAdsDesktop,
};

const HeaderComponent = () => {
  const { header_components } = useSearch();

  return <ComponentList availableComponents={availableComponents} components={header_components} />;
};

export default HeaderComponent;
