import React, { useMemo, useRef, useCallback, useEffect, useState } from 'react';
import { arrayOf, element, func, oneOfType, string, shape, number } from 'prop-types';

import { useStaticProps } from '../../context/static-props';
import { isItemIntervention } from '../renderer/utils';
import { renderItemsIterventionsDesktop } from '../../intervention/utils/render-item-interventions';
import { VERTICAL_TYPE_CPG } from '../renderer/constants';

const namespace = 'ui-search-layout';
const cpgBaseValue = 209; // Base value needed for recomendations on cpg cards

const GridRows = ({ itemComponent: LayoutItem, itemGroup: initialItemGroup, layout, idx }) => {
  const item = initialItemGroup || [];
  const { lowEnd, vertical } = useStaticProps();
  const options = useMemo(() => ({ lowEnd, verboseLabels: false, preload: false }), [lowEnd]);

  const listRef = useRef(null);
  const [maxImageHeight, setMaxImageHeight] = useState(0);
  const [maxContentHeight, setMaxContentHeight] = useState(0);

  const safeSetMaxContentHeight = useCallback(
    (newValue) => {
      const baseCpgVal = newValue < cpgBaseValue ? cpgBaseValue : newValue;

      switch (vertical) {
        case VERTICAL_TYPE_CPG:
          return setMaxContentHeight((currentBaseHeight) =>
            baseCpgVal > currentBaseHeight ? baseCpgVal : currentBaseHeight,
          );

        default:
          return setMaxContentHeight((currentBaseHeight) =>
            newValue > currentBaseHeight ? newValue : currentBaseHeight,
          );
      }
    },
    [vertical],
  );

  const handleMount = useCallback(
    ({ imageHeight, contentHeight }) => {
      setMaxImageHeight((currentBaseHeight) => (imageHeight > currentBaseHeight ? imageHeight : currentBaseHeight));
      safeSetMaxContentHeight(contentHeight);
    },
    [safeSetMaxContentHeight],
  );

  useEffect(() => {
    listRef.current.style.setProperty('--min-image-height', `${maxImageHeight}px`);
    listRef.current.style.setProperty('--min-content-height', `${maxContentHeight}px`);
  }, [maxImageHeight, maxContentHeight]);

  return (
    <li className={`${namespace}__item`} ref={listRef}>
      {isItemIntervention(item) ? (
        renderItemsIterventionsDesktop[item.id](item)
      ) : (
        <LayoutItem
          key={item?.polycard?.metadata?.id || item?.id}
          layout={layout}
          item={item}
          options={options}
          index={idx}
          onMount={handleMount}
        />
      )}
    </li>
  );
};

GridRows.propTypes = {
  idx: number.isRequired,
  itemComponent: oneOfType([element, func]).isRequired,
  itemGroup: arrayOf(shape()),
  layout: string.isRequired,
};

export default GridRows;
