import { FILTER_TOOLTIP_PREFIX } from './constants';

const getFilterTooltipKey = ({ filter_content_id: contentId } = {}) =>
  contentId ? `${FILTER_TOOLTIP_PREFIX}-${contentId}` : '';

const isTTLActive = ({ isPermanentClosed, with_ttl, hasTTLExpired, closeLimit, countClose, createdDate }) => {
  if (createdDate === null || createdDate === undefined) {
    return true;
  }

  if (isPermanentClosed) {
    return false;
  }

  if (with_ttl && (!hasTTLExpired() || closeLimit <= countClose)) {
    return false;
  }
  return true;
};

export { getFilterTooltipKey, isTTLActive };
