const suggestionsClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { SUGGESTIONS_PATH } = require('../constants/paths');

class Service {
  static getSuggestions(category, query = {}) {
    return suggestionsClient
      .get(`${SUGGESTIONS_PATH}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
        },
        params: {
          categoryId: category,
          ...query,
        },
      })
      .then((response) => response);
  }
}

module.exports = Service;
