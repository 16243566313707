import React from 'react';
import { string, bool } from 'prop-types';

import StyledLabelWrapper from '../../styled-label/styled-label';
import { useSearch } from '../../../hooks/context';
import Link from '../../../components-v2/link';

const AdLabel = ({ text, shouldRenderLinks }) => {
  const { ads_metadata } = useSearch();

  const padsDebugInfoContent = ads_metadata?.pads_debug_info ? (
    <input type="hidden" id="pads_debug_info" data-attribute1={ads_metadata?.pads_debug_info} />
  ) : null;
  const labelContent = (
    <StyledLabelWrapper color={ads_metadata?.ui?.font_color} text={text} className="ui-search-item__pub-label" />
  );

  if (ads_metadata?.landing && shouldRenderLinks) {
    return (
      <div className="ui-search-item__pub-container">
        <Link className="ui-search-item__pub-link" href={ads_metadata.landing} adLabel title={text}>
          {labelContent}
        </Link>
        {padsDebugInfoContent}
      </div>
    );
  }

  return (
    <div className="ui-search-item__pub-container">
      {labelContent}
      {padsDebugInfoContent}
    </div>
  );
};

AdLabel.propTypes = {
  shouldRenderLinks: bool,
  text: string,
};

AdLabel.defaultProps = {
  shouldRenderLinks: false,
  text: '',
};

export default AdLabel;
