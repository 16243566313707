/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { string, shape, bool } from 'prop-types';

import classnames from 'classnames';

import Link from '../../../components-v2/link';
import { trackEvent } from '../../../lib/tracking';
import { GRID, GALLERY } from '../../layout/renderer/constants';
import IconLabel from '../../icon-label/icon-label';

const namespace = 'ui-search-official-store-label';

const handleStoreRedirect = (event, permalink) => {
  event.preventDefault();

  if (permalink) {
    window.location.href = permalink;
  }
};

function trackingClick(tracks) {
  trackEvent(tracks);
}

function capitalize(stringSet) {
  if (stringSet) {
    return stringSet.charAt(0).toUpperCase() + stringSet.slice(1);
  }

  return null;
}

const renderTypeLabel = (
  className,
  label,
  permalink,
  shouldRenderLinks,
  title,
  tracks,
  isAd,
  layout,
  has_official_store_icon,
) => {
  const officialStoreText = capitalize(label.text.split(' ')?.slice(1)?.join(' '));
  const iconContent = ['cockade'];

  const labelContent = (
    <p
      className={classnames(namespace, className, `ui-search-color--${label.color}`)}
      onClick={shouldRenderLinks ? undefined : (e) => handleStoreRedirect(e, permalink)}
    >
      {layout === GRID || layout === GALLERY ? officialStoreText : label.text}
      {has_official_store_icon && <IconLabel className={`${namespace}__cockade`} icons={iconContent} text="{icon}" />}
    </p>
  );

  if (permalink && shouldRenderLinks) {
    return (
      <Link
        className="ui-search-official-store-item__link"
        href={permalink}
        title={title}
        isAd={isAd}
        onClick={() => {
          trackingClick(tracks);
        }}
      >
        {labelContent}
      </Link>
    );
  }

  return labelContent;
};

const OfficialStoreLabel = ({
  className = null,
  label,
  permalink = null,
  shouldRenderLinks = false,
  title,
  tracks = null,
  isAd,
  layout,
  has_official_store_icon,
}) =>
  label.text !== null && label.text !== undefined
    ? renderTypeLabel(
        className,
        label,
        permalink,
        shouldRenderLinks,
        title,
        tracks,
        isAd,
        layout,
        has_official_store_icon,
      )
    : null;

OfficialStoreLabel.propTypes = {
  className: string,
  has_official_store_icon: bool,
  isAd: bool,
  label: shape({
    text: string,
    color: string.isRequired,
  }).isRequired,
  permalink: string,
  shouldRenderLinks: bool,
  title: string,
  tracks: shape({
    melidata_track: string,
    analytics_track: string,
  }),
};

export default OfficialStoreLabel;
