import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--f1-car';

const ICON_ID = 'ic_f1_car';

const IconF1Car = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="26"
    height="12"
    viewBox="0 0 26 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconF1Car.propTypes = {
  className: string,
};

IconF1Car.defaultProps = {
  className: null,
};

export default IconF1Car;
