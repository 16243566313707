import React from 'react';
import { arrayOf, func, shape, string, oneOfType, node, number } from 'prop-types';

import LoaderError from './loader-error';
import { useStaticProps } from '../context/static-props';
import { Loader, Map } from './lib';
import { INITIAL_MAP_STATUS } from './constants';
import { LANGUAGE_TO_MAP_LANGUAGE } from './lib/constants';
import MarkerGroup from './marker-group';

const getMapLanguage = (languageLocale = '') => {
  const [language] = languageLocale.split('_');

  return LANGUAGE_TO_MAP_LANGUAGE[language];
};

const FullMap = ({
  className,
  markers,
  children,
  handleBoundsChanged,
  handleMapLoad,
  handleDragEnd,
  handleZoomChanged,
  handleMapClick,
  handleMarkerClick,
  handleIdle,
  mapStatus: { bounds, zoom },
  selectedCluster,
  selectedMarker,
  handleMarkerHover,
}) => {
  const { mapApiKey, country: { locale } = {} } = useStaticProps();

  return (
    <Loader apiKey={mapApiKey} language={getMapLanguage(locale)} ErrorComponent={LoaderError}>
      <Map
        className={className}
        height="100%"
        onBoundsChanged={handleBoundsChanged}
        onClick={handleMapClick}
        onLoad={handleMapLoad}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChanged}
        handleIdle={handleIdle}
      >
        <MarkerGroup
          bounds={bounds}
          markers={markers}
          selectedCluster={selectedCluster}
          selectedMarker={selectedMarker}
          onMarkerSelect={handleMarkerClick}
          onMarkerHover={handleMarkerHover}
          zoom={zoom}
        />
        {children}
      </Map>
    </Loader>
  );
};

FullMap.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  handleBoundsChanged: func.isRequired,
  handleDragEnd: func.isRequired,
  handleIdle: func.isRequired,
  handleMapClick: func.isRequired,
  handleMapLoad: func.isRequired,
  handleMarkerClick: func.isRequired,
  handleMarkerHover: func,
  handleZoomChanged: func.isRequired,
  mapStatus: shape({
    bounds: shape({
      top: number,
      right: number,
      bottom: number,
      left: number,
    }),
    zoom: number,
  }),
  markers: arrayOf(shape()),
  selectedCluster: number,
  selectedMarker: number,
};

FullMap.defaultProps = {
  children: null,
  className: null,
  mapStatus: INITIAL_MAP_STATUS,
  markers: [],
  selectedCluster: null,
  selectedMarker: null,
  handleMarkerHover: () => undefined,
};

export default FullMap;
