import { trackEvent } from '../../../lib/melidata';
import { trackEvent as trackAnalyticsEvent } from '../../../lib/analytics';
import { FILTERS } from '../../../constants';

const {
  IDS: { OPERATION, CATEGORY, PROPERTY_TYPE, OPERATION_SUBTYPE, STATE, CITY, NEIGHBORHOOD },
} = FILTERS;
const buildMelidataPayload = (facetedState, melidataTrack, isNewFacetedSearch) => {
  if (isNewFacetedSearch) {
    const [CATEGORY_ID, PROPERTY_TYPE_ID] = facetedState[CATEGORY].split('_');
    const eventData = {
      faceted_search_filters: {
        category_id: CATEGORY_ID,
        property_type_id: PROPERTY_TYPE_ID,
        operation_id: facetedState[OPERATION],
        is_development: !!facetedState[OPERATION_SUBTYPE],
        location_state: facetedState[STATE],
        location_city: facetedState[CITY],
        location_neighborhood: facetedState[NEIGHBORHOOD],
      },
    };

    return {
      ...melidataTrack,
      event_data: eventData,
      clean: true,
    };
  }

  const { faceted_selected_filters: selectedFilters } = melidataTrack.event_data || {};

  if (!selectedFilters) {
    return null;
  }

  const eventData = {
    faceted_selected_filters: { ...selectedFilters },
    faceted_applied_filters: {
      operation: facetedState[OPERATION],
      property: facetedState[PROPERTY_TYPE],
      only_new: !!facetedState[OPERATION_SUBTYPE],
      keep_filters: !!selectedFilters.keep_filters,
      location: facetedState[NEIGHBORHOOD] || facetedState[CITY] || facetedState[STATE] || null,
    },
  };

  return {
    ...melidataTrack,
    event_data: eventData,
    clean: true,
  };
};

const sendTrack = (
  facetedState,
  { melidata_track: melidataTrack = {}, analytics_track: analyticsTrack = {} } = {},
  isNewFacetedSearch = false,
) => {
  const melidataPayload = buildMelidataPayload(facetedState, melidataTrack, isNewFacetedSearch);

  trackAnalyticsEvent(analyticsTrack);

  if (melidataPayload) {
    trackEvent(melidataPayload);
  }
};

export { sendTrack };
