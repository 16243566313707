import React, { Children, cloneElement, useEffect, useRef } from 'react';
import { string, shape, node } from 'prop-types';
import { createPortal } from 'react-dom';

import cx from 'classnames';

import { CONTROL_POSITION, NAMESPACE } from './constants';
import helper from '../helpers';

const { getControlPosition } = helper;

const CustomControls = ({ map, position = CONTROL_POSITION.rightBottom, children }) => {
  const controlRef = useRef(document.createElement('div'));

  useEffect(() => {
    const mapControls = map.controls[getControlPosition(position)];
    const controlIndex = mapControls.length;

    mapControls.push(controlRef.current);

    return () => mapControls.splice(controlIndex, 1);
  }, [map, position, controlRef]);

  if (!controlRef) {
    return null;
  }

  return createPortal(
    <div className={cx(NAMESPACE, `${NAMESPACE}-custom-controls`)}>
      {Children.map(children, (child) => cloneElement(child, { map }))}
    </div>,
    controlRef.current,
  );
};

CustomControls.propTypes = {
  children: node.isRequired,
  map: shape({}).isRequired,
  position: string,
};

export default CustomControls;
