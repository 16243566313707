import React, { memo } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';

import Tooltip from '@andes/tooltip';

import FiltersButton from './filters-button';
import FiltersContent from './filters-content';
import { NAMESPACE } from '../constants';

const SecondaryFilters = ({ appliedFilters, availableFilters, availableLabels, appliedLabels, isLoading }) => {
  if (isLoading) {
    return <FiltersButton label={appliedLabels.filter} totalLabel={appliedLabels.total} />;
  }

  return (
    <div className={`${NAMESPACE}__secondary-container`}>
      <Tooltip
        side="bottomRight"
        trigger="click"
        className={`${NAMESPACE}__filters-tooltip`}
        offsetX={-16}
        offsetY={10}
        zIndex={900}
        content={
          <FiltersContent
            isLoading={isLoading}
            appliedFilters={appliedFilters}
            availableFilters={availableFilters}
            availableLabels={availableLabels}
          />
        }
      >
        <FiltersButton label={appliedLabels.filter} totalLabel={appliedLabels.total} />
      </Tooltip>
    </div>
  );
};

SecondaryFilters.propTypes = {
  appliedFilters: arrayOf(shape({})).isRequired,
  appliedLabels: shape({
    filter: string.isRequired,
    total: string.isRequired,
  }).isRequired,
  availableFilters: arrayOf(shape({})).isRequired,
  availableLabels: shape({}).isRequired,
  isLoading: bool,
};

SecondaryFilters.defaultProps = {
  isLoading: false,
};

export default memo(SecondaryFilters);
