import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'recommendations-full_icon';

const namespace = 'ui-search-icon ui-search-icon--full';

const IconFullfillment = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 41 13" xmlns="http://www.w3.org/2000/svg">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconFullfillment.propTypes = {
  className: string,
};

IconFullfillment.defaultProps = {
  className: null,
};

const IconApplMemo = React.memo(IconFullfillment);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
