/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import { string, number } from 'prop-types';

import { isLocalStorageSupported } from '../lib/dom-utils';

const useTimesViewed = (id = 'timesLS', maxViews = 1) => {
  const [timesShownState, setTimesShownState] = useState({ id, maxViews, views: 0, wasClosed: false });

  function getCurrentData() {
    let localStorageStatus = null;

    if (isLocalStorageSupported()) {
      const localStorageCurrent = localStorage.getItem(id);

      localStorageStatus = localStorageCurrent ? JSON.parse(localStorageCurrent) : timesShownState;
    }

    return localStorageStatus;
  }

  function updateLocalStorage(dataToSend, errorMessage = 'There is not possible to save in Local Storage') {
    let wasUpdated = false;

    try {
      if (isLocalStorageSupported()) {
        localStorage.setItem(id, JSON.stringify(dataToSend));
        setTimesShownState(dataToSend);
        wasUpdated = true;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(errorMessage, error);
    }

    return wasUpdated;
  }

  function handleClose() {
    const localStorageStatus = getCurrentData();

    if (localStorageStatus) {
      localStorageStatus.wasClosed = true;
      updateLocalStorage(localStorageStatus);
    }
  }

  useEffect(() => {
    if (isLocalStorageSupported()) {
      const localStorageStatus = getCurrentData();

      localStorageStatus.views += 1;

      updateLocalStorage(localStorageStatus);
    }
  }, [isLocalStorageSupported]);

  return {
    ...timesShownState,
    isAvailable: timesShownState?.views <= timesShownState?.maxViews,
    handleClose,
  };
};

useTimesViewed.propTypes = {
  id: string.isRequired,
  maxViews: number.isRequired,
};

export default useTimesViewed;
