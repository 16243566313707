// In production, export the es5 targeted file which is IE11 compatible.

const supercluster =
  process.env.NODE_ENV === 'production' ? require('supercluster/dist/supercluster.min') : require('supercluster');

if (process.env.NODE_ENV === 'production') {
  module.exports = supercluster;
} else {
  module.exports = supercluster;
}
