import React from 'react';
import { arrayOf, shape, string } from 'prop-types';

import FilterRange from '../../../../../filters/range-filters/range-filters.desktop';

const RenderFilterRange = ({
  activeCurrencyId = '',
  fragment = '',
  labels = null,
  modifier = '',
  type = '',
  url_templates = '',
  values = null,
}) => (
  <FilterRange
    labels={labels}
    values={values}
    url={url_templates}
    activeCurrencyId={activeCurrencyId}
    type={type}
    id={modifier}
    fragment={fragment}
    className={`ui-search-filter-range--${modifier}`}
  />
);

RenderFilterRange.propTypes = {
  activeCurrencyId: string,
  fragment: string,
  labels: arrayOf(shape({})),
  modifier: string,
  type: string,
  url_templates: string,
  values: arrayOf(shape({})),
};

export default RenderFilterRange;
