import React from 'react';
import { node, string, objectOf, any } from 'prop-types';

import { Tooltip as AndesTooltip } from '@andes/tooltip';

import useTooltip from '../../../../hooks/use-tooltip';
import { getFilterTooltipKey } from './helpers';

const namespace = 'ui-search-tooltip-filter';

const FilterTooltip = ({ children, tooltip: initialTooltip, className = '' }) => {
  const tooltip = initialTooltip || {
    side: 'right',
    type: 'highlight',
  };
  const { title, offset_x, offset_y, content, side, type, max_print, filter_content_id } = tooltip || {};

  const localStorageKey = getFilterTooltipKey({ filter_content_id });
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);

  if (tooltipStatus?.wasClosed || tooltipStatus?.views > max_print || !tooltip || !content) {
    return children;
  }

  return (
    <AndesTooltip
      className={`${namespace} ${className}`}
      closable
      onClose={handleClose}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      side={side}
      type={type}
      trigger="no-trigger"
      visible
    >
      {children}
    </AndesTooltip>
  );
};

FilterTooltip.propTypes = {
  children: node.isRequired,
  className: string,
  tooltip: objectOf(any),
};

export default FilterTooltip;
