import React from 'react';
import { number, shape } from 'prop-types';

import { getSplitText } from '../../../utils/split-test';
import ItemHighlight from '../highlight/highlight-label';

const namespace = 'ui-search-rebates';

const Rebates = ({ rebates, contentWidth }) => {
  let rebatesContent = [];

  const splitRebate = (rebate, width, characterSize) => {
    if (rebate.highlight && width) {
      const highlight = {};

      if (rebate.highlight.background && rebate.highlight.type === 'pricing_rebates') {
        const { background } = rebate.highlight;

        highlight.label = {
          ...rebate.highlight.label,
          background,
        };
        highlight.background = undefined;
      }

      const label = rebate.highlight ? rebate.highlight.label : null;
      const splits = getSplitText(label.text, characterSize, width);

      highlight.pills = splits.map((split) => ({ ...label, text: split }));

      return {
        ...rebate,
        highlight: {
          ...rebate.highlight,
          ...highlight,
        },
      };
    }

    return rebate;
  };

  const splitRebates = (width) => {
    const characterSize = 6.3;

    rebatesContent = rebates.map((rebate) => splitRebate(rebate, width, characterSize));
  };

  const renderItemHighlight = (rebate) =>
    rebate?.highlight?.pills?.map((pill) => (
      <ItemHighlight {...rebate.highlight} label={pill} className={`${namespace}-rebate__pill`} />
    ));

  splitRebates(contentWidth);

  return (
    <div className={namespace}>
      {rebatesContent.map((rebate) => (
        <div className={`${namespace}-rebate`}>
          {renderItemHighlight(rebate)}
          {rebate?.label?.text && (
            <span
              style={rebate.label.color ? { color: rebate.label.color } : {}}
              className={
                rebate.highlight.label.text.length > 15 ? `${namespace}-rebate__text_nl` : `${namespace}-rebate__text`
              }
            >
              {rebate.label.text}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

Rebates.propTypes = {
  contentWidth: number.isRequired,
  rebates: shape().isRequired,
};

export default Rebates;
