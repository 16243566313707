import React from 'react';

import { loadable } from 'nordic/lazy';

import useFooterDisclaimer from './use-footer-disclaimer';

const ChunkFooterDisclaimer = () => {
  const footerDisclaimerProps = useFooterDisclaimer();
  const FooterDisclaimer = loadable(
    /* webpackChunkName: "chunk-footer-disclaimer" */ async () => import('./footer-disclaimer'),
  );

  return footerDisclaimerProps ? <FooterDisclaimer {...footerDisclaimerProps} /> : null;
};

export default ChunkFooterDisclaimer;
