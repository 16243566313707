import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-list-desktop';

const ICON_ID = 'ic_list_desktop';

const IconListDesktop = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconListDesktop.propTypes = {
  className: string,
};

IconListDesktop.defaultProps = {
  className: null,
};

export default IconListDesktop;
