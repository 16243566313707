import React from 'react';
import { string, bool, shape } from 'prop-types';

import classNames from 'classnames';
import MoneyAmount from '@andes/money-amount';

import { PACKAGE_NAMESPACE_LABEL } from '../constants';

const NAMESPACE = `${PACKAGE_NAMESPACE_LABEL}-price__part`;

const Price = ({ className, currency_symbol: currencySymbol, decimal, id, strikeThrough, styles, text }) => (
  <span style={{ ...styles }}>
    <MoneyAmount
      className={classNames(NAMESPACE, className, id)}
      value={{
        fraction: text,
        cents: decimal || null,
      }}
      disabled={strikeThrough}
      symbol={currencySymbol}
      previous={strikeThrough}
      size={16}
      centsType="superscript"
    />
  </span>
);

Price.propTypes = {
  className: string,
  currency_symbol: string,
  decimal: string,
  id: string,
  strikeThrough: bool,
  styles: shape(),
  text: string,
};

export default Price;
