import React from 'react';

import { useStaticProps } from '../../../context/static-props';
import MapLayoutItem from '../map/card-component';
import CardMobile from '../../../map/cards/card-mobile';
import { DEVICE_TYPES } from '../../../../constants';

const { MOBILE } = DEVICE_TYPES;

const ItemLayout = (props) => {
  const { deviceType } = useStaticProps();

  if (deviceType === MOBILE) {
    return <CardMobile {...props} />;
  }

  return <MapLayoutItem {...props} />;
};

export default ItemLayout;
