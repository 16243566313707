import React from 'react';
import { arrayOf, shape, string, number } from 'prop-types';

import classnames from 'classnames';
import ChevronRight20 from '@andes/icons/ChevronRight20';

import useFilterRange from '../../../hooks/use-filter-range';
import FilterRangesInput from './components/range-filter-item';
import { TYPE_RANGE } from '../../../constants';
import { useSearch } from '../../../hooks/context';

const namespace = 'ui-search-price-filter';

const FilterRange = ({
  className,
  labels: initialLabels,
  values: initialValues,
  url = '',
  activeCurrencyId = null,
  type,
  id,
  fragment,
}) => {
  const labels = initialLabels || [];
  const values = initialValues || [];

  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const isDropdown = type === TYPE_RANGE.DROPDOWN;
  const onlyNumbersPattern = '^[0-9]+([,.][0-9]+)?$';

  const { handleChangeDropdown, handleInputChange, handleOnSubmit, labelsData, disabled, setDropdownItemSelected } =
    useFilterRange({
      filterId: id,
      labels,
      url,
      activeCurrencyId,
      type,
      fragment,
    });

  const renderFilterButton = () => (
    <button
      data-testid={`submit-${id}`}
      type="submit"
      className={`${namespace}-action-btn`}
      disabled={disabled}
      aria-label={labels.apply}
    >
      <ChevronRight20 />
    </button>
  );

  return (
    <form
      onSubmit={handleOnSubmit}
      className={classnames(namespace, className, { 'shops__price-filter': isShops })}
      method="post"
    >
      {labelsData.map((value) => (
        <FilterRangesInput
          className={classnames(`${namespace}-container`, { 'shops__price-filter-container': isShops })}
          id={id}
          type={type}
          handleInputChange={handleInputChange}
          handleChangeDropdown={handleChangeDropdown}
          label={value.text}
          name={value.label}
          setDropdownItemSelected={setDropdownItemSelected}
          values={isDropdown ? values : null}
          pattern={onlyNumbersPattern}
        />
      ))}
      <div className={`${namespace}-container`}>{renderFilterButton()}</div>
    </form>
  );
};

FilterRange.propTypes = {
  activeCurrencyId: string,
  className: string.isRequired,
  fragment: string,
  id: string,
  labels: arrayOf(
    shape({
      label: string.isRequired,
      type: string.isRequired,
    }),
  ),
  type: string,
  url: string,
  values: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      results: number,
      url: string.isRequired,
    }),
  ),
};

export default FilterRange;
