import React, { useEffect, useRef } from 'react';
import { arrayOf, elementType, shape, bool, func } from 'prop-types';

import cx from 'classnames';

import { namespace } from '../../constants';
import usePagination from '../../hooks/use-pagination';
import Pagination from '../pagination';
import Results from './results';
import ListPlaceholder from './list-placeholder';
import Header from './header';

const List = ({ isClusterSelected, isLoadingResults, ItemComponent, onItemHover, onUnselectCluster, results }) => {
  const { previousPage, currentPage, nextPage, paginatedResults, showPagination, isLoading, offset } = usePagination({
    isLoadingResults,
    results,
    overrideHashPage: isClusterSelected,
  });
  const list = useRef(null);

  useEffect(() => {
    if (list.current) {
      list.current.scrollTop = 0;
    }
  }, [paginatedResults]);

  return (
    <div className={namespace}>
      <Header
        className={cx(namespace, `${namespace}__header`)}
        clusterSize={results.length}
        isClusterSelected={isClusterSelected}
        onUnselectCluster={onUnselectCluster}
      />
      <div className={cx(namespace, `${namespace}__group`)} ref={list}>
        <div>
          {isLoadingResults ? (
            <ListPlaceholder />
          ) : (
            <Results
              results={paginatedResults}
              ItemComponent={ItemComponent}
              onItemHover={onItemHover}
              offset={offset}
            />
          )}
        </div>

        {showPagination && !isLoading && (
          <Pagination currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
        )}
      </div>
    </div>
  );
};

List.propTypes = {
  isClusterSelected: bool,
  isLoadingResults: bool.isRequired,
  ItemComponent: elementType.isRequired,
  results: arrayOf(shape()),
  onItemHover: func.isRequired,
  onUnselectCluster: func.isRequired,
};

List.defaultProps = {
  isClusterSelected: false,
  results: [],
};

export default List;
