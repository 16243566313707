import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { getVariablesFromText, getVariablesObject, splitVariablesAndText } from '../utils/utilities';
import HighLightUI from './highlight-ui';
import renderComponentsFromBuilder from '../utils/renderComponentsFromBuilder';

const HighlightBuilder = props => {
  const { accessibility_text, text, values, sourceIcons } = props;
  const [content, setContent] = useState(values);

  useEffect(() => {
    setContent(values);
  }, [values]);


  const result = splitVariablesAndText(text);

  const vars = getVariablesFromText(text);

  const replacements = getVariablesObject(vars, content);

  const componentsToRender = renderComponentsFromBuilder(result, replacements, sourceIcons, accessibility_text);

  return <HighLightUI {...props} content={componentsToRender} type="BUILDER" accessibility_text={accessibility_text} />;
};


HighlightBuilder.propTypes = {
  accessibility_text: string,
  class_name: string,
  styles: shape({}),
  text: string,
  values: arrayOf(shape({})),
};


export default HighlightBuilder;
