import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-half';

const IconStarHalf = ({ className = null }) => (
  <svg className={classnames(namespace, className)} width="100%" height="100%" viewBox="0 0 10 10" fill="#3483fa">
    <use href="#poly_star_half" />
  </svg>
);

IconStarHalf.propTypes = {
  className: string,
};

export default React.memo(IconStarHalf);
