import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const ICON_ID = 'ic_ahora30';
const namespace = 'ui-search-icon ui-search-icon--ahora30';

const IconAhora30 = ({ className }) => (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames(namespace, className)}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconAhora30.propTypes = {
  className: string,
};

IconAhora30.defaultProps = {
  className: null,
};

const IconApplMemo = React.memo(IconAhora30);

IconApplMemo.ICON_ID = ICON_ID;

export default IconApplMemo;
