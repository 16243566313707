import React from 'react';

import omit from 'lodash/omit';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { RenderPrice } from './render-price';
import StyledLabelWrapper from '../styled-label/styled-label';
import ComposedLabelValue from '../composed-label/composed-label-value';
import { GENERIC_KEY_PREFIXES } from '../layout/renderer/constants';
import { mapWithKey } from '../layout/renderer/utils';
import {
  setItemAlternativeComponentValueSize,
  setItemAlternativeComponentSize,
  useAlternativeComponentLabel,
} from '../context/search';

const namespace = 'ui-search-composed-label-alternatives';

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;

const getStylesFromProps = (props) => {
  const styleMap = {
    marginBottom: get(props, 'margin.bottom'),
    borderRadius: get(props, 'border_radius'),
  };
  const ALLOWED_STYLES = pick({ ...props, ...styleMap }, [
    'borderRadius',
    'marginBottom',
    'background',
    'padding',
    'color',
  ]);
  const styles = {
    ...ALLOWED_STYLES,
  };

  return styles;
};

const renderNormalText = (text, props, { itemId, alternative, componentIndex }) => {
  if (props.font_size && props.size !== props.font_size) {
    try {
      setItemAlternativeComponentSize({ itemId, alternative, componentIndex });
    } catch (e) {
      // ignore
    }
  }

  return (
    <StyledLabelWrapper as="pre" className={`${namespace}--normal-text`} text={text} {...omit(props, ['padding'])} />
  );
};

const ComposedLabelAlternative = ({ itemId, alternative, componentIndex }) => {
  const { text = '', values = [], ...props } = useAlternativeComponentLabel({ itemId, alternative, componentIndex });
  const componentsToRender = [];

  let currentIndex = 0;
  let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

  while (match !== null) {
    const matchIndex = match.index;
    const placeHolder = match[0];
    const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
    const normalTextToRender = matchIndex > 0 && text.substring(currentIndex, matchIndex);

    currentIndex = matchIndex + placeHolder.length;

    if (normalTextToRender) {
      componentsToRender.push(
        renderNormalText(normalTextToRender, omit(props, ['padding']), {
          itemId,
          alternative,
          componentIndex,
        }),
      );
    }

    const valueProps = values?.find((value) => value.key === valueKey);

    if (valueProps) {
      switch (valueProps.type) {
        case 'price':
          componentsToRender.push(<RenderPrice className={`${namespace}__price`} {...props} {...valueProps} />);

          break;
        case 'icon':
        case 'text':

        // falls through
        default: {
          const addToComponentsToRender = (componentProps) =>
            componentsToRender.push(
              <ComposedLabelValue className={`${namespace}--value`} as="pre" {...props} {...componentProps} />,
            );

          if (!valueProps.font_size || valueProps.size === valueProps.font_size) {
            addToComponentsToRender(valueProps);

            break;
          }

          try {
            setItemAlternativeComponentValueSize({ itemId, alternative, componentIndex, valueKey });
          } catch (e) {
            // ignore
          }

          addToComponentsToRender(valueProps);

          break;
        }
      }
    }

    match = PLACEHOLDER_FORMAT_REGEX.exec(text);
  }

  if (currentIndex < text.length) {
    const component = renderNormalText(text.substring(currentIndex), omit(props, ['padding']), {
      itemId,
      alternative,
      componentIndex,
    });

    componentsToRender.push(component);
  }

  return (
    <div className={`${namespace}__container`} style={getStylesFromProps(props)}>
      <div className={`${namespace}__items`}>
        {mapWithKey(componentsToRender, GENERIC_KEY_PREFIXES.MODAL_COMPONENTS)}
      </div>
    </div>
  );
};

export default ComposedLabelAlternative;
