import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--map-loader-error';

const IconLoaderError = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="loader-error.svg" alt="" />
  </div>
);

IconLoaderError.propTypes = {
  className: string,
};

IconLoaderError.defaultProps = {
  className: null,
};

export default IconLoaderError;
