import { createSetter, useSubscribedState } from 'nordic/page/store';

export const useFreeShippingTh = () => useSubscribedState().freeShippingTh;

export const setFreeShippingTh = createSetter(({ freeShippingTh }, { type: action, payload }) => {
  const { threshold_labels: thresholdLabeldata, threshold_label_state: thresholdLabelState } = payload || {};

  switch (action) {
    case 'FIRST_LOAD_SUPER':
      freeShippingTh.firstLoadSuper = false;
      freeShippingTh.freeShippingSuper = thresholdLabelState;

      break;
    case 'FIRST_LOAD_PARTNER':
      freeShippingTh.firstLoadPartner = false;
      freeShippingTh.freeShippingPartner = thresholdLabelState;

      break;
    case 'CHANGE_THRESHOLD_SUPER_TEXT':
      if (freeShippingTh.freeShippingSuper === thresholdLabelState && !freeShippingTh.superChange) {
        break;
      }

      freeShippingTh.superChange = true;
      freeShippingTh.thresholdSuperLabel = thresholdLabeldata;

      break;
    case 'CHANGE_THRESHOLD_PARTNER_TEXT':
      if (freeShippingTh.freeShippingPartner === thresholdLabelState && !freeShippingTh.partnerChange) {
        break;
      }

      freeShippingTh.partnerChange = true;
      freeShippingTh.thresholdPartnerLabel = thresholdLabeldata;

      break;
    case 'CHANGE_THRESHOLD_PROMISE_SUPER_TEXT':
      freeShippingTh.superShippingPromiseLabel = thresholdLabeldata;

      break;
    case 'CHANGE_THRESHOLD_PROMISE_PARTNER_TEXT':
      freeShippingTh.partnerShippingPromiseLabel = thresholdLabeldata;

      break;

    default:
      break;
  }
});
