import React from 'react';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--brand_premium';

const ICON_ID = 'is_highlight_brand_premium';

const IconPremiumBrands = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

export default IconPremiumBrands;
