/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from 'react';

import smoothScroll from '../pages/search/helpers/smooth-scroll';

// We want to scroll only when the element is not fully visible.
const isElementCovered = (element) => {
  const bounds = element.getBoundingClientRect();

  return bounds.top + bounds.height > window.innerHeight;
};

const useElementSmoothScroll = (active = true) => {
  const container = useRef();

  useEffect(() => {
    if (window && container.current) {
      const element = container.current;

      if (isElementCovered(element) && active) {
        smoothScroll(element, window);
      }
    }
  }, []);

  return container;
};

export default useElementSmoothScroll;
