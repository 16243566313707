import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { useSearch } from '../../../../hooks/context';

const namespace = 'ui-search-search-result';

const SearchResult = ({ text = '' }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <div className={classnames(namespace, { shops__result: isShops })}>
      <span className={classnames(`${namespace}__quantity-results`, { 'shops-custom-secondary-font': isShops })}>
        {text}
      </span>
    </div>
  );
};

SearchResult.propTypes = {
  text: string.isRequired,
};

export default SearchResult;
