import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--chevron';

const ICON_ID = 'chevron';

const IconChevron = ({ className = null, mustHidden = true }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden={mustHidden}
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconChevron.propTypes = {
  className: string,
};

export default React.memo(IconChevron);
