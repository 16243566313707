/**
 * Module dependencies
 */

import React from 'react';

import { Image } from 'nordic/image';

/**
 * Icon
 */

const Icon = () => <Image src="error-screen.svg" alt="" />;

/**
 * Expose Icon
 */

export default Icon;
