import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';

const namespace = 'ui-search-icon ui-search-icon--icon-melicoin';

const IconMelicoin = ({ className, centsType }) => {
  const centsTypeStyles = centsType === 'dot' ? '2px' : '';

  return (
    <div className={classnames(namespace, className)} style={{ marginTop: centsTypeStyles }}>
      <Image src="icon-melicoin.svg" alt="" />
    </div>
  );
};

IconMelicoin.propTypes = {
  centsType: string,
  className: string,
};

IconMelicoin.defaultProps = {
  className: null,
  currency: '',
};

export default IconMelicoin;
