import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';
import classnames from 'classnames';

const namespace = 'ui-search-icon ui-search-icon--melichoice-logo';

const IconMeliChoiceLogo = ({ className }) => (
  <div className={classnames(namespace, className)}>
    <Image src="melichoice.svg" alt="" />
  </div>
);

IconMeliChoiceLogo.propTypes = {
  className: string,
};

IconMeliChoiceLogo.defaultProps = {
  className: null,
};

export default IconMeliChoiceLogo;
